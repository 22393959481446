import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  template: `
      <div class="layout-footer">
        <!-- <div class="clearfix"> -->
<div class="p-col">
<span class="footer-text-left">
       <img class="logo" routerLink="/" style="outline: none; cursor: pointer;" alt="apollo-layout"
            src="assets/layout/images/Logo600_sfWhite.png" width="150" /></span></div>
            <div class="p-col">
          <span class="footer-text-right">Via A. Meucci, 27 - 00012 Guidonia Montecelio (RM) - Tel +39 0655045230 - Partita Iva 15694471002 - info@orconsulting.it - www.orconsulting.it</span>
          <!-- <span class="footer-text-right"> per assistenza ed implementazioni: info@l2soluzioni.com</span> -->
        <!-- </div> --> </div> 
      </div>    `
})
export class AppFooterComponent {

}
