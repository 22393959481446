import { FormGroup } from "@angular/forms";
import * as _ from "lodash";
import { MessageService } from "primeng/api";
import { ChiavaValore } from "src/app/_interfaces/DataChiavaValore";
export class CalcoloPreventivoBCAI {
    preventivoForm: FormGroup;
    tipologiaForm: FormGroup;
    msgService: MessageService;
    tipo: "si_dd" | "ssco_dd" | "ba_dd_si" | "bc_dd" | "bc_dd_ai" | "ba_dd_ai";
    ruoloUtente: string;
    data: any;

    values: {
        prev_imp_asseverabile: number;
        vendita_infissi: number;
        posa_opera: number;
        beni_significativi: number;
        altri_beni: number;
        altre_opere: number;
        prestazione_prof: number;
        fisica_giuridica: string;
        iva_offerta: string;
        asseverazione: string;
        imponibile_serramentista: number;
        zona_climatica: string;
        mq_installati: number;
        mq_installati_co: number;
        praticaenea: number;
        costoenea: number;
    };

    lat: {
        costo_fisso: number;
        asseverazione: number;
        asseverazione_perc: number;
    };

    calculated: {
        costo_pratica: number;
        nuovo_imponibile: number;
    };


    fattura_SI: {
        posaInOpera_imponibile: number;
        posaInOpera_iva_10: number;
        posaInOpera_iva_22: number;
        posaInOpera_totale: number;

        valoreSchermatureSolari_imponibile: number;
        valoreSchermatureSolari_iva_10: number;
        valoreSchermatureSolari_iva_22: number;
        valoreSchermatureSolari_totale: number;

        valoreMeccanismi_imponibile: number;
        valoreMeccanismi_iva_10: number;
        valoreMeccanismi_iva_22: number;
        valoreMeccanismi_totale: number;

        valoreOscurantiTecniche_imponibile: number;
        valoreOscurantiTecniche_iva_10: number;
        valoreOscurantiTecniche_iva_22: number;
        valoreOscurantiTecniche_totale: number;

        opereComplementari_imponibile: number;
        opereComplementari_iva_10: number;
        opereComplementari_iva_22: number;
        opereComplementari_totale: number;

        prestazioni_imponibile: number;
        prestazioni_iva_10: number;
        prestazioni_iva_22: number;
        prestazioni_totale: number;

        totale_imponibile: number;
        totale_iva_10: number;
        totale_iva_22: number;
        totale_totale: number;
    };

    massimali_SI: {
        imponibile_fornitura: number;
        tot_mq_installati: number;

        massimale_mq_senza_oscurante: number;
        massimale_mq_con_oscurante: number;
        imponibile_max_senza_oscurante: number;
        imponibile_max_con_oscurante: number;
        imponibile_max: number;
        imponibile_non_detraibile: number;
        importo_detraibile_enea: number;
        recupero_10_anni: number;
    };

    preRE: ChiavaValore[];
    constructor(
        preventivoForm: FormGroup,
        tipologiaForm: FormGroup,
        msgService: MessageService,
        tipo: "si_dd" | "ssco_dd" | "ba_dd_si" | "bc_dd" | "bc_dd_ai" | "ba_dd_ai",
        ruoloUtente: string,
        data: any,
        preRE: ChiavaValore[]
    ) {
        this.preventivoForm = preventivoForm;
        this.tipologiaForm = tipologiaForm;
        this.tipo = tipo;
        this.data = data;
        this.msgService = msgService;
        this.ruoloUtente = ruoloUtente;
        this.preRE = preRE;
    }

    calcola() {
        this.retrieveFormValue();
        this.calcolaLat(this.tipo, this.data);
        this.calcalaCalculated();
        if (!this.checkValues()) {
            return false;
        }
        if (this.tipo === "bc_dd_ai") {
            this.calcolaFattura_SI();
            this.calcolaMassimali_SI();
            if (!this.checkValuesCalculated_SI()) {
                return false;
            }
            this.writeToForm_SI();
        }
        this.success();
        return true;
    }

    calcolaserr_costi() {
        this.retrieveFormValue();
        this.calcolaLat(this.tipo, this.data);
        this.calcalaCalculated();
        if (!this.checkValues()) {
            return false;
        }
        if (this.tipo === "bc_dd_ai") {
            this.calcolaFattura_SI();
            this.calcolaMassimali_SI();
            if (!this.checkValuesCalculated_SI()) {
                return false;
            }
            this.writeToForm_SI();
        }
        return {serr:this.values.imponibile_serramentista, cost:this.calculated.costo_pratica}
    }


    private retrieveFormValue() {
        // retrieve values
        const vendita_infissi = this.valueOrZero(
            this.preventivoForm.get("prev_vendita_infissi").value
        );
        const posa_opera = this.valueOrZero(
            this.preventivoForm.get("prev_posa_opera").value
        );
        const beni_significativi = this.valueOrZero(
            this.preventivoForm.get("prev_beni_significativi").value
        );
        const altri_beni = this.valueOrZero(
            this.preventivoForm.get("prev_altri_beni").value
        );
        const altre_opere = this.valueOrZero(
            this.preventivoForm.get("prev_altre_opere").value
        );
        const prestazione_prof = this.valueOrZero(
            this.preventivoForm.get("prev_prestazioni_prof").value
        );
        const fisica_giuridica = this.preventivoForm.get("lat_tipo").value;
        const iva_offerta = this.preventivoForm.get("prev_iva_offerta").value;
        const asseverazione =
            this.preventivoForm.get("prev_asseverazione").value;

        const praticaenea =
            this.preventivoForm.get("prev_praticaenea").value;

        const costoenea =
            this.preventivoForm.get("prev_costoenea").value;

        const imponibile_serramentista =
            vendita_infissi +
            posa_opera +
            altri_beni +
            altre_opere +
            prestazione_prof;

        const prev_imp_asseverabile = this.preventivoForm.get("prev_imp_asseverazione").value;


        const zona_climatica = this.tipologiaForm.get(
            "prev_zona_climatica"
        ).value;
        const mq_installati = this.valueOrZero(
            this.tipologiaForm.get("prev_mq_installati").value
        );
        const mq_installati_co = this.valueOrZero(
            this.tipologiaForm.get("prev_mq_installati_co").value
        );

        this.values = {
            prev_imp_asseverabile,
            vendita_infissi,
            posa_opera,
            beni_significativi,
            altri_beni,
            altre_opere,
            prestazione_prof,
            fisica_giuridica,
            iva_offerta,
            asseverazione,
            imponibile_serramentista,
            zona_climatica,
            mq_installati,
            mq_installati_co,
            praticaenea,
            costoenea
        };
    }
    percDetrazione: number;


    private calcolaLat(tipo: string, data: any): void {
        let range1min: string;
        let range1max: string;
        let range2min: string;
        let range2max: string;
        let indice: string = "0";


        this.percDetrazione = data.find((i) => i.ua_chiave === `${tipo}_detrazione`).ua_valore;
        range1min = data.find(
            (i) => i.ua_chiave === `${tipo}_1rangeMin`
        ).ua_valore;
        range1max = data.find(
            (i) => i.ua_chiave === `${tipo}_1rangeMax`
        ).ua_valore;
        range2min = data.find(
            (i) => i.ua_chiave === `${tipo}_2rangeMin`
        ).ua_valore;
        range2max = data.find(
            (i) => i.ua_chiave === `${tipo}_2rangeMax`
        ).ua_valore;

        if (
            _.inRange(
                this.values.imponibile_serramentista,
                Number(range1min),
                Number(range1max)
            )
        ) {
            indice = "1";
        } else if (
            _.inRange(
                this.values.imponibile_serramentista,
                Number(range2min),
                Number(range2max)
            )
        ) {
            indice = "2";
        } else {
            indice = "3";
        }

        // const percentualeImponibile = Number(this.getValoreByKey(`${tipo}_${indice}percImp`, data));

        if (this.preventivoForm.controls["lat_id"].value == "0") {
            this.preventivoForm.controls["lat_oneri_att"].setValue(
                this.getValoreByKey(`${tipo}_${indice}percOneri`, data)
            );
            // this.percentualeOneri = Number(this.getValoreByKey(`${tipo}_${indice}percOneri`, data));
        } else {
            // this.percentualeOneri = this.frmOfferta.controls.lat_oneri_att.value;
        }

        this.preventivoForm.controls["lat_imp"].setValue(
            this.getValoreByKey(`${tipo}_${indice}percImp`, data)
        );
        this.preventivoForm.controls["lat_range_min"].setValue(
            this.getValoreByKey(`${tipo}_${indice}rangeMin`, data)
        );
        this.preventivoForm.controls["lat_range_max"].setValue(
            this.getValoreByKey(`${tipo}_${indice}rangeMax`, data)
        );
        this.preventivoForm.controls["lat_costo_fisso"].setValue(
            this.getValoreByKey(`${tipo}_${indice}costoFisso`, data)
        );
        this.preventivoForm.controls["lat_costo_min"].setValue(
            this.getValoreByKey(`${tipo}_${indice}costoMin`, data)
        );
        this.preventivoForm.controls["lat_costo_max"].setValue(
            this.getValoreByKey(`${tipo}_${indice}costoMax`, data)
        );
        this.preventivoForm.controls["lat_costo_asseverazione"].setValue(
            this.getValoreByKey(`${tipo}_${indice}costoAssev`, data)
        );
        this.preventivoForm.controls["lat_perc_asseverazione"].setValue(
            this.getValoreByKey(`${tipo}_${indice}percAssev`, data)
        );
        this.preventivoForm.controls["lat_costo_visto"].setValue(
            this.getValoreByKey(`${tipo}_${indice}costoVisto`, data)
        );
        this.preventivoForm.controls["lat_perc_visto"].setValue(
            this.getValoreByKey(`${tipo}_${indice}percVisto`, data)
        );
        this.preventivoForm.controls["lat_detrazione"].setValue(
            this.getValoreByKey(`${tipo}_detrazione`, data)
        );
        this.preventivoForm.controls["lat_oneri"].setValue(
            Number(this.getValoreByKey(`${tipo}_preventivatore`, data))
        );
        this.preventivoForm.controls["lat_cessionario"].setValue(
            Number(this.getValoreByKey(`${tipo}_cessionario`, data))
        );
        this.preventivoForm.controls["prev_cessionario"].setValue(
            Number(this.getValoreByKey(`${tipo}_cessionario`, data))
        );
        this.preventivoForm.controls["lat_chiusura_pratica"].setValue(
            this.getValoreByKey(`${tipo}_chiusuraPratica`, data)
        );

        this.lat = {
            costo_fisso: Number(
                this.getValoreByKey(`${tipo}_${indice}costoFisso`, data)
            ),
            asseverazione: Number(
                this.getValoreByKey(`${tipo}_${indice}costoAssev`, data)
            ),
            asseverazione_perc: Number(
                this.getValoreByKey(`${tipo}_${indice}percAssev`, data)
            ),
        };
    }

    private calcalaCalculated() {
        let costo_pratica =
            this.values.asseverazione === "NO"
                ? this.lat.costo_fisso
                : this.values.asseverazione === "SI" &&
                    (this.values.imponibile_serramentista *
                        this.lat.asseverazione_perc) / 100 <= this.lat.asseverazione
                    ? this.lat.asseverazione + this.lat.costo_fisso
                    : this.lat.costo_fisso + (this.values.imponibile_serramentista * this.lat.asseverazione_perc) / 100;



        costo_pratica = this.values.praticaenea == 1 ? costo_pratica : costo_pratica - this.values.costoenea;

        const nuovo_imponibile =
            this.values.imponibile_serramentista + costo_pratica;

        this.calculated = {
            costo_pratica,
            nuovo_imponibile,
        };
    }

    private checkValues(): boolean {
        // presente su excel ma nel codice è valido solo per si_dd
        if (
            (this.tipo === "bc_dd_ai") &&
            this.values.vendita_infissi < this.values.beni_significativi
        ) {
            this.error(
                "Vendita infissi non deve essere inferiore ai Beni significativi"
            );
            return false;
        }
        // presente su excel ma nel codice è valido solo per ssco_dd
        if (
            this.tipo === "ssco_dd" &&
            this.values.vendita_infissi === 0 &&
            this.values.beni_significativi === 0
        ) {
            this.error(
                "Beni significativi o Vendita infissi devono essere diversi da 0"
            );
            return false;
        }
        // if (this.tipo === "ssco_dd" && this.values.posa_opera === 0) {
        //     this.error("Posa in opera deve essere diversa da 0");
        //     return false;
        // }

        // Presente nell'excel
        // if (this.values.posa_opera <= 0) {
        //     this.error("Posa in opera deve essere maggiore di 0");
        //     return false;
        // }

        // Presente nell'excel
        // if (
        //     this.values.asseverazione === "SI" &&
        //     this.values.altre_opere <= 0
        // ) {
        //     this.error("Altre opere deve essere maggiore di 0");
        //     return false;
        // }

        // presente nell'excel
        // if (
        //     this.values.asseverazione === "SI" &&
        //     this.values.prestazione_prof <= 0
        // ) {
        //     this.error("Prestazione professionale deve essere maggiore di 0");
        //     return false;
        // }

        return true;
    }

    private checkValuesCalculated_SI(): boolean {
        // if (
        //     this.values.posa_opera >
        //     this.values.mq_installati * 139.4 +
        //     this.values.mq_installati_co * 209.1
        // ) {
        //     if (this.ruoloUtente === "SE") {
        //         this.error(
        //             "VALORE DI POSA IN OPERA NON CONGRUO, CONTATTARE OFFICINE RINNOVABILI"
        //         );
        //         return false;
        //     } else {
        //         this.warning(
        //             "VALORE DI POSA IN OPERA NON CONGRUO, CONTATTARE OFFICINE RINNOVABILI"
        //         );
        //         return true;
        //     }
        // }

        if (
            this.values.altre_opere >
            this.values.mq_installati * 78.1 +
            this.values.mq_installati_co * 112.3
        ) {
            if (this.ruoloUtente === "SE") {
                this.error(
                    "VALORE DI ALTRE OPERE NON CONGRUO, CONTATTARE OFFICINE RINNOVABILI"
                );
                return false;
            } else {
                this.warning(
                    "VALORE DI ALTRE OPERE NON CONGRUO, CONTATTARE OFFICINE RINNOVABILI"
                );
                return true;
            }
        }

        const val =
            (this.massimali_SI.imponibile_max +
                this.values.mq_installati * 139.4 +
                this.values.mq_installati_co * 209.1 +
                this.values.mq_installati * 78.1 +
                this.values.mq_installati_co * 112.3) *
            (3.90817275638123 / 100);
        if (this.values.prestazione_prof > val) {
            if (this.ruoloUtente === "SE") {
                this.error(
                    "VALORE DI PRESTAZIONI PROFESSIONALI NON CONGRUO, CONTATTARE OFFICINE RINNOVABILI"
                );
                return false;
            } else {
                this.warning(
                    "VALORE DI PRESTAZIONI PROFESSIONALI NON CONGRUO, CONTATTARE OFFICINE RINNOVABILI"
                );
                return true;
            }
        }

        if (this.massimali_SI.imponibile_non_detraibile > 0) {
            this.warning(
                "IL PREVENTIVO PRESENTA UN IMPORTO NON DETRAIBILE PARI A € " +
                this.massimali_SI.imponibile_non_detraibile.toFixed(2) +
                ", SI CONSIGLIA DI VERIFICARE LA CORRETTEZZA DEI DATI"
            );
        }

        return true;
    }



    private getValoreByKey = (key: string, array: any[]) =>
        array.find((i) => i.ua_chiave === key).ua_valore;

    calcolaMassimali_SI() {
        //*** CALCOLI */
        const imponibile_fornitura =
            this.calculated.nuovo_imponibile -
            this.values.posa_opera -
            this.values.altre_opere -
            this.values.prestazione_prof;

        const tot_mq_installati =
            this.values.mq_installati + this.values.mq_installati_co;
        const trasmittanza_massima =
            this.values.zona_climatica === "A" ||
                this.values.zona_climatica === "B"
                ? "<=2,60"
                : this.values.zona_climatica === "C"
                    ? "<=1,75"
                    : this.values.zona_climatica === "D"
                        ? "<=1,67"
                        : this.values.zona_climatica === "E"
                            ? "<=1,30"
                            : this.values.zona_climatica === "F"
                                ? "<=1,0"
                                : "";
        const massimale_mq_senza_oscurante =
            this.values.mq_installati > 0 &&
                (this.values.zona_climatica === "A" ||
                    this.values.zona_climatica === "B" ||
                    this.values.zona_climatica === "C")
                ? 660
                : this.values.mq_installati > 0 &&
                    (this.values.zona_climatica === "D" ||
                        this.values.zona_climatica === "E" ||
                        this.values.zona_climatica === "F")
                    ? 780
                    : 0;
        const massimale_mq_con_oscurante =
            this.values.mq_installati_co > 0 &&
                (this.values.zona_climatica === "A" ||
                    this.values.zona_climatica === "B" ||
                    this.values.zona_climatica === "C")
                ? 780
                : this.values.mq_installati_co > 0 &&
                    (this.values.zona_climatica === "D" ||
                        this.values.zona_climatica === "E" ||
                        this.values.zona_climatica === "F")
                    ? 900
                    : 0;
        const imponibile_max_senza_oscurante =
            this.values.mq_installati * massimale_mq_senza_oscurante;
        const imponibile_max_con_oscurante =
            this.values.mq_installati_co * massimale_mq_con_oscurante;
        const imponibile_max =
            imponibile_max_senza_oscurante + imponibile_max_con_oscurante;

        const ImportoNONDetraibileEccedenteMassimanli: number =
            this.calcoloImportoNONDetraibileEccedenteMassimali_SI(imponibile_fornitura, imponibile_max)


        const imponibile_non_detraibile = this.calcoloImponibileNONDetraibile_SI(ImportoNONDetraibileEccedenteMassimanli);

        const importo_detraibile_enea = this.calcoloImportoDetraibile(imponibile_non_detraibile);

        const recupero_10_anni = (importo_detraibile_enea * Number(this.percDetrazione)) / 100;

        this.massimali_SI = {
            imponibile_fornitura,
            tot_mq_installati,
            massimale_mq_senza_oscurante,
            massimale_mq_con_oscurante,
            imponibile_max_senza_oscurante,
            imponibile_max_con_oscurante,
            imponibile_max,
            imponibile_non_detraibile,
            importo_detraibile_enea,
            recupero_10_anni,
        };
    }


    calcoloImportoDetraibile(imponibile_non_detraibile: number): number {
        const F6: string = this.values.fisica_giuridica;
        const D16: number = this.fattura_SI.totale_totale;
        const D13: number = this.calculated.nuovo_imponibile;
        const D18: number = imponibile_non_detraibile;
        if (F6 === "fisica") {
            return D16 - D18;
        } else {
            return D13 - D18;
        }
    }

    calcoloImponibileNONDetraibile_SI(impDetrMaxMassimali: number): number {
        const F6: string = this.values.fisica_giuridica;
        const D13: number = this.calculated.nuovo_imponibile;
        const D15: number = Number(this.values.prev_imp_asseverabile);
        const D16: number = this.fattura_SI.totale_totale;
        const H20: number = this.fattura_SI.totale_iva_10;
        const I20: number = this.fattura_SI.totale_iva_22;

        if (F6 === "giuridica") {
            if (D13 <= D15 && D13 <= 96000) {
                return 0;
            } else if (D13 > D15 && D15 <= 96000) {
                return D13 - D15;
            } else {
                return D16 - 96000;
            }
        }
        if (F6 === "fisica") {
            if (D13 < D15 && D16 <= 96000) {
                return 0;
            } else if (D13 > D15 && D16 - (D13 - D15) - (((D13 - D15) / D13) * (H20 + I20)) <= 96000) {
                return (D13 - D15) + ((D13 - D15) / D13) * (H20 + I20);
            } else {
                return D16 - 96000;
            }
        }
        return 0;
    }


    calcoloImportoNONDetraibileEccedenteMassimali_SI(imponibile_fornitura: number, imponibile_max: number) {
        const F6: string = this.values.fisica_giuridica;
        const D22: number = imponibile_fornitura;
        const D32: number = imponibile_max;
        const D13: number = this.calculated.nuovo_imponibile;
        const H20: number = this.fattura_SI.totale_iva_10;
        const I20: number = this.fattura_SI.totale_iva_22;


        return -(D22 - D32) > 0
            ? 0
            : F6 === "giuridica"
                ? D22 - D32
                : D32 < D22
                    ? (D22 - D32) + ((D22 - D32) / D13) * (H20 + I20)
                    : 0;
    }

    calcolaFattura_SI() {
        //**** Calcoli
        // 1 riga (Posa in opera)
        const posaInOpera_imponibile = this.values.posa_opera;
        const posaInOpera_iva_10 =
            this.values.iva_offerta === "0" || this.values.iva_offerta === "22"
                ? 0
                : this.values.iva_offerta === "4" ? posaInOpera_imponibile * 0.04 : posaInOpera_imponibile * 0.1;
        const posaInOpera_iva_22 =
            this.values.iva_offerta === "22"
                ? posaInOpera_imponibile * 0.22
                : 0;
        const posaInOpera_totale =
            posaInOpera_imponibile + posaInOpera_iva_10 + posaInOpera_iva_22;
        // 2 riga (Altri beni accessori)
        const valoreSchermatureSolari_imponibile =
            this.values.altri_beni === 0
                ? 0
                : this.values.altri_beni + this.calculated.costo_pratica / 2;
        const valoreSchermatureSolari_iva_10 =
            this.values.iva_offerta === "0" || this.values.iva_offerta === "22"
                ? 0
                : this.values.iva_offerta === "4" ? valoreSchermatureSolari_imponibile * 0.04 : valoreSchermatureSolari_imponibile * 0.1;
        const valoreSchermatureSolari_iva_22 =
            this.values.iva_offerta === "22" ? valoreSchermatureSolari_imponibile * 0.22
                : 0;
        const valoreSchermatureSolari_totale =
            valoreSchermatureSolari_imponibile +
            valoreSchermatureSolari_iva_10 +
            valoreSchermatureSolari_iva_22;

        // 4 riga (Valore di vendita infissi al 22%)
        const valoreMeccanismi_imponibile =
            (this.values.iva_offerta === "0" || this.values.iva_offerta === "22") && this.values.altri_beni === 0
                ? this.values.vendita_infissi + this.calculated.costo_pratica
                : this.values.iva_offerta === "0" || this.values.iva_offerta === "22"
                    ? this.values.vendita_infissi +
                    this.calculated.costo_pratica / 2
                    : this.values.iva_offerta === "10" ||
                        this.values.beni_significativi -
                        this.values.altri_beni -
                        (this.values.posa_opera +
                            this.values.vendita_infissi -
                            this.values.beni_significativi) -
                        this.calculated.costo_pratica -
                        this.values.altre_opere <=
                        0
                        ? 0
                        : this.values.beni_significativi -
                        this.values.altri_beni -
                        (this.values.posa_opera +
                            this.values.vendita_infissi -
                            this.values.beni_significativi) -
                        this.calculated.costo_pratica -
                        this.values.altre_opere;
        const valoreMeccanismi_iva_10 = 0;
        const valoreMeccanismi_iva_22 =
            this.values.iva_offerta === "0"
                ? 0
                : valoreMeccanismi_imponibile * 0.22;
        const valoreMeccanismi_totale =
            valoreMeccanismi_imponibile +
            valoreMeccanismi_iva_10 +
            valoreMeccanismi_iva_22;

        // 3 riga (Valore di vendita infissi al 10%)
        // TODO DA RIVEDERE
        const valoreOscurantiTecniche_imponibile =
            this.values.iva_offerta === "0" || this.values.iva_offerta === "22"
                ? 0
                : this.values.altri_beni === 0
                    ? this.values.vendita_infissi +
                    this.calculated.costo_pratica -
                    valoreMeccanismi_imponibile
                    : -valoreMeccanismi_imponibile +
                    this.values.vendita_infissi +
                    this.calculated.costo_pratica / 2;
        const valoreOscurantiTecniche_iva_10 = this.values.iva_offerta === "4" ? valoreOscurantiTecniche_imponibile * 0.04 : valoreOscurantiTecniche_imponibile * 0.1;
        const valoreOscurantiTecniche_iva_22 = 0;
        const valoreOscurantiTecniche_totale =
            valoreOscurantiTecniche_imponibile +
            valoreOscurantiTecniche_iva_10 +
            valoreOscurantiTecniche_iva_22;

        // 5 riga (Opere compl. all'installazione delle tecnologie)
        const opereComplementari_imponibile = this.values.altre_opere;
        const opereComplementari_iva_10 =
            this.values.iva_offerta === "0" || this.values.iva_offerta === "22"
                ? 0
                : this.values.iva_offerta === "4" ? opereComplementari_imponibile * 0.04 : opereComplementari_imponibile * 0.1;
        const opereComplementari_iva_22 =
            this.values.iva_offerta === "22"
                ? opereComplementari_imponibile * 0.22
                : 0;
        const opereComplementari_totale =
            opereComplementari_imponibile +
            opereComplementari_iva_10 +
            opereComplementari_iva_22;

        // 6 riga (Prestazioni professionali)
        const prestazioni_imponibile = this.values.prestazione_prof;
        const prestazioni_iva_10 = 0;
        const prestazioni_iva_22 =
            this.values.iva_offerta === "0" ? 0 : prestazioni_imponibile * 0.22;
        const prestazioni_totale =
            prestazioni_imponibile + prestazioni_iva_10 + prestazioni_iva_22;

        // 7 riga - totale
        const totale_imponibile =
            posaInOpera_imponibile +
            valoreSchermatureSolari_imponibile +
            valoreOscurantiTecniche_imponibile +
            valoreMeccanismi_imponibile +
            opereComplementari_imponibile +
            prestazioni_imponibile;
        const totale_iva_10 =
            posaInOpera_iva_10 +
            valoreSchermatureSolari_iva_10 +
            valoreOscurantiTecniche_iva_10 +
            valoreMeccanismi_iva_10 +
            opereComplementari_iva_10 +
            prestazioni_iva_10;
        const totale_iva_22 =
            posaInOpera_iva_22 +
            valoreSchermatureSolari_iva_22 +
            valoreOscurantiTecniche_iva_22 +
            valoreMeccanismi_iva_22 +
            opereComplementari_iva_22 +
            prestazioni_iva_22;
        const totale_totale =
            posaInOpera_totale +
            valoreSchermatureSolari_totale +
            valoreOscurantiTecniche_totale +
            valoreMeccanismi_totale +
            opereComplementari_totale +
            prestazioni_totale;

        this.fattura_SI = {
            posaInOpera_imponibile,
            posaInOpera_iva_10,
            posaInOpera_iva_22,
            posaInOpera_totale,
            valoreSchermatureSolari_imponibile,
            valoreSchermatureSolari_iva_10,
            valoreSchermatureSolari_iva_22,
            valoreSchermatureSolari_totale,
            valoreOscurantiTecniche_imponibile,
            valoreOscurantiTecniche_iva_10,
            valoreOscurantiTecniche_iva_22,
            valoreOscurantiTecniche_totale,
            valoreMeccanismi_imponibile,
            valoreMeccanismi_iva_10,
            valoreMeccanismi_iva_22,
            valoreMeccanismi_totale,
            opereComplementari_imponibile,
            opereComplementari_iva_10,
            opereComplementari_iva_22,
            opereComplementari_totale,
            prestazioni_imponibile,
            prestazioni_iva_10,
            prestazioni_iva_22,
            prestazioni_totale,
            totale_imponibile,
            totale_iva_10,
            totale_iva_22,
            totale_totale,
        };
    }

    writeToForm_SI() {
        // Scrivo nel form fattura
        this.preventivoForm.controls["prev_preventivo"]
            .get("posaOperaImponibile")
            .setValue(this.fattura_SI.posaInOpera_imponibile);
        this.preventivoForm.controls["prev_preventivo"]
            .get("posaOpera10")
            .setValue(this.fattura_SI.posaInOpera_iva_10);
        this.preventivoForm.controls["prev_preventivo"]
            .get("posaOpera22")
            .setValue(this.fattura_SI.posaInOpera_iva_22);
        this.preventivoForm.controls["prev_preventivo"]
            .get("posaOperaTot")
            .setValue(this.fattura_SI.posaInOpera_totale);

        this.preventivoForm.controls["prev_preventivo"]
            .get("altibeniaccImponibile")
            .setValue(this.fattura_SI.valoreSchermatureSolari_imponibile);
        this.preventivoForm.controls["prev_preventivo"]
            .get("altibeniacc10")
            .setValue(this.fattura_SI.valoreSchermatureSolari_iva_10);
        this.preventivoForm.controls["prev_preventivo"]
            .get("altibeniacc22")
            .setValue(this.fattura_SI.valoreSchermatureSolari_iva_22);
        this.preventivoForm.controls["prev_preventivo"]
            .get("altibeniaccTot")
            .setValue(this.fattura_SI.valoreSchermatureSolari_totale);

        this.preventivoForm.controls["prev_preventivo"]
            .get("valore10Imponibile")
            .setValue(this.fattura_SI.valoreOscurantiTecniche_imponibile);
        this.preventivoForm.controls["prev_preventivo"]
            .get("valore1010")
            .setValue(this.fattura_SI.valoreOscurantiTecniche_iva_10);
        this.preventivoForm.controls["prev_preventivo"]
            .get("valore1022")
            .setValue(this.fattura_SI.valoreOscurantiTecniche_iva_22);
        this.preventivoForm.controls["prev_preventivo"]
            .get("valore10Tot")
            .setValue(this.fattura_SI.valoreOscurantiTecniche_totale);

        this.preventivoForm.controls["prev_preventivo"]
            .get("valore22Imponibile")
            .setValue(this.fattura_SI.valoreMeccanismi_imponibile);
        this.preventivoForm.controls["prev_preventivo"]
            .get("valore2210")
            .setValue(this.fattura_SI.valoreMeccanismi_iva_10);
        this.preventivoForm.controls["prev_preventivo"]
            .get("valore2222")
            .setValue(this.fattura_SI.valoreMeccanismi_iva_22);
        this.preventivoForm.controls["prev_preventivo"]
            .get("valore22Tot")
            .setValue(this.fattura_SI.valoreMeccanismi_totale);

        this.preventivoForm.controls["prev_preventivo"]
            .get("altreOpereImponibile")
            .setValue(this.fattura_SI.opereComplementari_imponibile);
        this.preventivoForm.controls["prev_preventivo"]
            .get("altreOpere10")
            .setValue(this.fattura_SI.opereComplementari_iva_10);
        this.preventivoForm.controls["prev_preventivo"]
            .get("altreOpere22")
            .setValue(this.fattura_SI.opereComplementari_iva_22);
        this.preventivoForm.controls["prev_preventivo"]
            .get("altreOpereTot")
            .setValue(this.fattura_SI.opereComplementari_totale);

        this.preventivoForm.controls["prev_preventivo"]
            .get("prestProfImponibile")
            .setValue(this.fattura_SI.prestazioni_imponibile);
        this.preventivoForm.controls["prev_preventivo"]
            .get("prestProf10")
            .setValue(this.fattura_SI.prestazioni_iva_10);
        this.preventivoForm.controls["prev_preventivo"]
            .get("prestProf22")
            .setValue(this.fattura_SI.prestazioni_iva_22);
        this.preventivoForm.controls["prev_preventivo"]
            .get("prestProfTot")
            .setValue(this.fattura_SI.prestazioni_totale);

        this.preventivoForm.controls["prev_preventivo"]
            .get("ftImponibile")
            .setValue(this.fattura_SI.totale_imponibile);
        this.preventivoForm.controls["prev_preventivo"]
            .get("ft10")
            .setValue(this.fattura_SI.totale_iva_10);
        this.preventivoForm.controls["prev_preventivo"]
            .get("ft22")
            .setValue(this.fattura_SI.totale_iva_22);
        this.preventivoForm.controls["prev_preventivo"]
            .get("ftTot")
            .setValue(this.fattura_SI.totale_totale);

        // scrivo nel form massimali
        this.preventivoForm.controls["prev_preventivo"]
            .get("imponibileFornitura")
            .setValue(this.massimali_SI.imponibile_fornitura);
        this.preventivoForm.controls["prev_preventivo"]
            .get("massimaleMQdaApplicare")
            .setValue(this.massimali_SI.massimale_mq_senza_oscurante);
        this.preventivoForm.controls["prev_preventivo"]
            .get("massimaleMQdaApplicare_CON_CO")
            .setValue(this.massimali_SI.massimale_mq_con_oscurante);

        this.preventivoForm.controls["prev_preventivo"]
            .get("imponibileMassimoDetraibile")
            .setValue(this.massimali_SI.imponibile_max_senza_oscurante);
        this.preventivoForm.controls["prev_preventivo"]
            .get("imponibileMassimoDetraibile_CON_CO")
            .setValue(this.massimali_SI.imponibile_max_con_oscurante);
        this.preventivoForm.controls["prev_preventivo"]
            .get("imponibileMassimoDetraibile_Totale")
            .setValue(this.massimali_SI.imponibile_max);

        this.preventivoForm.controls["prev_preventivo"]
            .get("costoMQImponibile")
            .setValue(this.massimali_SI.imponibile_max);
        this.preventivoForm.controls["prev_preventivo"]
            .get("importoNonDetraibile")
            .setValue(this.massimali_SI.imponibile_non_detraibile);
        this.preventivoForm.controls["prev_imp_detraibile_enea"].setValue(
            this.massimali_SI.importo_detraibile_enea
        );
        this.preventivoForm.controls["prev_max_detraibile"].setValue(
            this.massimali_SI.imponibile_non_detraibile
        );
        this.preventivoForm.controls["prev_recupero_fiscale_anno"].setValue(
            this.massimali_SI.recupero_10_anni
        );
    }

    clearForm_SI() { }


    private valueOrZero(value: any) {
        if (value === "") return 0;
        if (value === null || value === undefined) return 0;
        return Number.parseFloat(value);
    }

    private error(err: string) {
        this.msgService.add({
            key: "tst",
            severity: "error",
            summary: "Errore",
            detail: err,
        });
    }
    private warning(err: string) {
        this.msgService.add({
            key: "tst",
            severity: "warn",
            summary: "Attenzione",
            detail: err,
        });
    }
    private success() {
        this.msgService.add({
            key: "tst",
            severity: "success",
            summary: "Calcola preventivo",
            detail: "Il preventivo è stato calcolato con successo",
        });
    }
}
