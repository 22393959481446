import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from './../../../environments/environment';
import {  MessageService } from 'primeng/api';
import { ArchivioCategoriaService } from "../../_services/archivio-categoria.service";
import { ArchivioCategoria } from "../../_interfaces/list-type";
import { AppBreadcrumbService } from 'src/app/app.breadcrumb.service';

@Component({
  selector: 'app-archivio-categoria',
  templateUrl: './archivio-categoria.component.html',
  styleUrls: ['./archivio-categoria.component.scss']
})
export class ArchivioCategoriaComponent implements OnInit {

  archCategoria: ArchivioCategoria[];
  
  environment = environment;
  constructor(private archCatSrv: ArchivioCategoriaService,
    private msgService: MessageService,
    private breadcrumbService: AppBreadcrumbService) { }

  frmAchCat: FormGroup;

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
          label: `Documenti Archivio / Categoria`,
      },
  ]);
    this.GeneraArchCategoria();
    this.refresh();
  }

  GeneraArchCategoria(): void {
    this.frmAchCat = new FormGroup({
      acat_id: new FormControl(0),
      acat_nome: new FormControl("", Validators.required)
    });
  }

  resetForm(): void {
    this.GeneraArchCategoria();
  }

  editAchCat(archCategoria: ArchivioCategoria): void {
    this.frmAchCat.patchValue(archCategoria);
  }

  refresh(): void {
    this.archCatSrv.getArchivioCaterorie().subscribe((res) => {
      this.archCategoria = [...res.data];
    });
  }

  submit() {
    this.archCatSrv.addArchivioCategoria(this.frmAchCat.value).subscribe(() => {
      this.resetForm();
      this.msgService.add({
        key: "tst",
        severity: "success",
        summary: `Tipologia Documento Archivio`,
        detail: `Tipologia Registrata Correttamente!`,
      });
      this.refresh();
    });
  }

}
