import {CheckEmail, Credenziali} from './../_interfaces/utente';
import {Validators} from '@angular/forms';
import {FormControl} from '@angular/forms';
import {FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from './../_services/auth.service';
import {Component} from '@angular/core';
import {MessageService} from 'primeng/api';

@Component({
    selector: 'app-login',
    templateUrl: './app.login.component.html',
})
export class AppLoginComponent {

    modalEmail: boolean = false;
    frmEmail: FormGroup;

    formLogin = new FormGroup({
        name: new FormControl("", [Validators.required]),
        password: new FormControl("", Validators.required),
    });

    constructor(private authService: AuthService, private router: Router, private msgService: MessageService) {
        this.creaFormEmail();
    }

    submit(): void {
        const credenziali: Credenziali = {...this.formLogin.value};
        credenziali.password = CryptoJS.SHA256(credenziali.password).toString();
        this.authService.doLogin(credenziali).subscribe(
            (res) => {
                this.router.navigate([""]);
            }
        );
    }

    creaFormEmail() {
        this.frmEmail = new FormGroup({
            rec_email: new FormControl('', [Validators.required, Validators.email])
        });
    }

    openModal(): void {
        this.modalEmail = true;
    }

    closeModal(): void {
        this.modalEmail = false;
    }

    sendEmail(): void {
        const checkEmail: CheckEmail = {...this.frmEmail.value};
        this.authService.checkEmail(checkEmail).subscribe(
            (res) => {
                this.msgService.add({
                    key: 'tst',
                    severity: 'success',
                    summary: `${res.message} (controllare anche la cartella SPAM)`,
                });

                // window.location.href = res.link;
            }
        );
        this.closeModal();
    }
}
