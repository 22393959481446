import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from './../../../environments/environment';
import { MessageService } from 'primeng/api';
import { AppBreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ChiavaValore } from 'src/app/_interfaces/DataChiavaValore';
import { ChiavevaloreService } from 'src/app/_services/chiavevalore.service';

@Component({
  selector: 'app-parametr-re',
  templateUrl: './parametr-re.component.html',
  styleUrls: ['./parametr-re.component.scss']
})
export class ParametrREComponent implements OnInit {

  cv: ChiavaValore[];
  ruolo: string;
  environment = environment;
  constructor(private chiavevaloreSrv: ChiavevaloreService,
    private msgService: MessageService,
    private breadcrumbService: AppBreadcrumbService) { }

  frmRE: FormGroup;

  ngOnInit(): void {
    this.ruolo = sessionStorage.getItem('ruolo');
    this.breadcrumbService.setItems([
      {
        label: `Parametri RE`,
      },
    ]);
    this.GeneraFormParametri();
    this.refresh();
  }

  GeneraFormParametri(): void {
    this.frmRE = new FormGroup({
      pre_chiave: new FormControl(0),
      pre_valore: new FormControl("", Validators.required)
    });
  }

  resetForm(): void {
    this.GeneraFormParametri();
  }

  editAchCat(parametriRE: ChiavaValore): void {
    this.frmRE.patchValue(parametriRE);
  }

  refresh(): void {
    this.chiavevaloreSrv.getAll().subscribe((res) => {
      if (this.ruolo == 'AD') {
        this.cv = res.data.filter((x) => x.pre_chiave == 'CE_COSTO_ENEA');
      }
      else {
        this.cv = [...res.data];
      }

    });
  }

  submit() {
    this.chiavevaloreSrv.addKeyValue(this.frmRE.value).subscribe(() => {
      this.resetForm();
      this.msgService.add({
        key: "tst",
        severity: "success",
        summary: `Parametri RE`,
        detail: `Valore Registrato Correttamente!`,
      });
      this.refresh();
    });
  }

}