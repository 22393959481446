import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { FormGroup, FormGroupDirective } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { Table } from "primeng/table";
import { GeneraForm } from "src/app/_helpers/genera-form";
import { Utility } from "src/app/_helpers/utility";
import { Cliente, Clienti } from "src/app/_interfaces/clienti";
import { Utente } from "src/app/_interfaces/utente";
import { AuthService } from "src/app/_services/auth.service";
import { ClientiService } from "src/app/_services/clienti.service";
import { UtentiService } from "src/app/_services/utenti.service";

@Component({
    selector: "app-anagrafica-select",
    templateUrl: "./anagrafica-select.component.html",
    styleUrls: ["./anagrafica-select.component.scss"],
})
export class AnagraficaSelectComponent implements OnInit {
    @Input() cliente: Cliente;
    @Input() editabile: boolean;
    @Output() clienteChange = new EventEmitter<Cliente>();

    form!: FormGroup;
    ruolo: string;
    legaleVisible = false;
    visibileNuovaAnagrafica = false;

    blockedDocument: boolean = false;

    id: string;
    utenti: Utente[];
    clienti: Clienti[];
    frmCliente: FormGroup;
    modalCliente = false;
    drpTipoCliente = true;

    haPartitaIva: { label: string; value: any }[] = [
        { label: "SI", value: "SI" },
        { label: "NO", value: "NO" },
    ];

    Tipologia: { label: string; value: any }[] = [
        { label: "Persona Fisica", value: "fisica" },
        { label: "Persona Giuridica", value: "giuridica" },
    ];

    StatoPreventivo: { label: string; value: any }[] = [
        { label: "ELABORATO", value: "1" },
        { label: "PRESENTATO", value: "2" },
        { label: "DA INSERIRE", value: "3" },
        { label: "APPROVATO", value: "4" },
        { label: "KO", value: "5" },
        { label: "SOSPESO", value: "6" },
    ];

    constructor(
        private utenteSrv: UtentiService,
        private clientiSrv: ClientiService,
        private authSrv: AuthService,
        private msgService: MessageService,
        private confirmService: ConfirmationService,
        private rootFormGroup: FormGroupDirective
    ) { }

    HaPartitaChange(ev): void {
        if (ev.value == "NO") {
            this.frmCliente.controls.cl_piva.setValue("");
        }
    }
    tipologiaChange(ev): void {
        this.legaleVisible = ev.value === "giuridica";

        if (this.legaleVisible) this.frmCliente.controls.cl_nome.setValue("");
        else this.frmCliente.controls.cl_nome.setValue(null);

        //this.creaFormCliente();
    }

    clear(table: Table) {
        table.clear();
    }

    ngOnInit(): void {
        this.refresh();
        this.ruolo = sessionStorage.getItem("ruolo");
        this.creaFormCliente();
        //this.drpTipoCliente = false;

        this.form = this.rootFormGroup.control.get(
            "anagraficaForm"
        ) as FormGroup;
        this.refreshUtenti();
        if (this.ruolo == "SE") {
            this.frmCliente.controls.cl_serramentista.setValue(
                Number(this.authSrv.getUserId())
            );
        }
    }

    refresh(): void {
        if (sessionStorage.getItem("ruolo") != "SE") {
            this.clientiSrv.getClienti().subscribe((res) => {
                this.clienti = [...res.data];
            });
        } else {
            this.clientiSrv
                .getClientiByUser(sessionStorage.getItem("id"))
                .subscribe((res) => {
                    this.clienti = [...res.data];
                });
        }
    }

    delCliente(idCliente: number): void {
        this.confirmService.confirm({
            header: `Elimina Cliente`,
            message: "Sicuro di Eseguire l'operazione di Cancellazione?",
            icon: "pi  pi-question-circle",
            accept: () => {
                this.clientiSrv.delCliente(idCliente, true).subscribe((res) => {
                    this.msgService.add({
                        key: "tst",
                        severity: res.res === "ok" ? "success" : "danger",
                        summary: `Elimina Cliente`,
                        detail: res.message,
                    });
                    this.refresh();
                });
            },
        });
    }

    selectCliente(cl: Cliente) {
        this.cliente = cl;
        this.clienteChange.emit(this.cliente);
        this.form.controls.prev_cliente.setValue(cl.cl_id);
        this.rootFormGroup.control.controls.prev_user.setValue(
            cl.cl_serramentista
        );
    }

    clearCliente() {
        this.cliente = null;
        this.clienteChange.emit(this.cliente);
        this.form.controls.prev_cliente.setValue("");
        this.rootFormGroup.control.controls["abitazioniForm"]
            .get("prev_abitazione")
            .setValue("");

        if (
            this.rootFormGroup.control.controls["tipologiaSelectForm"].get(
                "prev_tipologia"
            ) != null
        )
            this.rootFormGroup.control.controls["tipologiaSelectForm"]
                .get("prev_tipologia")
                .setValue("");

        if (
            this.rootFormGroup.control.controls["tipologiaSelectForm"].get(
                "prev_tipologia_intervento"
            ) != null
        )
            this.rootFormGroup.control.controls["tipologiaSelectForm"]
                .get("prev_tipologia_intervento")
                .setValue("");

        if (
            this.rootFormGroup.control.controls["tipologiaSelectForm"].get(
                "prev_tipo_unita"
            ) != null
        )
            this.rootFormGroup.control.controls["tipologiaSelectForm"]
                .get("prev_tipo_unita")
                .setValue("");

        if (
            this.rootFormGroup.control.controls["tipologiaSelectForm"].get(
                "prev_descrizione"
            ) != null
        )
            this.rootFormGroup.control.controls["tipologiaSelectForm"]
                .get("prev_descrizione")
                .setValue("");

        if (
            this.rootFormGroup.control.controls["tipologiaSelectForm"].get(
                "prev_chiusura"
            ) != null
        )
            this.rootFormGroup.control.controls["tipologiaSelectForm"]
                .get("prev_chiusura")
                .setValue("");

        if (
            this.rootFormGroup.control.controls["tipologiaSelectForm"].get(
                "prev_zona_climatica"
            ) != null
        )
            this.rootFormGroup.control.controls["tipologiaSelectForm"]
                .get("prev_zona_climatica")
                .setValue("");

        if (
            this.rootFormGroup.control.controls["tipologiaSelectForm"].get(
                "prev_prodotto_installato"
            ) != null
        )
            this.rootFormGroup.control.controls["tipologiaSelectForm"]
                .get("prev_prodotto_installato")
                .setValue("");

        if (
            this.rootFormGroup.control.controls["tipologiaSelectForm"].get(
                "prev_mq_installati"
            ) != null
        )
            this.rootFormGroup.control.controls["tipologiaSelectForm"]
                .get("prev_mq_installati")
                .setValue("");

        if (
            this.rootFormGroup.control.controls["tipologiaSelectForm"].get(
                "prev_mq_installati_co"
            ) != null
        )
            this.rootFormGroup.control.controls["tipologiaSelectForm"]
                .get("prev_mq_installati_co")
                .setValue("");

        if (
            this.rootFormGroup.control.controls["tipologiaSelectForm"].get(
                "prev_transmittanza"
            ) != null
        )
            this.rootFormGroup.control.controls["tipologiaSelectForm"]
                .get("prev_transmittanza")
                .setValue("");

        if (
            this.rootFormGroup.control.controls["tipologiaSelectForm"].get(
                "prev_tipologia_agevolazione"
            ) != null
        )
            this.rootFormGroup.control.controls["tipologiaSelectForm"]
                .get("prev_tipologia_agevolazione")
                .setValue("");

        if (
            this.rootFormGroup.control.controls["tipologiaSelectForm"].get(
                "prev_tipologia_interventoBC"
            ) != null
        )
            this.rootFormGroup.control.controls["tipologiaSelectForm"]
                .get("prev_tipologia_interventoBC")
                .setValue("");
    }

    @ViewChild("dt", { static: false }) dt: Table;
    @ViewChild("searchField", { static: false }) searchField;

    RemoveFind(): void {
        this.dt.clear();
        this.searchField.value = "";
        sessionStorage.removeItem("stateSchedeInt-session");
    }

    editUser() {
        this.clientiSrv.editUser(true);
    }

    editCliente(cliente: Cliente): void {
        this.drpTipoCliente = false;
        this.frmCliente.patchValue(cliente);
        this.showClienteDialog();

        if (cliente.cl_tipo == "giuridica") this.legaleVisible = true;
        else this.legaleVisible = false;

        // if (this.ruolo === "SE") {
        //     this.enabledSerr = false;
        // } else {
        //     this.enabledSerr = true;
        // }
    }

    aggiungiCliente(): void {
        this.drpTipoCliente = true;
        this.legaleVisible = false;
        this.creaFormCliente();
        if (this.ruolo == "SE") {
            this.frmCliente.controls.cl_serramentista.setValue(
                Number(this.authSrv.getUserId())
            );
        }
        this.showClienteDialog();
    }

    showClienteDialog(): void {
        this.modalCliente = true;
        Utility.updateFormValidity(this.frmCliente);
        // Utility.updateFormValidity(
        //     this.frmCliente.controls["documento"] as FormGroup
        // );
        // (this.frmCliente.get("residenza") as FormGroup)
        //     .get("r_nazione")
        //     .valueChanges.subscribe((value) => {
        //         if (value !== "Italia") {
        //             (this.frmCliente.get("residenza") as FormGroup)
        //                 .get("r_regione")
        //                 .disable();
        //             (this.frmCliente.get("residenza") as FormGroup)
        //                 .get("r_provincia")
        //                 .disable();
        //         } else {
        //             (this.frmCliente.get("residenza") as FormGroup)
        //                 .get("r_regione")
        //                 .enable();
        //             (this.frmCliente.get("residenza") as FormGroup)
        //                 .get("r_provincia")
        //                 .enable();
        //         }
        //         Utility.updateFormValidity(this.frmCliente);
        //     });
    }

    creaFormCliente() {
        this.frmCliente = GeneraForm.createFormClientePreventivo();
    }

    refreshUtenti() {
        return new Promise((resolve) => {
            this.utenteSrv.getUtenti("SE").subscribe((res) => {
                if (this.ruolo != "SE") {
                    this.utenti = [...res.data];
                } else {
                    this.utenti = [
                        ...res.data.filter(
                            (u) => u.id === Number(sessionStorage.getItem("id"))
                        ),
                    ];
                }
                resolve(true);
            });
        });
    }

    async SalvaAnagrafica() {
        let data = { ...this.frmCliente.value };

        this.blockedDocument = true;
        //nuova anagrafica mancano alcuni campi e li vado a ripreden a DB
        let clienteDatabase: Cliente;

        try {
            clienteDatabase = await this.clientiSrv
                .getCliente(data.cl_id)
                .toPromise();
        } catch (e) {
            clienteDatabase = {
                cl_id: 0,
                cl_serramentista: 0,
                cl_tipo: "",
                cl_cognome: "",
                cl_nome: "",
                cl_data_nascita: "",
                cl_nazione_nascita: "",
                cl_regione_nascita: "",
                cl_citta_nascita: "",
                cl_provincia_nascita: "",
                cl_telefono: "",
                cl_email: "",
                cl_pec: "",
                cl_cf: "",
                cl_piva: "",
                cl_cancellato: 0,
                cl_ha_piva: "",
                cl_note: "",
                name: "",
                documento: {
                    doc_tipo: 0,
                    doc_cliente: 0,
                    doc_numero: "",
                    doc_rilasciato_da: "",
                    doc_emissione: "",
                    doc_scadenza: "",
                },
                legale: {
                    rl_id: 0,
                    rl_cliente: 0,
                    rl_cognome: "",
                    rl_nome: "",
                    rl_telefono: "",
                    rl_email: "",
                    rl_cf: "",
                },
                residenza: {
                    r_id: 0,
                    r_cliente: 0,
                    r_nazione: "",
                    r_regione: "",
                    r_citta: "",
                    r_cap: "",
                    r_indirizzo: "",
                    r_civico: "",
                    r_provincia: "",
                },
                cnt_offerte_approvate: {
                    cnt: 0,
                },
            };
        }

        let anagKO: string
        data.cl_check_cf = true
        this.clientiSrv
            .addCliente({ ...clienteDatabase, ...data })
            .subscribe((res) => {
                anagKO = res.res
                if (anagKO != 'ko') {
                    this.msgService.add({
                        key: "tst",
                        severity: 'success',
                        summary: `Cliente`,
                        detail: "Cliente Salvato!",
                    });
                    this.blockedDocument = false;
                    this.refresh();
                    this.clientiSrv.editUser(false);
                    this.modalCliente = false;
                    this.creaFormCliente();
                }
                if (anagKO == 'ko' && data.cl_check_cf == true) {
                    this.blockedDocument = false;
                    this.modalCliente = false;
                    data.cl_check_cf = false
                    this.confirmService.confirm({
                        header: `Attenzione`,
                        message: "Anagrafica presente nei nostri archivi, continuare con il salvataggio?",
                        icon: "pi  pi-question-circle",
                        accept: () => {
                            this.clientiSrv
                                .addCliente({ ...clienteDatabase, ...data })
                                .subscribe((res) => {
                                    this.msgService.add({
                                        key: "tst",
                                        severity: res.res === 'ok' ? 'success' : 'error',
                                        summary: `Cliente`,
                                        detail: res.res === 'ok' ? "Cliente Salvato!" : "ERRORE Cliente NON Salvato!",
                                    });
                                    this.refresh();
                                    this.clientiSrv.editUser(false);
                                    this.creaFormCliente();
                                });
                        },
                    });
                }

            });

    }

    cancelModale(): void {
        this.modalCliente = false;
        this.creaFormCliente();
    }
}



