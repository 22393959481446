import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-menu",
    template: `
        <ul class="layout-menu">
            <li
                app-menuitem
                *ngFor="let item of model; let i = index"
                [item]="item"
                [index]="i"
                [root]="true"
            ></li>
        </ul>
    `,
})
export class AppMenuComponent implements OnInit {
    model: any[];

    ngOnInit() {
        this.model = [
            {
                label: "Menù",
                icon: "pi pi-fw pi-home",
                items: [
                    // { label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/'] },
                    {
                        label: "Preventivi",
                        icon: "pi pi-fw pi-book",
                        routerLink: ["/preventivi"],
                    },
                    {
                        label: "Schede Intervento",
                        icon: "pi pi-fw pi-user",
                        routerLink: ["/SchedeIntervento"],
                    },
                    {
                        label: "Offerte",
                        icon: "pi pi-fw pi-money-bill",
                        routerLink: ["/offerte"],
                    },
                    {
                        label: "Lavori",
                        icon: "pi pi-fw pi-th-large",
                        routerLink: ["/lavori"],
                    },
                    { label: "" },
                ],
            },
            {
                label: "Step Aggregazioni",
                icon: "pi pi-fw pi-home",
                items: [
                    {
                        label: "Cessione Credito",
                        icon: "pi pi-fw pi-dollar",
                        routerLink: ["/CessioneCredito"],
                    },
                    {
                        label: "Attestazione Cessione",
                        icon: "pi pi-fw pi-check-square",
                        routerLink: ["/AttestazioneCessione"],
                    },
                    {
                        label: "Liquidazione",
                        icon: "pi pi-fw pi-money-bill",
                        routerLink: ["/Liquidazione"],
                    },
                ],
            },

        ];
    }
}
