import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Offerta, Preventivo } from "src/app/_interfaces/lavori";
import {
    Catastale, TipoAgevolazione, TipoIntervento, TitolaritaImmobile, ZonaClimatica, ProdottiInstallati,
    CessionarioFiscale, InterventoStato,
} from "src/app/_interfaces/list-type";
import { LavoriService } from "src/app/_services/lavori.service";
import { ListTypeService } from "src/app/_services/list-type.service";
import { environment } from "src/environments/environment";
import { AppBreadcrumbService } from "src/app/app.breadcrumb.service";
import { AttrsSSCO, UtenteAttrs } from "./../../_interfaces/utente-attrs";
import { MessageService } from "primeng/api";
import { Utility } from "src/app/_helpers/utility";
import { UtentiAttrsService } from "src/app/_services/utenti-attrs.service";
import { SerramentistaService } from "src/app/_services/serramentista.service";
import * as _ from "lodash";
import { ReadVarExpr } from "@angular/compiler";

@Component({
    selector: "app-offerta-new",
    templateUrl: "./offerta-new.component.html",
    styleUrls: ["./offerta-new.component.scss"],
})
export class OffertaNewComponent implements OnInit {
    frmOfferta: FormGroup;
    environment = environment;
    offerta: Offerta;
    titImmobile: TitolaritaImmobile[];
    tipAgevolazioni: TipoAgevolazione[];
    tipoIntervento: TipoIntervento[];
    categorieCatastali: Catastale[];
    attrSI: UtenteAttrs[] = [];
    attrSSCO: AttrsSSCO;

    zonelimatiche: ZonaClimatica[] = [];
    filtroZonelimatiche: ZonaClimatica[];

    serramentoTipoProdotto: ProdottiInstallati[] = [];
    filtroTipoProdotto: ProdottiInstallati[];

    cessionariFiscali: CessionarioFiscale[];
    interventoStato: InterventoStato[];

    totaleFatturaIVA: number = 0;
    abilitaOneri: number = 0;
    ivaPonderata: number = 0;
    IvaPonderata10: number = 0;
    IvaPonderata22: number = 0;
    lavoroCodice: string = "";
    ruoloUtente: string = "SE";

    eccedenteContributoSpettante: number = 0;



    chiusuraOscurante: { label: string; value: any }[] = [
        { label: "SI", value: 1 },
        { label: "NO", value: 0 },
        { label: "<=0.35", value: 2 },
    ];

    cessionarioFinale: { label: string; value: any }[] = [];

    filtroChiusuraOscurante: { label: string; value: any }[] =
        this.chiusuraOscurante;

    AliquotaIvaPrestazioneOfferta: { label: string; value: any }[] = [
        { label: "0%", value: "0" },
        { label: "10%", value: "10" },
        { label: "22%", value: "22" },
    ];

    AliquotaIvaOfferta: { label: string; value: any }[] = [
        { label: "0%", value: "0" },
        { label: "10%", value: "10" },
        { label: "10% / 22%", value: "1022" },
        { label: "22%", value: "22" },
    ];

    AliquotaIvaAttuazione: { label: string; value: any }[] = [
        { label: "0%", value: 0 },
        { label: "10%", value: 10 },
        { label: "10% / 22%", value: 15 },
        { label: "22%", value: 22 },
    ];


    agevolazione: { label: string; value: any }[] = [
        { label: "Oneri Annegati", value: 1 },
        { label: "Oneri Esposti", value: 2 },
    ];

    fisica_giuridica: { label: string; value: any }[] = [
        { label: "NON AGEVOLABILE", value: "giuridica" },
        { label: "AGEVOLABILE", value: "fisica" },
    ];

    TipoDetrazione: { label: string; value: any }[] = [
        { label: "ECO Bonus", value: "ECO Bonus" },
        { label: "Bonus Casa", value: "Bonus Casa" }
    ];

    tipoVisto: { label: string; value: any }[] = [
        { label: "SI", value: "SI" },
        { label: "NO", value: "NO" },
    ];

    tipoAsseverazioneOrigin: { label: string; value: any }[] = [
        { label: "NO", value: "NO" },
        { label: "Ex DEI", value: "Ex DEI" },
        { label: "Ex All.I", value: "Ex All.I" },
        { label: "ALL. A", value: "ALL. A" },
        { label: "PREZZARI", value: "PREZZARI" },
    ];

    tipoAsseverazione: { label: string; value: any }[] = [
        { label: "NO", value: "NO" },
        { label: "Ex DEI", value: "Ex DEI" },
        { label: "Ex All.I", value: "Ex All.I" },
        { label: "ALL. A", value: "ALL. A" },
        { label: "PREZZARI", value: "PREZZARI" },
    ];

    // fisica_fiuridica: { label: string; value: any }[] = [
    //     { label: "Oneri Annegati", value: "giuridica" },1
    //     { label: "Oneri Esposti", value: "fisica" },2
    // ];

    filtroAliquotaIvaOfferta: { label: string; value: any }[] = this.AliquotaIvaOfferta;
    percentualeImponibile: Number = 0;
    percentualeOneri: Number = 0;
    ControlloFisicaGiuridica: string;

    constructor(
        private offertaSrv: LavoriService,
        private router: Router,
        private route: ActivatedRoute,
        private listTypeSrv: ListTypeService,
        private msgService: MessageService,
        private utenteAttrsSrv: UtentiAttrsService,
        private breadcrumbService: AppBreadcrumbService,
        private SerramentistaSrv: SerramentistaService,
    ) { }

    creaFormOfferta() {
        this.frmOfferta = new FormGroup({
            lav_id: new FormControl(""),
            lav_tipo: new FormControl(""),
            lav_tipo_unita: new FormControl(""),
            lav_tipo_bc: new FormControl(""),
            lav_tipologia: new FormControl(""),
            lav_user: new FormControl(""),
            lav_cliente: new FormControl(""),
            lav_abitazione: new FormControl(""),
            lav_titolarita: new FormControl(""),
            lav_codice: new FormControl(""),
            lav_praticaenea: new FormControl(0),
            lav_costoenea: new FormControl(0),
            lav_descrizione: new FormControl("", Validators.required),
            lav_tipo_detrazione: new FormControl("ECO Bonus", Validators.required),
            lav_tipologia_intervento: new FormControl("", Validators.required),
            lav_tipologia_agevolazione: new FormControl(0, Validators.required),
            lav_zona_climatica: new FormControl("", Validators.required),
            lav_prodotto_installato: new FormControl("", Validators.required),
            lav_mq_installati: new FormControl("", Validators.required),
            lav_mq_installati_co: new FormControl("0"),
            lav_transmittanza: new FormControl("", Validators.required),
            lav_iva_offerta: new FormControl("", Validators.required),
            lav_iva_prestazioni: new FormControl("", Validators.required),
            lav_iva_attualizzazione: new FormControl("", Validators.required),
            lav_pag_desc_1: new FormControl("", Validators.required),
            lav_pag_perc_1: new FormControl("", Validators.required),
            lav_pag_desc_2: new FormControl(""),
            lav_pag_perc_2: new FormControl(""),
            lav_pag_desc_3: new FormControl(""),
            lav_pag_perc_3: new FormControl(""),
            lav_consegna: new FormControl("90 giorni", Validators.required),
            lav_cessionario: new FormControl("", Validators.required),
            lav_vendita_infissi: new FormControl("", Validators.required),
            lav_beni_significativi: new FormControl("", Validators.required),
            lav_altri_beni: new FormControl("", Validators.required),
            lav_posa_opera: new FormControl("", Validators.required),
            lav_altre_opere: new FormControl("", Validators.required),
            lav_prestazioni_prof: new FormControl("", Validators.required),
            lav_chiusura: new FormControl(""),
            lav_stato: new FormControl(0),
            lav_cancellato: new FormControl(""),
            lav_note: new FormControl(""),
            lav_importo: new FormControl(0),
            lav_asseverazione: new FormControl("", Validators.required),
            lav_visto: new FormControl("", Validators.required),
            lav_imp_asseverazione: new FormControl(0),
            lav_file_step2: new FormControl(""),
            lav_finanz: new FormControl(0),
            lav_preventiviAttr: new FormControl(""),
            id: new FormControl(""),
            name: new FormControl(""),
            email: new FormControl(""),
            ruolo: new FormControl(""),
            abilitato: new FormControl(""),
            email_verified_at: new FormControl(""),
            password: new FormControl(""),
            remember_token: new FormControl(""),
            created_at: new FormControl(""),
            updated_at: new FormControl(""),
            cl_id: new FormControl(""),
            cl_serramentista: new FormControl(""),
            cl_tipo: new FormControl(""),
            cl_nome: new FormControl(""),
            cl_cognome: new FormControl(""),
            cl_data_nascita: new FormControl(""),
            cl_nazione_nascita: new FormControl(""),
            cl_regione_nascita: new FormControl(""),
            cl_citta_nascita: new FormControl(""),
            cl_provincia_nascita: new FormControl(""),
            cl_telefono: new FormControl(""),
            cl_email: new FormControl(""),
            cl_pec: new FormControl(""),
            cl_cf: new FormControl(""),
            cl_piva: new FormControl(""),
            cl_cancellato: new FormControl(""),
            cl_note: new FormControl(""),
            ab_id: new FormControl(""),
            ab_cliente: new FormControl(""),
            ab_provincia: new FormControl(""),
            ab_citta: new FormControl(""),
            ab_indirizzo: new FormControl(""),
            ab_civico: new FormControl(""),
            ab_cap: new FormControl(""),
            ab_codice_comune: new FormControl(""),
            ab_foglio: new FormControl(""),
            ab_mappale: new FormControl(""),
            ab_mq_immobile: new FormControl(""),
            ab_mq_infissi: new FormControl(""),
            ab_categoria_catastale: new FormControl(""),
            ab_subalterno: new FormControl(""),
            ab_titolarita: new FormControl(0),
            ab_cancellata: new FormControl(""),
            ab_data_aggiunta: new FormControl(""),
            ab_data_modifica: new FormControl(""),
            ti_id: new FormControl(""),
            ti_alias: new FormControl(""),
            ti_nome: new FormControl(""),
            tin_id: new FormControl(""),
            tin_alias: new FormControl(""),
            tin_nome: new FormControl(""),
            ta_id: new FormControl(""),
            ta_alias: new FormControl(""),
            ta_nome: new FormControl(""),
            zc_codice: new FormControl(""),
            pi_id: new FormControl(""),
            pi_alias: new FormControl(""),
            pi_nome: new FormControl(""),
            cf_id: new FormControl(""),
            cf_alias: new FormControl(""),

            lat_id: new FormControl("0"),
            lat_lavoro: new FormControl("0"),
            lat_tipo: new FormControl("", Validators.required),
            //  attrs_FisicaGiuridica: new FormControl("", Validators.required),
            lat_oneri: new FormControl("", Validators.required),
            lat_detrazione: new FormControl(""),
            lat_cessionario: new FormControl(0),
            lat_chiusura_pratica: new FormControl(""),
            lat_range_min: new FormControl(""),
            lat_range_max: new FormControl(""),
            lat_costo_fisso: new FormControl(""),
            lat_imp: new FormControl(""),
            lat_costo_min: new FormControl(""),
            lat_costo_max: new FormControl(""),
            lat_oneri_att: new FormControl(""),
            lat_costo_asseverazione: new FormControl("0"),
            lat_costo_visto: new FormControl("0"),
            lat_perc_asseverazione: new FormControl("0"),
            lat_perc_visto: new FormControl("0"),

            lav_preventivo: new FormGroup({
                imponibileFornitura: new FormControl(0),
                massimaleMQdaApplicare: new FormControl(0),
                massimaleMQdaApplicare_CON_CO: new FormControl(0),
                costoMQImponibile: new FormControl(0),
                imponibileMassimoDetraibile: new FormControl(0),
                imponibileMassimoDetraibile_CON_CO: new FormControl(0),
                importoNonDetraibile: new FormControl(0),
                manoOperaImponibile: new FormControl(0),
                imponibileSerramentista: new FormControl(0),
                speseTecniche: new FormControl(0),
                nuovoImponibile: new FormControl(0),
                totaleFatturaIva: new FormControl(0),
                contributoSpettante: new FormControl(0),
                ImportoLavoriCaricoCliente: new FormControl(0),
                importoNONDetraibileACaricoCliente: new FormControl(0),
                costiAttuazione: new FormControl(0),
                costiAttuazioneIVA: new FormControl(0),
                totaleDovutoCliente: new FormControl(0),
                posaOperaImponibile: new FormControl(0),
                posaOpera10: new FormControl(0),
                posaOpera22: new FormControl(0),
                posaOperaTot: new FormControl(0),
                altibeniaccImponibile: new FormControl(0),
                altibeniacc10: new FormControl(0),
                altibeniacc22: new FormControl(0),
                altibeniaccTot: new FormControl(0),
                valore10Imponibile: new FormControl(0),
                valore1010: new FormControl(0),
                valore1022: new FormControl(0),
                valore10Tot: new FormControl(0),
                valore22Imponibile: new FormControl(0),
                valore2210: new FormControl(0),
                valore2222: new FormControl(0),
                valore22Tot: new FormControl(0),
                altreOpereImponibile: new FormControl(0),
                altreOpere10: new FormControl(0),
                altreOpere22: new FormControl(0),
                altreOpereTot: new FormControl(0),
                prestProfImponibile: new FormControl(0),
                prestProf10: new FormControl(0),
                prestProf22: new FormControl(0),
                prestProfTot: new FormControl(0),
                ftImponibile: new FormControl(0),
                ft10: new FormControl(0),
                ft22: new FormControl(0),
                ftTot: new FormControl(0),
                CA_EsenteImponibile: new FormControl(0),
                CA_EsenteIva10: new FormControl(0),
                CA_EsenteIva22: new FormControl(0),
                CA_EsenteImporto: new FormControl(0),
                CA_10Imponibile: new FormControl(0),
                CA_10Iva10: new FormControl(0),
                CA_10Iva22: new FormControl(0),
                CA_10Totale: new FormControl(0),
                CA_22Imponibile: new FormControl(0),
                CA_22Iva10: new FormControl(0),
                CA_22Iva22: new FormControl(0),
                CA_22Totale: new FormControl(0),
                CAImponibile: new FormControl(0),
                CA10: new FormControl(0),
                CA22: new FormControl(0),
                CATot: new FormControl(0),
            }),
        });
    }

    ngOnInit(): void {
        this.eccedenteContributoSpettante = 0;
        this.breadcrumbService.setItems([{ label: "Offerta" }]);
        this.ruoloUtente = sessionStorage.getItem("ruolo");
        this.abilitaOneri = Number(sessionStorage.getItem("abilita_oneri_attuazione"));
        this.creaFormOfferta();
        this.refreshCessionariFinali()
        this.refreshTitolariImmobili();
        this.refreshTipologieAgevolazioni();
        this.refreshTipoloInterventi();
        this.refreshCategorieCatastali();
        this.refreshZoneClimatiche();
        this.refreshProdottiInstallati();
        this.refreshCessionarioFinale();
        this.refreshInterventoStati();
        this.refreshOfferta(this.route.snapshot.paramMap.get("id") ?? "0");
        if (this.frmOfferta.controls.lav_tipo.value === "intervento") {
            this.frmOfferta.controls.lav_consegna.setValue("90 giorni");
            this.frmOfferta.controls.lav_cessionario.setValue("1");
            this.frmOfferta.controls.lav_stato.setValue("1");
        }
        Utility.updateFormValidity(this.frmOfferta);
    }

    refreshCessionariFinali(): void {
        this.SerramentistaSrv.getCessionarifinali().subscribe((res) => {
            this.cessionarioFinale = [];
            [...res.data].forEach((item) => {
                this.cessionarioFinale.push({ label: item.serr_ragsoc, value: item.serr_id })
            })
        });
    }

    refreshOfferta(id: string): void {
        this.offertaSrv.getInterventoID(id).subscribe((res) => {
            this.offerta = res.data;
            this.lavoroCodice = res.data.lav_codice;

            if (new Date(res.data.lav_data_aggiunta) > new Date("2022-05-05")) {
                this.tipoAsseverazione = this.tipoAsseverazioneOrigin.filter((e) =>
                    e.label != "Ex DEI" && e.label != "Ex All.I")

            } else {
                this.tipoAsseverazione = this.tipoAsseverazioneOrigin.filter((e) =>
                    e.label != "ALL. A" && e.label != "PREZZARI")

            }

            this.frmOfferta.patchValue(res.data);

            if (this.frmOfferta.controls.lav_tipologia_agevolazione.value == 1) { this.frmOfferta.controls.lav_tipologia_agevolazione.setValue(50) }
            this.frmOfferta.controls.lat_cessionario.setValue(Number(this.frmOfferta.controls.lat_cessionario.value))
            this.frmOfferta.controls.lat_oneri.setValue(Number(this.frmOfferta.controls.lat_oneri.value))

            this.FiltraTipologiaIntervento(this.frmOfferta.controls.lav_tipologia_intervento.value);
            let prObj: Preventivo = JSON.parse(res.data.lav_preventivo.toString());
            this.frmOfferta.get("lav_preventivo.imponibileFornitura").setValue(prObj.imponibileFornitura);
            this.frmOfferta.get("lav_preventivo.massimaleMQdaApplicare").setValue(prObj.massimaleMQdaApplicare);
            if (!("massimaleMQdaApplicare_CON_CO" in prObj)) {
                this.frmOfferta.get("lav_preventivo.massimaleMQdaApplicare_CON_CO").setValue(0);
            }
            else {
                this.frmOfferta.get("lav_preventivo.massimaleMQdaApplicare_CON_CO").setValue(prObj.massimaleMQdaApplicare_CON_CO);
            }

            this.frmOfferta.get("lav_preventivo.costoMQImponibile").setValue(prObj.costoMQImponibile);
            this.frmOfferta.get("lav_preventivo.imponibileMassimoDetraibile").setValue(prObj.imponibileMassimoDetraibile);
            if (!("imponibileMassimoDetraibile_CON_CO" in prObj)) {
                this.frmOfferta.get("lav_preventivo.imponibileMassimoDetraibile_CON_CO").setValue(0);
            }
            else {
                this.frmOfferta.get("lav_preventivo.imponibileMassimoDetraibile_CON_CO").setValue(prObj.imponibileMassimoDetraibile_CON_CO);
            }

            this.frmOfferta.get("lav_preventivo.importoNonDetraibile").setValue(prObj.importoNonDetraibile);
            this.frmOfferta.get("lav_preventivo.manoOperaImponibile").setValue(prObj.manoOperaImponibile);
            this.frmOfferta.get("lav_preventivo.imponibileSerramentista").setValue(prObj.imponibileSerramentista);
            this.frmOfferta.get("lav_preventivo.speseTecniche").setValue(prObj.speseTecniche);
            this.frmOfferta.get("lav_preventivo.nuovoImponibile").setValue(prObj.nuovoImponibile);
            this.frmOfferta.get("lav_preventivo.totaleFatturaIva").setValue(prObj.totaleFatturaIva);
            this.frmOfferta.get("lav_preventivo.contributoSpettante").setValue(prObj.contributoSpettante);
            this.frmOfferta.get("lav_preventivo.ImportoLavoriCaricoCliente").setValue(prObj.ImportoLavoriCaricoCliente);
            this.frmOfferta.get("lav_preventivo.importoNONDetraibileACaricoCliente").setValue(prObj.importoNONDetraibileACaricoCliente);
            this.frmOfferta.get("lav_preventivo.costiAttuazione").setValue(prObj.costiAttuazione);
            this.frmOfferta.get("lav_preventivo.costiAttuazioneIVA").setValue(prObj.costiAttuazioneIVA);
            this.frmOfferta.get("lav_preventivo.totaleDovutoCliente").setValue(prObj.totaleDovutoCliente);
            this.frmOfferta.get("lav_preventivo.posaOperaImponibile").setValue(prObj.posaOperaImponibile);
            this.frmOfferta.get("lav_preventivo.posaOpera10").setValue(prObj.posaOpera10);
            this.frmOfferta.get("lav_preventivo.posaOpera22").setValue(prObj.posaOpera22);
            this.frmOfferta.get("lav_preventivo.posaOperaTot").setValue(prObj.posaOperaTot);
            this.frmOfferta.get("lav_preventivo.altibeniaccImponibile").setValue(prObj.altibeniaccImponibile);
            this.frmOfferta.get("lav_preventivo.altibeniacc10").setValue(prObj.altibeniacc10);
            this.frmOfferta.get("lav_preventivo.altibeniacc22").setValue(prObj.altibeniacc22);
            this.frmOfferta.get("lav_preventivo.altibeniaccTot").setValue(prObj.altibeniaccTot);
            this.frmOfferta.get("lav_preventivo.valore10Imponibile").setValue(prObj.valore10Imponibile);
            this.frmOfferta.get("lav_preventivo.valore1010").setValue(prObj.valore1010);
            this.frmOfferta.get("lav_preventivo.valore1022").setValue(prObj.valore1022);
            this.frmOfferta.get("lav_preventivo.valore10Tot").setValue(prObj.valore10Tot);
            this.frmOfferta.get("lav_preventivo.valore22Imponibile").setValue(prObj.valore22Imponibile);
            this.frmOfferta.get("lav_preventivo.valore2210").setValue(prObj.valore2210);
            this.frmOfferta.get("lav_preventivo.valore2222").setValue(prObj.valore2222);
            this.frmOfferta.get("lav_preventivo.valore22Tot").setValue(prObj.valore22Tot);
            this.frmOfferta.get("lav_preventivo.altreOpereImponibile").setValue(prObj.altreOpereImponibile);
            this.frmOfferta.get("lav_preventivo.altreOpere10").setValue(prObj.altreOpere10);
            this.frmOfferta.get("lav_preventivo.altreOpere22").setValue(prObj.altreOpere22);
            this.frmOfferta.get("lav_preventivo.altreOpereTot").setValue(prObj.altreOpereTot);
            this.frmOfferta.get("lav_preventivo.prestProfImponibile").setValue(prObj.prestProfImponibile);
            this.frmOfferta.get("lav_preventivo.prestProf10").setValue(prObj.prestProf10);
            this.frmOfferta.get("lav_preventivo.prestProf22").setValue(prObj.prestProf22);
            this.frmOfferta.get("lav_preventivo.prestProfTot").setValue(prObj.prestProfTot);
            this.frmOfferta.get("lav_preventivo.ftImponibile").setValue(prObj.ftImponibile);
            this.frmOfferta.get("lav_preventivo.ft10").setValue(prObj.ft10);
            this.frmOfferta.get("lav_preventivo.ft22").setValue(prObj.ft22);
            this.frmOfferta.get("lav_preventivo.ftTot").setValue(prObj.ftTot);

            this.frmOfferta.get("lav_preventivo.CA_EsenteImponibile").setValue(prObj.CA_EsenteImponibile);
            this.frmOfferta.get("lav_preventivo.CA_EsenteIva10").setValue(prObj.CA_EsenteIva10);
            this.frmOfferta.get("lav_preventivo.CA_EsenteIva22").setValue(prObj.CA_EsenteIva22);
            this.frmOfferta.get("lav_preventivo.CA_EsenteImporto").setValue(prObj.CA_EsenteImporto);
            this.frmOfferta.get("lav_preventivo.CA_10Imponibile").setValue(prObj.CA_10Imponibile);
            this.frmOfferta.get("lav_preventivo.CA_10Iva10").setValue(prObj.CA_10Iva10);
            this.frmOfferta.get("lav_preventivo.CA_10Iva22").setValue(prObj.CA_10Iva22);
            this.frmOfferta.get("lav_preventivo.CA_10Totale").setValue(prObj.CA_10Totale);
            this.frmOfferta.get("lav_preventivo.CA_22Imponibile").setValue(prObj.CA_22Imponibile);
            this.frmOfferta.get("lav_preventivo.CA_22Iva10").setValue(prObj.CA_22Iva10);
            this.frmOfferta.get("lav_preventivo.CA_22Iva22").setValue(prObj.CA_22Iva22);
            this.frmOfferta.get("lav_preventivo.CA_22Totale").setValue(prObj.CA_22Totale);
            this.frmOfferta.get("lav_preventivo.CAImponibile").setValue(prObj.CAImponibile);
            this.frmOfferta.get("lav_preventivo.CA10").setValue(prObj.CA10);
            this.frmOfferta.get("lav_preventivo.CA22").setValue(prObj.CA22);
            this.frmOfferta.get("lav_preventivo.CATot").setValue(prObj.CATot);

            if (res.data.lav_tipo_detrazione == null || res.data.lav_tipo_detrazione == "") {
                this.frmOfferta.controls.lav_tipo_detrazione.setValue("ECO Bonus")
            }

        });

    }

    refreshTitolariImmobili(): void {
        this.listTypeSrv.getTitolaritaImmobile().subscribe((res) => {
            this.titImmobile = [...res.data];
        });
    }

    refreshTipologieAgevolazioni(): void {
        this.listTypeSrv.getTipoAgevolazione().subscribe((res) => {
            this.tipAgevolazioni = [...res.data];
        });
    }

    refreshTipoloInterventi(): void {
        this.listTypeSrv.getTipoInterventi().subscribe((res) => {
            this.tipoIntervento = [...res.data];
        });
    }

    refreshCategorieCatastali(): void {
        this.listTypeSrv.getCatastale().subscribe((res) => {
            this.categorieCatastali = [...res.data];
        });
    }

    refreshZoneClimatiche(): void {
        this.listTypeSrv.getZonaClimatica().subscribe((res) => {
            this.zonelimatiche = [...res.data];
            this.filtroZonelimatiche = [...res.data];
        });
    }

    refreshProdottiInstallati(): void {
        this.listTypeSrv.getProdottiInstallati().subscribe((res) => {
            this.serramentoTipoProdotto = [...res.data];
            this.filtroTipoProdotto = [...res.data];
        });
    }

    refreshCessionarioFinale(): void {
        this.listTypeSrv.getCessionariFiscali().subscribe((res) => {
            this.cessionariFiscali = [...res.data];
        });
    }

    refreshInterventoStati(): void {
        this.listTypeSrv.getInterventoStati().subscribe((res) => {
            this.interventoStato = [...res.data];
        });
    }

    Trasmittanza(ev): void {
        if (ev.value === "A" || ev.value === "B") {
            this.frmOfferta.controls.lav_transmittanza.setValue("<= a 2,60 W/m2k");
        } else if (ev.value === "C") {
            this.frmOfferta.controls.lav_transmittanza.setValue("<= a 1,75 W/m2k");
        } else if (ev.value === "D") {
            this.frmOfferta.controls.lav_transmittanza.setValue("<= a 1,67 W/m2k");
        } else if (ev.value === "E") {
            this.frmOfferta.controls.lav_transmittanza.setValue("<= a 1,30 W/m2k");
        } else if (ev.value === "F") {
            this.frmOfferta.controls.lav_transmittanza.setValue("<= a 1,00 W/m2k");
        } else {
            this.frmOfferta.controls.lav_transmittanza.setValue("0,08 Km2/w <= Rsup <= 0,455 Km2/w");
        }
    }

    submit(): void {
        if (!this.VerificaValidazioneForm()) {
            return;
        } else {

            this.frmOfferta.controls.lav_tipo.setValue("offerta");
            this.frmOfferta.controls.lav_stato.setValue("1");

            this.frmOfferta.controls.lav_cessionario.setValue(this.frmOfferta.controls.lat_cessionario.value)

            this.offertaSrv
                .addIntervento(this.frmOfferta.value)
                .subscribe((res) => {
                    this.msgService.add({
                        key: "tst",
                        severity: res.res === "ok" ? "success" : "error",
                        summary: `Offerta`,
                        detail:
                            res.res === "ok" ? "Offerta Salvata" : "Errore in fase di Salvataggio",
                    });
                    this.router.navigate(["offerte"]);
                });
        }
    }

    VerificaValidazioneForm(): boolean {
        if (this.frmOfferta.controls.lav_tipologia_intervento.value === 1) {
            if (
                Number(this.frmOfferta.controls.lav_beni_significativi.value) > 0 &&
                Number(this.frmOfferta.controls.lav_beni_significativi.value) > Number(this.frmOfferta.controls.lav_vendita_infissi.value)
            ) {
                this.msgService.add({
                    key: "tst",
                    severity: "error",
                    summary: `ERRORE`,
                    detail: "Attenzione, il valore dei beni significativi deve essere < o = al valore di vendita infissi",
                });
                return false;
            }
        } else if (this.frmOfferta.controls.lav_tipologia_intervento.value === 2) {
            if (
                Number(this.frmOfferta.controls.lav_vendita_infissi.value) <= 0
            ) {
                this.msgService.add({
                    key: "tst",
                    severity: "error",
                    summary: `ERRORE`,
                    detail: "Attenzione, il valore Valore di vendita schermature solari deve essere > 0",
                });
                return false;
            }
        } else if (this.frmOfferta.controls.lav_tipologia_intervento.value === 5) {
            if (Number(this.frmOfferta.controls.lav_beni_significativi.value) <= 0) {
                this.msgService.add({
                    key: "tst",
                    severity: "error",
                    summary: `ERRORE`,
                    detail: "Attenzione, il valore di vendita chiusure oscuranti tecniche deve essere > 0",
                });
                return false;
            }
        }

        if (Number(this.frmOfferta.controls.lav_posa_opera.value) === 0) {
            this.msgService.add({
                key: "tst",
                severity: "error",
                summary: `ERRORE`,
                detail: "Attenzione, è obbligatorio valorizzare la posa in opera",
            });
            return false;
        }

        if (this.frmOfferta.controls.lav_tipologia_intervento.value == 1) {
            if (this.frmOfferta.controls.lav_beni_significativi.value === null || Number(this.frmOfferta.controls.lav_beni_significativi.value) === 0) {
                this.msgService.add({
                    key: "tst",
                    severity: "error",
                    summary: `ERRORE`,
                    detail: "Attenzione, è obbligatorio valorizzare Beni significativi",
                });
                return false;
            }
        }

        if (this.frmOfferta.controls.lat_tipo.value === null) {
            this.msgService.add({
                key: "tst",
                severity: "error",
                summary: `ERRORE`,
                detail: "Attenzione, è obbligatorio valorizzare Tipo Preventivatore",
            });
            return false;
        }
        return true;
    }

    FiltraTipologiaIntervento(idTipInt: number): void {
        if (idTipInt === 1) {
            this.filtroChiusuraOscurante = this.chiusuraOscurante.filter((f) => f.value < 2);
            this.filtroZonelimatiche = this.zonelimatiche.filter(
                (f) =>
                    f.zc_codice === "A" ||
                    f.zc_codice === "B" ||
                    f.zc_codice === "C" ||
                    f.zc_codice === "D" ||
                    f.zc_codice === "E" ||
                    f.zc_codice === "F"
            );
            this.filtroTipoProdotto = this.serramentoTipoProdotto.filter((f) => f.pi_id <= 2);
            this.filtroAliquotaIvaOfferta = this.AliquotaIvaOfferta;
        } else {
            this.filtroChiusuraOscurante = this.chiusuraOscurante.filter((f) => f.value === 2);
            this.filtroZonelimatiche = this.zonelimatiche.filter(
                (f) =>
                    f.zc_codice != "A" &&
                    f.zc_codice != "B" &&
                    f.zc_codice != "C" &&
                    f.zc_codice != "D" &&
                    f.zc_codice != "E" &&
                    f.zc_codice != "F"
            );
            this.filtroTipoProdotto = this.serramentoTipoProdotto.filter((f) => f.pi_id > 2);
            this.filtroAliquotaIvaOfferta = this.AliquotaIvaOfferta.filter((f) => f.value != "1022");
        }
    }

    CalcolaPreventivo(): void {
        if (!this.VerificaValidazioneForm()) {
            return;
        }

        if (this.frmOfferta.controls.lav_tipologia_intervento.value === 1) {
            this.frmOfferta.controls.lav_tipologia.setValue("SI")
            this.DatiAttrsUtente("si");
        } else {
            this.DatiAttrsUtente("ssco");
            this.frmOfferta.controls.lav_tipologia.setValue("SSCO")
        }
        this.msgService.add({
            key: "tst",
            severity: "success",
            summary: `Calcolo`,
            detail: "Preventivo Calcolato con Successo",
        });
    }

    DatiAttrsUtente(tipo: string): void {
        this.ControlloFisicaGiuridica = this.frmOfferta.controls.lat_tipo.value;

        this.utenteAttrsSrv.getUserAttrs(this.frmOfferta.controls.id.value, tipo).subscribe((res) => {
            if (tipo === "si") {
                this.frmOfferta.get(["lav_preventivo", "imponibileSerramentista"]).setValue(
                    Number(this.frmOfferta.controls.lav_vendita_infissi.value) +
                    Number(this.frmOfferta.controls.lav_posa_opera.value) +
                    Number(this.frmOfferta.controls.lav_altri_beni.value) +
                    Number(this.frmOfferta.controls.lav_altre_opere.value) +
                    Number(this.frmOfferta.controls.lav_prestazioni_prof.value));
            } else if (tipo === "ssco") {
                this.frmOfferta.get(["lav_preventivo", "imponibileSerramentista"]).setValue(
                    Number(this.frmOfferta.controls.lav_vendita_infissi.value) +
                    Number(this.frmOfferta.controls.lav_posa_opera.value) +
                    Number(this.frmOfferta.controls.lav_beni_significativi.value) +
                    Number(this.frmOfferta.controls.lav_altri_beni.value) +
                    Number(this.frmOfferta.controls.lav_altre_opere.value) +
                    Number(this.frmOfferta.controls.lav_prestazioni_prof.value));
            }

            let range1min: string;
            let range1max: string;
            let range2min: string;
            let range2max: string;
            let indice: string = "0";

            range1min = res.data.find((i) => i.ua_chiave === `${tipo}_1rangeMin`).ua_valore;
            range1max = res.data.find((i) => i.ua_chiave === `${tipo}_1rangeMax`).ua_valore;
            range2min = res.data.find((i) => i.ua_chiave === `${tipo}_2rangeMin`).ua_valore;
            range2max = res.data.find((i) => i.ua_chiave === `${tipo}_2rangeMax`).ua_valore;

            if (_.inRange(Number(this.frmOfferta.get(["lav_preventivo", "imponibileSerramentista"]).value), Number(range1min), Number(range1max))) {
                indice = "1";
            } else if (_.inRange(Number(this.frmOfferta.get(["lav_preventivo", "imponibileSerramentista"]).value), Number(range2min), Number(range2max))) {
                indice = "2";
            } else { indice = "3" };

            this.percentualeImponibile = Number(this.getValoreByKey(`${tipo}_${indice}percImp`, res.data));

            if (this.frmOfferta.controls.lat_id.value == null) {
                this.percentualeOneri = Number(this.getValoreByKey(`${tipo}_${indice}percOneri`, res.data));
                this.frmOfferta.controls.lat_oneri_att.setValue(this.getValoreByKey(`${tipo}_${indice}percOneri`, res.data));
            } else {
                this.percentualeOneri = this.frmOfferta.controls.lat_oneri_att.value;
            }

            this.frmOfferta.controls.lat_imp.setValue(this.getValoreByKey(`${tipo}_${indice}percImp`, res.data));
            this.frmOfferta.controls.lat_range_min.setValue(this.getValoreByKey(`${tipo}_${indice}rangeMin`, res.data));
            this.frmOfferta.controls.lat_range_max.setValue(this.getValoreByKey(`${tipo}_${indice}rangeMax`, res.data));
            this.frmOfferta.controls.lat_costo_fisso.setValue(this.getValoreByKey(`${tipo}_${indice}costoFisso`, res.data));
            this.frmOfferta.controls.lat_costo_min.setValue(this.getValoreByKey(`${tipo}_${indice}costoMin`, res.data));
            this.frmOfferta.controls.lat_costo_max.setValue(this.getValoreByKey(`${tipo}_${indice}costoMax`, res.data));
            this.frmOfferta.controls.lat_costo_asseverazione.setValue(this.getValoreByKey(`${tipo}_${indice}costoAssev`, res.data));
            this.frmOfferta.controls.lat_perc_asseverazione.setValue(this.getValoreByKey(`${tipo}_${indice}percAssev`, res.data));
            this.frmOfferta.controls.lat_costo_visto.setValue(this.getValoreByKey(`${tipo}_${indice}costoVisto`, res.data));
            this.frmOfferta.controls.lat_perc_visto.setValue(this.getValoreByKey(`${tipo}_${indice}percVisto`, res.data));
            this.frmOfferta.controls.lat_detrazione.setValue(this.getValoreByKey(`${tipo}_detrazione`, res.data));
            this.frmOfferta.controls.lat_oneri.setValue(Number(this.getValoreByKey(`${tipo}_preventivatore`, res.data)));
            this.frmOfferta.controls.lat_cessionario.setValue(Number(this.getValoreByKey(`${tipo}_cessionario`, res.data)));
            this.frmOfferta.controls.lat_chiusura_pratica.setValue(this.getValoreByKey(`${tipo}_chiusuraPratica`, res.data));
            if (tipo === "si") { this.CalcolaPreventivo_SSI(); }
            else if (tipo === "ssco") { this.CalcolaPreventivo_SSCO(); }
        });
    }

    getValoreByKey = (key: string, array: any[]) => array.find((i) => i.ua_chiave === key).ua_valore;


    BloccaIva(ev): void {
        if (ev.value === 'Ex DEI' || ev.value === 'Ex All.I' || ev.value === 'ALL. A' || ev.value === 'PREZZARI') {
            this.frmOfferta.controls.lav_iva_prestazioni.setValue("22")
        }
        if (this.frmOfferta.controls.lav_iva_offerta.value == 0) {
            this.frmOfferta.controls.lav_iva_prestazioni.setValue("0")
        }
    }

    modificaIvaPrestazioni = (ev): void => {
        if (ev.value === '0') {
            this.frmOfferta.controls.lav_iva_prestazioni.setValue("0")
        }
        else {
            this.frmOfferta.controls.lav_iva_prestazioni.setValue("22")
        }
    }


    //////////////////////////////////////INIZIO CALCOLO SSI
    CalcolaPreventivo_SSI(): void {

        this.frmOfferta.get(["lav_preventivo", "manoOperaImponibile"]).setValue(
            Number(this.frmOfferta.controls.lav_posa_opera.value) +
            Number(this.frmOfferta.controls.lav_vendita_infissi.value) -
            Number(this.frmOfferta.controls.lav_beni_significativi.value));

        this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).setValue(
            (Number((this.frmOfferta.get(["lav_preventivo", "imponibileSerramentista"]).value) *
                Number(this.percentualeImponibile)) / 100) +
            Number(this.frmOfferta.controls.lat_costo_fisso.value) //valore precedente 7.5
        );


        // inizio Calcolo scelta per Asseverazione e Visto NO EX DEI
        let impFornitura: number = Number(this.frmOfferta.get(["lav_preventivo", "imponibileSerramentista"]).value);
        let SpeseTecniche: number = Number(this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).value);
        let CalcoloAss: number = 0;
        let CalcoloVisto: number = 0;

        if (this.frmOfferta.controls.lav_visto.value == "SI") {
            CalcoloVisto = ((impFornitura * Number(this.frmOfferta.controls.lat_perc_visto.value)) / 100) + Number(this.frmOfferta.controls.lat_costo_visto.value);
        }
        if (this.frmOfferta.controls.lav_asseverazione.value != "NO") {
            CalcoloAss = ((impFornitura * Number(this.frmOfferta.controls.lat_perc_asseverazione.value)) / 100) + Number(this.frmOfferta.controls.lat_costo_asseverazione.value);
        }

        SpeseTecniche = SpeseTecniche + CalcoloAss + CalcoloVisto;
        this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).setValue(SpeseTecniche);


        if (Number(this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).value) < Number(this.frmOfferta.controls.lat_costo_min.value)) {
            this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).setValue(this.frmOfferta.controls.lat_costo_min.value)
        }
        if (Number(this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).value) > Number(this.frmOfferta.controls.lat_costo_max.value)) {
            this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).setValue(this.frmOfferta.controls.lat_costo_max.value)
        }

        this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).setValue(
            Number(this.frmOfferta.get(["lav_preventivo", "imponibileSerramentista"]).value) +
            Number(this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).value)
        );
        // fine Calcolo scelta per Asseverazione e Visto NO EX DEI


        // Calcolo Iva Ponderata 10 %
        this.IvaPonderata10 = 0;
        if (
            this.frmOfferta.controls.lav_iva_prestazioni.value == "22" &&
            ((Number(this.frmOfferta.get(["lav_preventivo", "manoOperaImponibile"]).value) +
                Number(this.frmOfferta.controls.lav_altri_beni.value) +
                Number(this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).value) +
                Number(this.frmOfferta.controls.lav_altre_opere.value)) * 2) /
            (Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value - Number(this.frmOfferta.controls.lav_prestazioni_prof.value))) > 1
        ) {

            this.IvaPonderata10 = 1 - (Number(this.frmOfferta.controls.lav_prestazioni_prof.value) / Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value));

        } else if (this.frmOfferta.controls.lav_iva_prestazioni.value == "22") {
            this.IvaPonderata10 = ((Number(this.frmOfferta.get(["lav_preventivo", "manoOperaImponibile"]).value) +
                Number(this.frmOfferta.controls.lav_altri_beni.value) +
                Number(this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).value) +
                Number(this.frmOfferta.controls.lav_altre_opere.value)) * 2) /
                Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value);


        } else if (((Number(this.frmOfferta.get(["lav_preventivo", "manoOperaImponibile"]).value) +
            Number(this.frmOfferta.controls.lav_altri_beni.value) +
            Number(this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).value) +
            Number(this.frmOfferta.controls.lav_altre_opere.value) +
            Number(this.frmOfferta.controls.lav_prestazioni_prof.value)) * 2) /
            Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value) > 1
        ) { this.IvaPonderata10 = 1; }
        else {
            this.IvaPonderata10 = ((this.frmOfferta.get(["lav_preventivo", "manoOperaImponibile"]).value +
                Number(this.frmOfferta.controls.lav_altri_beni.value) +
                Number(this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).value) +
                Number(this.frmOfferta.controls.lav_altre_opere.value) +
                Number(this.frmOfferta.controls.lav_prestazioni_prof.value)) * 2) /
                Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value);
        }

        // Calcolo Iva Ponderata 22 %
        this.IvaPonderata22 = 0;

        let calcoloIP22 = (Number(this.frmOfferta.controls.lav_beni_significativi.value) -
            Number(this.frmOfferta.controls.lav_altri_beni.value) -
            Number(this.frmOfferta.get(["lav_preventivo", "manoOperaImponibile"]).value) -
            Number(this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).value) -
            Number(this.frmOfferta.controls.lav_altre_opere.value)) /
            Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value);

        let calcoloIP22altro = (Number(this.frmOfferta.controls.lav_beni_significativi.value) -
            Number(this.frmOfferta.controls.lav_altri_beni.value) -
            Number(this.frmOfferta.get(["lav_preventivo", "manoOperaImponibile"]).value) -
            Number(this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).value) -
            Number(this.frmOfferta.controls.lav_altre_opere.value) -
            Number(this.frmOfferta.controls.lav_prestazioni_prof.value)) /
            Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value);

        if (this.frmOfferta.controls.lav_iva_prestazioni.value == "22" && calcoloIP22 <= 0) {
            this.IvaPonderata22 = Number(this.frmOfferta.controls.lav_prestazioni_prof.value) / Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value);
        }
        else if (this.frmOfferta.controls.lav_iva_prestazioni.value == "22") {
            this.IvaPonderata22 = (calcoloIP22) + (Number(this.frmOfferta.controls.lav_prestazioni_prof.value) / Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value));
        } else if (
            calcoloIP22altro <= 0
        ) {
            this.IvaPonderata22 = 0;
        } else {
            this.IvaPonderata22 = calcoloIP22altro;
        }

        this.ivaPonderata = 0;

        if (this.IvaPonderata22 <= 0) { this.ivaPonderata = 10; }
        else { this.ivaPonderata = (this.IvaPonderata10 * 10) / 100 + (this.IvaPonderata22 * 22) / 100; }

        /// modifiche per calcolo iva all 22% su prestazioni professionali - iva commessa a 0 calcolo tutto a 0

        if (this.frmOfferta.controls.lav_asseverazione.value == "Ex DEI" || this.frmOfferta.controls.lav_asseverazione.value == "Ex All.I"
            || this.frmOfferta.controls.lav_asseverazione.value === 'ALL. A' || this.frmOfferta.controls.lav_asseverazione.value === 'PREZZARI') {
            if (this.frmOfferta.controls.lav_iva_offerta.value === "10" && this.frmOfferta.controls.lav_iva_prestazioni.value === "22") {
                this.totaleFatturaIVA = Number((this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value) * 1.1) + (Number(this.frmOfferta.controls.lav_prestazioni_prof.value) * 0.12);
            } else if (this.frmOfferta.controls.lav_iva_offerta.value === "0") {
                this.totaleFatturaIVA = Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value);
            }
            else if (this.frmOfferta.controls.lav_iva_offerta.value === "10") {
                this.totaleFatturaIVA = Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value) * 1.1;
            } else if (this.frmOfferta.controls.lav_iva_offerta.value === "22") {
                this.totaleFatturaIVA = Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value) * 1.22;
            } else {
                if (this.ivaPonderata != 10) { this.ivaPonderata = this.ivaPonderata * 100; }
                this.ivaPonderata = 100 + this.ivaPonderata;
                this.totaleFatturaIVA = (Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value) * this.ivaPonderata) / 100;
            }
        } else {
            if (this.frmOfferta.controls.lav_iva_offerta.value === "0") {
                this.totaleFatturaIVA = Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value);
            } else if (this.frmOfferta.controls.lav_iva_offerta.value === "10" && this.frmOfferta.controls.lav_iva_prestazioni.value === "22" &&
                this.frmOfferta.controls.lav_asseverazione.value == "NO" && this.frmOfferta.controls.lav_visto.value == "NO") {
                this.totaleFatturaIVA = Number((this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value) * 1.1) + (Number(this.frmOfferta.controls.lav_prestazioni_prof.value) * 0.12);
            }
            else if (this.frmOfferta.controls.lav_iva_offerta.value === "10") {
                this.totaleFatturaIVA = Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value) * 1.1;
            } else if (this.frmOfferta.controls.lav_iva_offerta.value === "22") {
                this.totaleFatturaIVA = Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value) * 1.22;
            } else {
                if (this.ivaPonderata != 10) { this.ivaPonderata = this.ivaPonderata * 100; }
                this.ivaPonderata = 100 + this.ivaPonderata;
                this.totaleFatturaIVA = (Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value) * this.ivaPonderata) / 100;
            }
        }



        this.frmOfferta.controls.lav_importo.setValue(this.totaleFatturaIVA);
        this.frmOfferta.get(["lav_preventivo", "totaleFatturaIva"]).setValue(this.totaleFatturaIVA);

        this.CalcoloMassimali_SSI();

        // INIZIO CALCOLO EX DEI
        let varImporto_Non_Detraibile_Carico_Cliente: Number = 0;
        let IMPONIBILE_NON_DETRAIBILE: Number = 0;
        if (this.frmOfferta.controls.lav_asseverazione.value == "Ex DEI" ||
            this.frmOfferta.controls.lav_asseverazione.value === 'ALL. A' ||
            this.frmOfferta.controls.lav_asseverazione.value === 'PREZZARI') {
            IMPONIBILE_NON_DETRAIBILE = Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value) - Number(this.frmOfferta.controls.lav_imp_asseverazione.value);
            let varTotaleIvaFattura: Number = Number(this.totaleFatturaIVA) - Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value)

            this.frmOfferta.get(["lav_preventivo", "importoNonDetraibile"]).setValue(0);

            if (IMPONIBILE_NON_DETRAIBILE < 0) {
                IMPONIBILE_NON_DETRAIBILE = 0;
                //giuridica = NON Agevolabile
                if (this.ControlloFisicaGiuridica === "giuridica") {
                    varImporto_Non_Detraibile_Carico_Cliente = varTotaleIvaFattura + this.frmOfferta.get(["lav_preventivo", "importoNonDetraibile"]).value
                } else {
                    varImporto_Non_Detraibile_Carico_Cliente = 0;
                }
            } else {

                let Quota_parte_iva: Number = 0;
                //giuridica = NON Agevolabile
                if (this.ControlloFisicaGiuridica === "giuridica") {
                    Quota_parte_iva = varTotaleIvaFattura + this.frmOfferta.get(["lav_preventivo", "importoNonDetraibile"]).value;
                } else {
                    Quota_parte_iva = (Number(IMPONIBILE_NON_DETRAIBILE) / Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value)) * Number(varTotaleIvaFattura);
                }

                varImporto_Non_Detraibile_Carico_Cliente = Number(IMPONIBILE_NON_DETRAIBILE) + Number(Quota_parte_iva);
            }
        }
        // FINE CALCOLO EX DEI

        if (this.frmOfferta.controls.lav_asseverazione.value == "Ex DEI" ||
            this.frmOfferta.controls.lav_asseverazione.value === 'ALL. A' ||
            this.frmOfferta.controls.lav_asseverazione.value === 'PREZZARI') {
            this.frmOfferta.get(["lav_preventivo", "importoNONDetraibileACaricoCliente"]).setValue(Number(varImporto_Non_Detraibile_Carico_Cliente))
        }

        let varImportoNonDetraibile: Number = 0
        if (this.frmOfferta.controls.lav_asseverazione.value == "Ex DEI" ||
            this.frmOfferta.controls.lav_asseverazione.value === 'ALL. A' ||
            this.frmOfferta.controls.lav_asseverazione.value === 'PREZZARI') {
            varImportoNonDetraibile = varImporto_Non_Detraibile_Carico_Cliente
        }
        else {
            varImportoNonDetraibile = Number(this.frmOfferta.get(["lav_preventivo", "importoNonDetraibile"]).value);
        }

        if (this.ControlloFisicaGiuridica === "giuridica") {

            let varImporto: number = 0;

            if (this.frmOfferta.controls.lav_asseverazione.value == "Ex DEI" ||
                this.frmOfferta.controls.lav_asseverazione.value === 'ALL. A' ||
                this.frmOfferta.controls.lav_asseverazione.value === 'PREZZARI') {
                varImporto = Number(this.totaleFatturaIVA);
            } else {
                varImporto = Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value);
            }

            this.frmOfferta.get(["lav_preventivo", "ImportoLavoriCaricoCliente"]).setValue(
                ((varImporto - Number(varImportoNonDetraibile)) *
                    (100 - Number(this.frmOfferta.controls.lat_detrazione.value))) / 100);

            this.frmOfferta.get(["lav_preventivo", "contributoSpettante"]).setValue(
                ((varImporto - Number(varImportoNonDetraibile)) *
                    Number(this.frmOfferta.controls.lat_detrazione.value)) / 100);

        } else {
            this.frmOfferta.get(["lav_preventivo", "ImportoLavoriCaricoCliente"]).setValue(
                ((this.totaleFatturaIVA - Number(varImportoNonDetraibile)) *
                    (100 - Number(this.frmOfferta.controls.lat_detrazione.value))) / 100);

            this.frmOfferta.get(["lav_preventivo", "contributoSpettante"]).setValue(
                ((this.totaleFatturaIVA - Number(varImportoNonDetraibile)) *
                    Number(this.frmOfferta.controls.lat_detrazione.value)) / 100);
        }


        // controllo contributo spettante sotto forma di sconto non deve essere maggiorn di 60000
        if (this.frmOfferta.controls.lav_asseverazione.value == "Ex DEI" ||
            this.frmOfferta.controls.lav_asseverazione.value === 'ALL. A' ||
            this.frmOfferta.controls.lav_asseverazione.value === 'PREZZARI') {
            // this.frmOfferta.get(["lav_preventivo", "importoNONDetraibileACaricoCliente"]).setValue(Number(varImporto_Non_Detraibile_Carico_Cliente))
        }
        else {
            if (Number(this.frmOfferta.get(["lav_preventivo", "contributoSpettante"]).value) >= 60000) {
                this.frmOfferta.get(["lav_preventivo", "importoNONDetraibileACaricoCliente"]).setValue(
                    this.totaleFatturaIVA - 120000);
            }
            else {
                if (this.ControlloFisicaGiuridica === "giuridica") {
                    this.frmOfferta.get(["lav_preventivo", "importoNONDetraibileACaricoCliente"]).setValue(
                        this.totaleFatturaIVA - Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value) +
                        Number(this.frmOfferta.get(["lav_preventivo", "importoNonDetraibile"]).value)
                    );
                } else {
                    this.frmOfferta.get(["lav_preventivo", "importoNONDetraibileACaricoCliente"]).setValue(
                        Number(this.frmOfferta.get(["lav_preventivo", "importoNonDetraibile"]).value)
                    );
                }
            }

        }

        // controllo contributo spettante sotto forma di sconto non deve essere maggiorn di 60000
        if (Number(this.frmOfferta.get(["lav_preventivo", "contributoSpettante"]).value) > 60000) {
            this.frmOfferta.get(["lav_preventivo", "contributoSpettante"]).setValue(60000);
            this.eccedenteContributoSpettante = Number(this.frmOfferta.get(["lav_preventivo", "contributoSpettante"]).value) - 60000;
        } else {
            this.eccedenteContributoSpettante = 0
        }

        // controllo contributo spettante sotto forma di sconto non deve essere maggiorn di 60000
        if (Number(this.frmOfferta.get(["lav_preventivo", "ImportoLavoriCaricoCliente"]).value) > 60000) {
            this.frmOfferta.get(["lav_preventivo", "ImportoLavoriCaricoCliente"]).setValue(60000);
            this.eccedenteContributoSpettante = Number(this.frmOfferta.get(["lav_preventivo", "ImportoLavoriCaricoCliente"]).value) - 60000;
        } else {
            this.eccedenteContributoSpettante = 0
        }

        if (Number(this.frmOfferta.controls.lat_oneri.value == 2)) {
            this.frmOfferta.get(["lav_preventivo", "costiAttuazione"]).setValue(
                ((Number(this.frmOfferta.get(["lav_preventivo", "contributoSpettante"]).value)) * Number(this.percentualeOneri)) / 100);
            let ivaAtt: number = 0
            if (this.frmOfferta.controls.lav_iva_attualizzazione.value == undefined || this.frmOfferta.controls.lav_iva_attualizzazione.value == null || Number(this.frmOfferta.controls.lav_iva_attualizzazione.value) == 0) {
                ivaAtt = 0;
            } else if (Number(this.frmOfferta.controls.lav_iva_attualizzazione.value) == 15) {
                ivaAtt = this.ivaPonderata - 100;
            } else {
                ivaAtt = Number(this.frmOfferta.controls.lav_iva_attualizzazione.value)
            }

            this.frmOfferta.get(["lav_preventivo", "costiAttuazioneIVA"]).setValue(Number(this.frmOfferta.get(["lav_preventivo", "costiAttuazione"]).value) +
                ((Number(this.frmOfferta.get(["lav_preventivo", "costiAttuazione"]).value)) * Number(ivaAtt)) / 100);


            // precedentemete era 0 - valore
        } else {
            this.frmOfferta.get(["lav_preventivo", "costiAttuazione"]).setValue(0);
            this.frmOfferta.get(["lav_preventivo", "costiAttuazioneIVA"]).setValue(0)
        }

        // aggiungo a importoNONDetraibileACaricoCliente l parte eccedente dei 60000 di contributo spettante sotto forma di sconto
        this.frmOfferta.get(["lav_preventivo", "importoNONDetraibileACaricoCliente"]).setValue(
            Number(this.frmOfferta.get(["lav_preventivo", "importoNONDetraibileACaricoCliente"]).value) +
            Number(this.eccedenteContributoSpettante)
        )

        this.frmOfferta.get(["lav_preventivo", "totaleDovutoCliente"]).setValue(
            Number(this.frmOfferta.get(["lav_preventivo", "ImportoLavoriCaricoCliente"]).value) +
            Number(this.frmOfferta.get(["lav_preventivo", "costiAttuazione"]).value) +
            Number(this.frmOfferta.get(["lav_preventivo", "importoNONDetraibileACaricoCliente"]).value)
        );

        this.CalcoloFattura_SSI();
        this.calcoloResoconto_CostiAttuazione();
    }

    CalcoloMassimali_SSI(): void {

        this.frmOfferta.get(["lav_preventivo", "imponibileFornitura"]).setValue(
            Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value) -
            Number(this.frmOfferta.controls.lav_posa_opera.value) -
            Number(this.frmOfferta.controls.lav_prestazioni_prof.value) -
            Number(this.frmOfferta.controls.lav_altre_opere.value)
        );

        // CALCOLO MASSIMALE AL MQ 
        if (this.offerta.lav_data_aggiunta < new Date("2022-05-05")) {
            this.calcolo_Massimale_Serramentisti_PRIMA_del_CON_SENZA()
            this.frmOfferta.get(["lav_preventivo", "imponibileMassimoDetraibile_CON_CO"]).setValue(0)
            this.frmOfferta.get(["lav_preventivo", "massimaleMQdaApplicare_CON_CO"]).setValue(0)
        } else {
            this.calcolo_Massimale_Serramentisti_SENZA_CO()
            this.calcolo_Massimale_Serramentisti_CON_CO()
        }
        // ---------------------------------
        if (this.offerta.lav_data_aggiunta < new Date("2022-05-05")) {
            this.frmOfferta.get(["lav_preventivo", "costoMQImponibile"]).setValue(
                Number(this.frmOfferta.get(["lav_preventivo", "imponibileFornitura"]).value) / Number(this.frmOfferta.controls.lav_mq_installati.value)
            );
        } else {
            this.frmOfferta.get(["lav_preventivo", "costoMQImponibile"]).setValue(0);
        }

        this.frmOfferta.get(["lav_preventivo", "imponibileMassimoDetraibile"]).setValue(
            Number(this.frmOfferta.controls.lav_mq_installati.value) *
            Number(this.frmOfferta.get(["lav_preventivo", "massimaleMQdaApplicare"]).value)
        );

        this.frmOfferta.get(["lav_preventivo", "imponibileMassimoDetraibile_CON_CO"]).setValue(
            Number(this.frmOfferta.controls.lav_mq_installati_co.value) *
            Number(this.frmOfferta.get(["lav_preventivo", "massimaleMQdaApplicare_CON_CO"]).value)
        );

        // Imponibile Max detraibile =  impMaxDetr_Senza_co + impMaxDetr_Con_co
        let imponibileMassimaleDetraibileCalcolato: number =
            Number(this.frmOfferta.get(["lav_preventivo", "imponibileMassimoDetraibile"]).value) +
            Number(this.frmOfferta.get(["lav_preventivo", "imponibileMassimoDetraibile_CON_CO"]).value)
        // -----------------------------------------------------------


        if (this.offerta.lav_data_aggiunta < new Date("2022-05-05")) {
            if (-1 * (Number(this.frmOfferta.get(["lav_preventivo", "imponibileFornitura"]).value) -
                imponibileMassimaleDetraibileCalcolato) > 0
            ) {
                this.frmOfferta.get(["lav_preventivo", "importoNonDetraibile"]).setValue(0);
            } else if (
                this.ControlloFisicaGiuridica === "giuridica" || this.frmOfferta.controls.lav_iva_offerta.value == "0"
            ) {
                this.frmOfferta.get(["lav_preventivo", "importoNonDetraibile"]).setValue(
                    Number(this.frmOfferta.get(["lav_preventivo", "imponibileFornitura"]).value) -
                    imponibileMassimaleDetraibileCalcolato);
            } // ultima modifica del 10 /22 / NO /NO
            else if (this.frmOfferta.controls.lav_iva_offerta.value == "10" && this.frmOfferta.controls.lav_iva_prestazioni.value == "22" &&
                this.frmOfferta.controls.lav_asseverazione.value == 'NO' && this.frmOfferta.controls.lav_visto.value == 'NO') {
                this.frmOfferta.get(["lav_preventivo", "importoNonDetraibile"]).setValue(
                    ((Number(this.frmOfferta.get(["lav_preventivo", "imponibileFornitura"]).value) -
                        imponibileMassimaleDetraibileCalcolato) * (1 + Number(this.ivaPonderata)))
                );
            }
            else if (this.frmOfferta.controls.lav_iva_offerta.value == "10" && this.frmOfferta.controls.lav_asseverazione.value == "Ex All.I") {
                this.frmOfferta.get(["lav_preventivo", "importoNonDetraibile"]).setValue(
                    ((Number(this.frmOfferta.get(["lav_preventivo", "imponibileFornitura"]).value) -
                        imponibileMassimaleDetraibileCalcolato) * (1 + Number(this.ivaPonderata)))
                );
            }
            else if (this.frmOfferta.controls.lav_iva_offerta.value == "10") {
                this.frmOfferta.get(["lav_preventivo", "importoNonDetraibile"]).setValue(
                    (Number(this.frmOfferta.get(["lav_preventivo", "imponibileFornitura"]).value) -
                        imponibileMassimaleDetraibileCalcolato) * 1.1
                );
            } else if (this.frmOfferta.controls.lav_iva_offerta.value == "22") {
                this.frmOfferta.get(["lav_preventivo", "importoNonDetraibile"]).setValue(
                    (Number(this.frmOfferta.get(["lav_preventivo", "imponibileFornitura"]).value) -
                        imponibileMassimaleDetraibileCalcolato) * 1.22
                );
            }
            else {
                this.frmOfferta.get(["lav_preventivo", "importoNonDetraibile"]).setValue(
                    ((Number(this.frmOfferta.get(["lav_preventivo", "imponibileFornitura"]).value) -
                        imponibileMassimaleDetraibileCalcolato) * this.ivaPonderata) / 100
                );
            }
        } else {
            if (-1 * (Number(this.frmOfferta.get(["lav_preventivo", "imponibileFornitura"]).value - imponibileMassimaleDetraibileCalcolato)) > 0) {
                this.frmOfferta.get(["lav_preventivo", "importoNonDetraibile"]).setValue(0);
            } else if (this.ControlloFisicaGiuridica === "giuridica") {
                this.frmOfferta.get(["lav_preventivo", "importoNonDetraibile"]).setValue(
                    Number(this.frmOfferta.get(["lav_preventivo", "imponibileFornitura"]).value) - imponibileMassimaleDetraibileCalcolato);
            } else if (imponibileMassimaleDetraibileCalcolato < Number(this.frmOfferta.get(["lav_preventivo", "imponibileFornitura"]).value)) {
                this.frmOfferta.get(["lav_preventivo", "importoNonDetraibile"]).setValue(
                    (Number(this.frmOfferta.get(["lav_preventivo", "imponibileFornitura"]).value) - imponibileMassimaleDetraibileCalcolato) +
                    (((Number(this.frmOfferta.get(["lav_preventivo", "imponibileFornitura"]).value) - imponibileMassimaleDetraibileCalcolato) / Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value))
                        * (this.totaleFatturaIVA - Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value)))
                );
            } else {
                this.frmOfferta.get(["lav_preventivo", "importoNonDetraibile"]).setValue(0);
            }
        }
    }

    calcolo_Massimale_Serramentisti_PRIMA_del_CON_SENZA(): void {
        if (this.frmOfferta.controls.lav_chiusura.value === 0 &&
            (this.frmOfferta.controls.lav_zona_climatica.value === "A" ||
                this.frmOfferta.controls.lav_zona_climatica.value === "B" ||
                this.frmOfferta.controls.lav_zona_climatica.value === "C")
        ) { this.frmOfferta.get(["lav_preventivo", "massimaleMQdaApplicare"]).setValue(550.0); }
        else if (
            this.frmOfferta.controls.lav_chiusura.value === 1 &&
            (this.frmOfferta.controls.lav_zona_climatica.value === "D" ||
                this.frmOfferta.controls.lav_zona_climatica.value === "E" ||
                this.frmOfferta.controls.lav_zona_climatica.value === "F")
        ) { this.frmOfferta.get(["lav_preventivo", "massimaleMQdaApplicare"]).setValue(750.0); }
        else { this.frmOfferta.get(["lav_preventivo", "massimaleMQdaApplicare"]).setValue(650.0); }
    }

    calcolo_Massimale_Serramentisti_SENZA_CO(): void {
        // SENZA CHIUSURA OSCURANTE ---- INIZIO ----- MQ DA APPLICARE
        if (this.frmOfferta.controls.lav_mq_installati.value > 0 &&
            (this.frmOfferta.controls.lav_zona_climatica.value === "A" ||
                this.frmOfferta.controls.lav_zona_climatica.value === "B" ||
                this.frmOfferta.controls.lav_zona_climatica.value === "C")
        ) { this.frmOfferta.get(["lav_preventivo", "massimaleMQdaApplicare"]).setValue(660.0); }
        else if (
            this.frmOfferta.controls.lav_mq_installati.value > 0 &&
            (this.frmOfferta.controls.lav_zona_climatica.value === "D" ||
                this.frmOfferta.controls.lav_zona_climatica.value === "E" ||
                this.frmOfferta.controls.lav_zona_climatica.value === "F")
        ) { this.frmOfferta.get(["lav_preventivo", "massimaleMQdaApplicare"]).setValue(780.0); }
        // SENZA CHIUSURA OSCURANTE ---- FINE ----- MQ DA APPLICARE
    }

    calcolo_Massimale_Serramentisti_CON_CO(): void {
        // CON CHIUSURA OSCURANTE ---- INIZIO ----- MQ DA APPLICARE
        if (this.frmOfferta.controls.lav_mq_installati_co.value > 0 &&
            (this.frmOfferta.controls.lav_zona_climatica.value === "A" ||
                this.frmOfferta.controls.lav_zona_climatica.value === "B" ||
                this.frmOfferta.controls.lav_zona_climatica.value === "C")
        ) { this.frmOfferta.get(["lav_preventivo", "massimaleMQdaApplicare_CON_CO"]).setValue(780.0); }
        else if (
            this.frmOfferta.controls.lav_mq_installati_co.value > 0 &&
            (this.frmOfferta.controls.lav_zona_climatica.value === "D" ||
                this.frmOfferta.controls.lav_zona_climatica.value === "E" ||
                this.frmOfferta.controls.lav_zona_climatica.value === "F")
        ) { this.frmOfferta.get(["lav_preventivo", "massimaleMQdaApplicare_CON_CO"]).setValue(900.0); }
        // CON CHIUSURA OSCURANTE ---- FINE ----- MQ DA APPLICARE
    }

    calcoloResoconto_CostiAttuazione(): void {
        this.pulisciResocontoCostiAttuazione();
        if (Number(this.frmOfferta.controls.lav_iva_attualizzazione.value == 0)) {
            this.frmOfferta.get(["lav_preventivo", "CA_EsenteImponibile"]).setValue(Number(this.frmOfferta.get(["lav_preventivo", "costiAttuazione"]).value));
            this.frmOfferta.get(["lav_preventivo", "CA_EsenteIva10"]).setValue(0);
            this.frmOfferta.get(["lav_preventivo", "CA_EsenteIva22"]).setValue(0);
            this.frmOfferta.get(["lav_preventivo", "CA_EsenteImporto"]).setValue(Number(this.frmOfferta.get(["lav_preventivo", "costiAttuazione"]).value));

            this.frmOfferta.get(["lav_preventivo", "CAImponibile"]).setValue(Number(this.frmOfferta.get(["lav_preventivo", "costiAttuazione"]).value));
            this.frmOfferta.get(["lav_preventivo", "CA10"]).setValue(0);
            this.frmOfferta.get(["lav_preventivo", "CA22"]).setValue(0);
            this.frmOfferta.get(["lav_preventivo", "CATot"]).setValue(Number(this.frmOfferta.get(["lav_preventivo", "costiAttuazione"]).value));
        }
        if (Number(this.frmOfferta.controls.lav_iva_attualizzazione.value == 10)) {
            this.frmOfferta.get(["lav_preventivo", "CA_10Imponibile"]).setValue(Number(this.frmOfferta.get(["lav_preventivo", "costiAttuazione"]).value));
            this.frmOfferta.get(["lav_preventivo", "CA_10Iva10"]).setValue((Number(this.frmOfferta.get(["lav_preventivo", "costiAttuazione"]).value) * 10) / 100
            );
            this.frmOfferta.get(["lav_preventivo", "CA_10Iva22"]).setValue(0);
            this.frmOfferta.get(["lav_preventivo", "CA_10Totale"]).setValue(Number(this.frmOfferta.get(["lav_preventivo", "costiAttuazioneIVA"]).value));

            this.frmOfferta.get(["lav_preventivo", "CAImponibile"]).setValue(Number(this.frmOfferta.get(["lav_preventivo", "costiAttuazione"]).value));
            this.frmOfferta.get(["lav_preventivo", "CA10"]).setValue(0);
            this.frmOfferta.get(["lav_preventivo", "CA22"]).setValue((Number(this.frmOfferta.get(["lav_preventivo", "costiAttuazione"]).value) * 10) / 100);
            this.frmOfferta.get(["lav_preventivo", "CATot"]).setValue(Number(this.frmOfferta.get(["lav_preventivo", "costiAttuazioneIVA"]).value));
        }
        if (Number(this.frmOfferta.controls.lav_iva_attualizzazione.value == 22)) {
            this.frmOfferta.get(["lav_preventivo", "CA_22Imponibile"]).setValue(Number(this.frmOfferta.get(["lav_preventivo", "costiAttuazione"]).value));
            this.frmOfferta.get(["lav_preventivo", "CA_22Iva10"]).setValue((Number(this.frmOfferta.get(["lav_preventivo", "costiAttuazione"]).value) * 22) / 100
            );
            this.frmOfferta.get(["lav_preventivo", "CA_22Iva22"]).setValue(0);
            this.frmOfferta.get(["lav_preventivo", "CA_22Totale"]).setValue(Number(this.frmOfferta.get(["lav_preventivo", "costiAttuazioneIVA"]).value));

            this.frmOfferta.get(["lav_preventivo", "CAImponibile"]).setValue(Number(this.frmOfferta.get(["lav_preventivo", "costiAttuazione"]).value));
            this.frmOfferta.get(["lav_preventivo", "CA10"]).setValue(0);
            this.frmOfferta.get(["lav_preventivo", "CA22"]).setValue((Number(this.frmOfferta.get(["lav_preventivo", "costiAttuazione"]).value) * 22) / 100);
            this.frmOfferta.get(["lav_preventivo", "CATot"]).setValue(Number(this.frmOfferta.get(["lav_preventivo", "costiAttuazioneIVA"]).value));
        }

        if (Number(this.frmOfferta.controls.lav_iva_attualizzazione.value == 15)) {
            const imp10: Number =
                (Number(this.frmOfferta.get(["lav_preventivo", "contributoSpettante"]).value) * Number(this.percentualeOneri) / 100)
                * (this.IvaPonderata10 * 100) / 100;
            const imp10Iva: Number = (Number(imp10) * 10) / 100

            const imp22: Number =
                (Number(this.frmOfferta.get(["lav_preventivo", "contributoSpettante"]).value) * Number(this.percentualeOneri) / 100)
                * (this.IvaPonderata22 * 100) / 100;
            const imp22Iva: Number = (Number(imp22) * 22) / 100

            this.frmOfferta.get(["lav_preventivo", "CA_10Imponibile"]).setValue(imp10);
            this.frmOfferta.get(["lav_preventivo", "CA_10Iva10"]).setValue(imp10Iva);
            this.frmOfferta.get(["lav_preventivo", "CA_10Iva22"]).setValue(0);
            this.frmOfferta.get(["lav_preventivo", "CA_10Totale"]).setValue(Number(imp10) + Number(imp10Iva));

            this.frmOfferta.get(["lav_preventivo", "CA_22Imponibile"]).setValue(imp22);
            this.frmOfferta.get(["lav_preventivo", "CA_22Iva10"]).setValue(0);
            this.frmOfferta.get(["lav_preventivo", "CA_22Iva22"]).setValue(imp22Iva);
            this.frmOfferta.get(["lav_preventivo", "CA_22Totale"]).setValue(Number(imp22) + Number(imp22Iva));

            this.frmOfferta.get(["lav_preventivo", "CAImponibile"]).setValue(Number(this.frmOfferta.get(["lav_preventivo", "costiAttuazione"]).value));
            this.frmOfferta.get(["lav_preventivo", "CA10"]).setValue(imp10Iva);
            this.frmOfferta.get(["lav_preventivo", "CA22"]).setValue(imp22Iva);
            this.frmOfferta.get(["lav_preventivo", "CATot"]).setValue(Number(this.frmOfferta.get(["lav_preventivo", "costiAttuazioneIVA"]).value));
        }
    }

    pulisciResocontoCostiAttuazione(): void {
        this.frmOfferta.get(["lav_preventivo", "CA_EsenteImponibile"]).setValue(0);
        this.frmOfferta.get(["lav_preventivo", "CA_EsenteIva10"]).setValue(0);
        this.frmOfferta.get(["lav_preventivo", "CA_EsenteIva22"]).setValue(0);
        this.frmOfferta.get(["lav_preventivo", "CA_EsenteImporto"]).setValue(0);
        this.frmOfferta.get(["lav_preventivo", "CA_10Imponibile"]).setValue(0);
        this.frmOfferta.get(["lav_preventivo", "CA_10Iva10"]).setValue(0);
        this.frmOfferta.get(["lav_preventivo", "CA_10Iva22"]).setValue(0);
        this.frmOfferta.get(["lav_preventivo", "CA_10Totale"]).setValue(0);
        this.frmOfferta.get(["lav_preventivo", "CA_22Imponibile"]).setValue(0);
        this.frmOfferta.get(["lav_preventivo", "CA_22Iva10"]).setValue(0);
        this.frmOfferta.get(["lav_preventivo", "CA_22Iva22"]).setValue(0);
        this.frmOfferta.get(["lav_preventivo", "CA_22Totale"]).setValue(0);
        this.frmOfferta.get(["lav_preventivo", "CAImponibile"]).setValue(0);
        this.frmOfferta.get(["lav_preventivo", "CA10"]).setValue(0);
        this.frmOfferta.get(["lav_preventivo", "CA22"]).setValue(0);
        this.frmOfferta.get(["lav_preventivo", "CATot"]).setValue(0);
    }

    CalcoloFattura_SSI(): void {
        // POSA IN OPERA  -------------------- INIZIO -------------------------
        this.frmOfferta.get(["lav_preventivo", "posaOperaImponibile"]).setValue(Number(this.frmOfferta.controls.lav_posa_opera.value));
        this.frmOfferta.get(["lav_preventivo", "posaOpera10"]).setValue(this.FatturaIva10_SSI(Number(this.frmOfferta.get(["lav_preventivo", "posaOperaImponibile"]).value)));
        this.frmOfferta.get(["lav_preventivo", "posaOpera22"]).setValue(this.FatturaIva22_SSI(Number(this.frmOfferta.get(["lav_preventivo", "posaOperaImponibile"]).value)));
        this.frmOfferta.get(["lav_preventivo", "posaOperaTot"]).setValue(
            Number(this.frmOfferta.get(["lav_preventivo", "posaOperaImponibile"]).value) +
            Number(this.frmOfferta.get(["lav_preventivo", "posaOpera10"]).value) +
            Number(this.frmOfferta.get(["lav_preventivo", "posaOpera22"]).value)
        );
        // POSA IN OPERA  -------------------- FINE -------------------------

        // ALTRI BENI ACCESSORI  -------------------- INIZIO -------------------------
        this.frmOfferta.get(["lav_preventivo", "altibeniaccImponibile"]).setValue(
            Number(this.frmOfferta.controls.lav_altri_beni.value) == 0 ? 0 : Number(this.frmOfferta.controls.lav_altri_beni.value) + Number(this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).value) / 2
        );
        this.frmOfferta.get(["lav_preventivo", "altibeniacc10"]).setValue(Number(this.FatturaIva10_SSI(this.frmOfferta.get(["lav_preventivo", "altibeniaccImponibile"]).value)));
        this.frmOfferta.get(["lav_preventivo", "altibeniacc22"]).setValue(Number(this.FatturaIva22_SSI(this.frmOfferta.get(["lav_preventivo", "altibeniaccImponibile"]).value)));
        this.frmOfferta.get(["lav_preventivo", "altibeniaccTot"]).setValue(
            Number(this.frmOfferta.get(["lav_preventivo", "altibeniaccImponibile"]).value) + Number(this.frmOfferta.get(["lav_preventivo", "altibeniacc10"]).value) +
            Number(this.frmOfferta.get(["lav_preventivo", "altibeniacc22"]).value));
        // ALTRI BENI ACCESSORI  -------------------- FINE -------------------------

        // VALORE DI VENDITA 22 %-------------------- INIZIO -------------------------
        // SE(O(F8=0%;F8=22%)*E(D8=0);
        if ((this.frmOfferta.controls.lav_iva_offerta.value == "0" || this.frmOfferta.controls.lav_iva_offerta.value == "22") && Number(this.frmOfferta.controls.lav_altri_beni.value) == 0
        ) {
            //D5+(D13);
            this.frmOfferta.get(["lav_preventivo", "valore22Imponibile"]).setValue(
                Number(this.frmOfferta.controls.lav_vendita_infissi.value) + Number(this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).value));
        }//SE(O(F8=0%;F8=22%);
        else if (this.frmOfferta.controls.lav_iva_offerta.value == "0" || this.frmOfferta.controls.lav_iva_offerta.value == "22") {
            //D5+(D13)/2;
            this.frmOfferta.get(["lav_preventivo", "valore22Imponibile"]).setValue(
                Number(this.frmOfferta.controls.lav_vendita_infissi.value) + Number(this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).value) / 2);
        }
        // else if (
        //     (Number(this.frmOfferta.controls.lav_iva_prestazioni.value) == 22) &&
        //     ((Number(this.frmOfferta.controls.lav_beni_significativi.value) -
        //         Number(this.frmOfferta.controls.lav_altri_beni.value) -
        //         Number(this.frmOfferta.get(["lav_preventivo", "manoOperaImponibile"]).value) -
        //         Number(this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).value) -
        //         Number(this.frmOfferta.controls.lav_altre_opere.value)) > 0)
        // ) {
        //     this.frmOfferta.get(["lav_preventivo", "valore22Imponibile"]).setValue(
        //         Number(this.frmOfferta.controls.lav_beni_significativi.value) -
        //         Number(this.frmOfferta.controls.lav_altri_beni.value) -
        //         Number(this.frmOfferta.get(["lav_preventivo", "manoOperaImponibile"]).value) -
        //         Number(this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).value) -
        //         Number(this.frmOfferta.controls.lav_altre_opere.value)
        //     );
        // }
        // SE(O(D7-D8-D11-D13-D9<=0;F8=10%);
        else if ((Number(this.frmOfferta.controls.lav_beni_significativi.value) -
            Number(this.frmOfferta.controls.lav_altri_beni.value) -
            Number(this.frmOfferta.get(["lav_preventivo", "manoOperaImponibile"]).value) -
            Number(this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).value) -
            Number(this.frmOfferta.controls.lav_altre_opere.value) <= 0) ||
            this.frmOfferta.controls.lav_iva_offerta.value == "10") {
            //
            this.frmOfferta.get(["lav_preventivo", "valore22Imponibile"]).setValue(0)
        }
        else {
            //D7-D8-D11-D13-D9
            this.frmOfferta.get(["lav_preventivo", "valore22Imponibile"]).setValue(
                (Number(this.frmOfferta.controls.lav_beni_significativi.value) -
                    Number(this.frmOfferta.controls.lav_altri_beni.value) -
                    Number(this.frmOfferta.get(["lav_preventivo", "manoOperaImponibile"]).value) -
                    Number(this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).value) -
                    Number(this.frmOfferta.controls.lav_altre_opere.value)));
        }


        this.frmOfferta.get(["lav_preventivo", "valore2210"]).setValue(0);
        this.frmOfferta.get(["lav_preventivo", "valore2222"]).setValue(
            //SE(F8=0%;0;G18*22%)
            this.frmOfferta.controls.lav_iva_offerta.value == "0" ? 0 : (Number(this.frmOfferta.get(["lav_preventivo", "valore22Imponibile"]).value) * 22) / 100
        );
        this.frmOfferta.get(["lav_preventivo", "valore22Tot"]).setValue(
            Number(this.frmOfferta.get(["lav_preventivo", "valore22Imponibile"]).value) +
            Number(this.frmOfferta.get(["lav_preventivo", "valore2210"]).value) +
            Number(this.frmOfferta.get(["lav_preventivo", "valore2222"]).value)
        );

        // VALORE DI VENDITA 22 % -------------------- FINE -------------------------

        // VALORE DI VENDITA 10 % -------------------- FINE -------------------------
        // =SE(O(F8=0%;F8=22%);
        if (this.frmOfferta.controls.lav_iva_offerta.value == "0" || this.frmOfferta.controls.lav_iva_offerta.value == "22") {
            // 0
            this.frmOfferta.get(["lav_preventivo", "valore10Imponibile"]).setValue(0);
            // SE(D8=0;
        } else if (Number(this.frmOfferta.controls.lav_altri_beni.value) == 0) {
            // D5+D13-G18;
            this.frmOfferta.get(["lav_preventivo", "valore10Imponibile"]).setValue(
                Number(this.frmOfferta.controls.lav_vendita_infissi.value) +
                Number(this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).value) -
                Number(this.frmOfferta.get(["lav_preventivo", "valore22Imponibile"]).value));
        } else {
            // -G18+D5+(D13)/2))
            this.frmOfferta.get(["lav_preventivo", "valore10Imponibile"]).setValue(
                0 - Number(this.frmOfferta.get(["lav_preventivo", "valore22Imponibile"]).value) +
                Number(this.frmOfferta.controls.lav_vendita_infissi.value) + Number(this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).value) / 2
            );
        }
        // =G17*10%
        this.frmOfferta.get(["lav_preventivo", "valore1010"]).setValue((Number(this.frmOfferta.get(["lav_preventivo", "valore10Imponibile"]).value) * 10) / 100);
        this.frmOfferta.get(["lav_preventivo", "valore1022"]).setValue(0);
        this.frmOfferta.get(["lav_preventivo", "valore10Tot"]).setValue(
            Number(this.frmOfferta.get(["lav_preventivo", "valore10Imponibile"]).value) +
            Number(this.frmOfferta.get(["lav_preventivo", "valore1010"]).value) +
            Number(this.frmOfferta.get(["lav_preventivo", "valore1022"]).value)
        );
        // VALORE DI VENDITA 10 % -------------------- FINE -------------------------

        this.frmOfferta.get(["lav_preventivo", "altreOpereImponibile"]).setValue(Number(this.frmOfferta.controls.lav_altre_opere.value));
        this.frmOfferta.get(["lav_preventivo", "altreOpere10"]).setValue(this.FatturaIva10_SSI(Number(this.frmOfferta.get(["lav_preventivo", "altreOpereImponibile"]).value)));
        this.frmOfferta.get(["lav_preventivo", "altreOpere22"]).setValue(this.FatturaIva22_SSI(Number(this.frmOfferta.get(["lav_preventivo", "altreOpereImponibile"]).value)));
        this.frmOfferta.get(["lav_preventivo", "altreOpereTot"]).setValue(
            Number(this.frmOfferta.get(["lav_preventivo", "altreOpereImponibile"]).value) +
            Number(this.frmOfferta.get(["lav_preventivo", "altreOpere10"]).value) +
            Number(this.frmOfferta.get(["lav_preventivo", "altreOpere22"]).value)
        );



        this.frmOfferta.get(["lav_preventivo", "prestProfImponibile"]).setValue(Number(this.frmOfferta.controls.lav_prestazioni_prof.value));

        if (this.frmOfferta.controls.lav_iva_offerta.value === "10") {
            this.frmOfferta.get(["lav_preventivo", "prestProf10"]).setValue((Number(this.frmOfferta.get(["lav_preventivo", "prestProfImponibile"]).value) * 10) / 100);
        } else if (
            this.frmOfferta.controls.lav_iva_offerta.value === "0" ||
            this.frmOfferta.controls.lav_iva_offerta.value === "22" ||
            this.frmOfferta.controls.lav_iva_prestazioni.value === "22"
        ) {
            this.frmOfferta.get(["lav_preventivo", "prestProf10"]).setValue(0);
        } else {
            this.frmOfferta.get(["lav_preventivo", "prestProf10"]).setValue((Number(this.frmOfferta.get(["lav_preventivo", "prestProfImponibile"]).value) * 10) / 100);
        }


        ///////   modifiche per calcolo EX dei e All.I con iva 10% o 0% e prestazione a 22%

        if (this.frmOfferta.controls.lav_asseverazione.value == "Ex DEI" || this.frmOfferta.controls.lav_asseverazione.value == "Ex All.I" ||
            this.frmOfferta.controls.lav_asseverazione.value === 'ALL. A' || this.frmOfferta.controls.lav_asseverazione.value === 'PREZZARI') {
            if (this.frmOfferta.controls.lav_iva_offerta.value === "0") {
                this.frmOfferta.get(["lav_preventivo", "prestProf10"]).setValue(0);
                this.frmOfferta.get(["lav_preventivo", "prestProf22"]).setValue(0);
            }
            else if (this.frmOfferta.controls.lav_iva_prestazioni.value === "22") {
                this.frmOfferta.get(["lav_preventivo", "prestProf22"]).setValue((Number(this.frmOfferta.get(["lav_preventivo", "prestProfImponibile"]).value) * 22) / 100);
                this.frmOfferta.get(["lav_preventivo", "prestProf10"]).setValue(0);
            }
            else {
                this.frmOfferta.get(["lav_preventivo", "prestProf10"]).setValue((Number(this.frmOfferta.get(["lav_preventivo", "prestProfImponibile"]).value) * 10) / 100);
                this.frmOfferta.get(["lav_preventivo", "prestProf22"]).setValue(0);
            }
        } else {
            if (this.frmOfferta.controls.lav_iva_offerta.value === "10" || this.frmOfferta.controls.lav_iva_prestazioni.value === "22") {
                this.frmOfferta.get(["lav_preventivo", "prestProf22"]).setValue((Number(this.frmOfferta.get(["lav_preventivo", "prestProfImponibile"]).value) * 22) / 100);
                this.frmOfferta.get(["lav_preventivo", "prestProf10"]).setValue(0);
            } else if (this.frmOfferta.controls.lav_iva_offerta.value === "0" || this.frmOfferta.controls.lav_iva_offerta.value === "10") {
                this.frmOfferta.get(["lav_preventivo", "prestProf22"]).setValue(0);
            } else if (this.frmOfferta.controls.lav_iva_offerta.value === "22" || this.frmOfferta.controls.lav_iva_prestazioni.value === "22") {
                this.frmOfferta.get(["lav_preventivo", "prestProf22"]).setValue((Number(this.frmOfferta.get(["lav_preventivo", "prestProfImponibile"]).value) * 22) / 100);
            } else { this.frmOfferta.get(["lav_preventivo", "prestProf22"]).setValue(0); }
        }
        ///////// ---------------------------------------------------

        this.frmOfferta.get(["lav_preventivo", "prestProfTot"]).setValue(
            this.frmOfferta.get(["lav_preventivo", "prestProfImponibile"]).value +
            this.frmOfferta.get(["lav_preventivo", "prestProf10"]).value +
            this.frmOfferta.get(["lav_preventivo", "prestProf22"]).value
        );
        this.frmOfferta.get(["lav_preventivo", "ftImponibile"]).setValue(
            this.frmOfferta.get(["lav_preventivo", "posaOperaImponibile"]).value +
            this.frmOfferta.get(["lav_preventivo", "altibeniaccImponibile"]).value +
            this.frmOfferta.get(["lav_preventivo", "valore10Imponibile"]).value +
            this.frmOfferta.get(["lav_preventivo", "valore22Imponibile"]).value +
            this.frmOfferta.get(["lav_preventivo", "altreOpereImponibile"]).value +
            this.frmOfferta.get(["lav_preventivo", "prestProfImponibile"]).value
        );

        this.frmOfferta.get(["lav_preventivo", "ft10"]).setValue(
            this.frmOfferta.get(["lav_preventivo", "posaOpera10"]).value +
            this.frmOfferta.get(["lav_preventivo", "altibeniacc10"]).value +
            this.frmOfferta.get(["lav_preventivo", "valore1010"]).value +
            this.frmOfferta.get(["lav_preventivo", "valore2210"]).value +
            this.frmOfferta.get(["lav_preventivo", "altreOpere10"]).value +
            this.frmOfferta.get(["lav_preventivo", "prestProf10"]).value
        );

        this.frmOfferta.get(["lav_preventivo", "ft22"]).setValue(
            this.frmOfferta.get(["lav_preventivo", "posaOpera22"]).value +
            this.frmOfferta.get(["lav_preventivo", "altibeniacc22"]).value +
            this.frmOfferta.get(["lav_preventivo", "valore1022"]).value +
            this.frmOfferta.get(["lav_preventivo", "valore2222"]).value +
            this.frmOfferta.get(["lav_preventivo", "altreOpere22"]).value +
            this.frmOfferta.get(["lav_preventivo", "prestProf22"]).value
        );

        this.frmOfferta.get(["lav_preventivo", "ftTot"]).setValue(
            this.frmOfferta.get(["lav_preventivo", "posaOperaTot"]).value +
            this.frmOfferta.get(["lav_preventivo", "altibeniaccTot"]).value +
            this.frmOfferta.get(["lav_preventivo", "valore10Tot"]).value +
            this.frmOfferta.get(["lav_preventivo", "valore22Tot"]).value +
            this.frmOfferta.get(["lav_preventivo", "altreOpereTot"]).value +
            this.frmOfferta.get(["lav_preventivo", "prestProfTot"]).value
        );
    }



    FatturaIva10_SSI(importo: number) {
        if (this.frmOfferta.controls.lav_iva_offerta.value == "0" || this.frmOfferta.controls.lav_iva_offerta.value == "22") {
            return 0;
        } else { return (importo * 10) / 100; }
    }

    FatturaIva22_SSI(importo: number) {
        if (this.frmOfferta.controls.lav_iva_offerta.value == "22") {
            return (importo * 22) / 100;
        } else { return 0; }
    }
    //FINE CALCOLO SSI

    //------------------------------------------------------------------------------------------------------//

    /////////////////////////////////////////////////////INIZIO CALCOLO SSCO


    CalcolaPreventivo_SSCO(): void {
        this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).setValue(
            ((this.frmOfferta.get(["lav_preventivo", "imponibileSerramentista"]).value * Number(this.percentualeImponibile)) /
                100) + Number(this.frmOfferta.controls.lat_costo_fisso.value) //percentuale prec 7.5
        );

        // inizio Calcolo scelta per Asseverazione e Visto NO EX DEI
        let impFornitura: number = Number(this.frmOfferta.get(["lav_preventivo", "imponibileSerramentista"]).value);
        let SpeseTecniche: number = Number(this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).value);
        let CalcoloAss: number = 0;
        let CalcoloVisto: number = 0;

        if (this.frmOfferta.controls.lav_visto.value == "SI") {
            CalcoloVisto = ((impFornitura * Number(this.frmOfferta.controls.lat_perc_visto.value)) / 100) + Number(this.frmOfferta.controls.lat_costo_visto.value);
        }
        if (this.frmOfferta.controls.lav_asseverazione.value != "NO") {
            CalcoloAss = ((impFornitura * Number(this.frmOfferta.controls.lat_perc_asseverazione.value)) / 100) + Number(this.frmOfferta.controls.lat_costo_asseverazione.value);
        }

        SpeseTecniche = SpeseTecniche + CalcoloAss + CalcoloVisto;
        this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).setValue(SpeseTecniche);


        if (Number(this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).value) < Number(this.frmOfferta.controls.lat_costo_min.value)) {
            this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).setValue(this.frmOfferta.controls.lat_costo_min.value)
        }
        if (Number(this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).value) > Number(this.frmOfferta.controls.lat_costo_max.value)) {
            this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).setValue(this.frmOfferta.controls.lat_costo_max.value)
        }

        this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).setValue(
            Number(this.frmOfferta.get(["lav_preventivo", "imponibileSerramentista"]).value) +
            Number(this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).value)
        );
        // fine Calcolo scelta per Asseverazione e Visto NO EX DEI

        /// modifiche per calcolo iva all 22% su prestazioni professionali - iva commessa a 0 calcolo tutto a 0
        if (this.frmOfferta.controls.lav_asseverazione.value == "Ex DEI" || this.frmOfferta.controls.lav_asseverazione.value == "Ex All.I" ||
            this.frmOfferta.controls.lav_asseverazione.value === 'ALL. A' || this.frmOfferta.controls.lav_asseverazione.value === 'PREZZARI') {
            if (this.frmOfferta.controls.lav_iva_offerta.value === "10" && this.frmOfferta.controls.lav_iva_prestazioni.value === "22") {
                this.totaleFatturaIVA = Number((this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value) * 1.1) + (Number(this.frmOfferta.controls.lav_prestazioni_prof.value) * 0.12);
            }
            else if (this.frmOfferta.controls.lav_iva_offerta.value === "0") {
                this.totaleFatturaIVA = Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value);
            }
            else if (this.frmOfferta.controls.lav_iva_offerta.value === "10") {
                this.totaleFatturaIVA = Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value) * 1.1;
            } else if (this.frmOfferta.controls.lav_iva_offerta.value === "22") {
                this.totaleFatturaIVA = Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value) * 1.22;
            } else {
                if (this.ivaPonderata != 10) { this.ivaPonderata = this.ivaPonderata * 100; }
                this.ivaPonderata = 100 + this.ivaPonderata;
                this.totaleFatturaIVA = (Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value) * this.ivaPonderata) / 100;
            }
        } else {
            if (this.frmOfferta.controls.lav_iva_offerta.value === "10" && this.frmOfferta.controls.lav_iva_prestazioni.value === "22") {
                this.totaleFatturaIVA = Number((this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value) * 1.1) + (Number(this.frmOfferta.controls.lav_prestazioni_prof.value) * 0.12);
            }
            else if (this.frmOfferta.controls.lav_iva_offerta.value === "0") {
                this.totaleFatturaIVA = Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value);
            } else if (this.frmOfferta.controls.lav_iva_offerta.value === "10" && this.frmOfferta.controls.lav_iva_prestazioni.value === "22" &&
                this.frmOfferta.controls.lav_asseverazione.value == "NO" && this.frmOfferta.controls.lav_visto.value == "NO") {
                this.totaleFatturaIVA = Number((this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value) * 1.1) + (Number(this.frmOfferta.controls.lav_prestazioni_prof.value) * 0.12);
            }
            else if (this.frmOfferta.controls.lav_iva_offerta.value === "10") {
                this.totaleFatturaIVA = Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value) * 1.1;
            } else if (this.frmOfferta.controls.lav_iva_offerta.value === "22") {
                this.totaleFatturaIVA = Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value) * 1.22;
            } else {
                if (this.ivaPonderata != 10) { this.ivaPonderata = this.ivaPonderata * 100; }
                this.ivaPonderata = 100 + this.ivaPonderata;
                this.totaleFatturaIVA = (Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value) * this.ivaPonderata) / 100;
            }
        }

        this.CalcoloMassimali_SSCO();

        //  this.frmOfferta.get(["lav_preventivo", "contributoSpettante"]).setValue(-1 * this.frmOfferta.get(["lav_preventivo", "ImportoLavoriCaricoCliente"]).value);


        // INIZIO CALCOLO EX DEI
        let varImporto_Non_Detraibile_Carico_Cliente: Number = 0;
        let IMPONIBILE_NON_DETRAIBILE: Number = 0;
        if (this.frmOfferta.controls.lav_asseverazione.value == "Ex DEI" ||
            this.frmOfferta.controls.lav_asseverazione.value === 'ALL. A' ||
            this.frmOfferta.controls.lav_asseverazione.value === 'PREZZARI') {
            IMPONIBILE_NON_DETRAIBILE = Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value) - Number(this.frmOfferta.controls.lav_imp_asseverazione.value);
            let varTotaleIvaFattura: Number = Number(this.totaleFatturaIVA) - Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value)

            if (IMPONIBILE_NON_DETRAIBILE < 0) {
                IMPONIBILE_NON_DETRAIBILE = 0;
                if (this.ControlloFisicaGiuridica === "giuridica") {
                    varImporto_Non_Detraibile_Carico_Cliente = varTotaleIvaFattura + this.frmOfferta.get(["lav_preventivo", "importoNonDetraibile"]).value
                } else {
                    varImporto_Non_Detraibile_Carico_Cliente = 0;
                }
            } else {

                let Quota_parte_iva: Number = 0;

                if (this.ControlloFisicaGiuridica === "giuridica") {
                    Quota_parte_iva = varTotaleIvaFattura + this.frmOfferta.get(["lav_preventivo", "importoNonDetraibile"]).value
                } else {
                    Quota_parte_iva = (Number(IMPONIBILE_NON_DETRAIBILE) / Number(this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value)) * Number(varTotaleIvaFattura);
                }

                varImporto_Non_Detraibile_Carico_Cliente = Number(IMPONIBILE_NON_DETRAIBILE) + Number(Quota_parte_iva);
            }
        }
        // FINE CALCOLO EX DEI


        if (this.frmOfferta.controls.lav_asseverazione.value == "Ex DEI" ||
            this.frmOfferta.controls.lav_asseverazione.value === 'ALL. A' ||
            this.frmOfferta.controls.lav_asseverazione.value === 'PREZZARI') {
            this.frmOfferta.get(["lav_preventivo", "importoNONDetraibileACaricoCliente"]).setValue(Number(varImporto_Non_Detraibile_Carico_Cliente))
        }

        let varImportoNonDetraibile: Number = 0
        if (this.frmOfferta.controls.lav_asseverazione.value == "Ex DEI" ||
            this.frmOfferta.controls.lav_asseverazione.value === 'ALL. A' ||
            this.frmOfferta.controls.lav_asseverazione.value === 'PREZZARI') {
            varImportoNonDetraibile = varImporto_Non_Detraibile_Carico_Cliente
        }
        else {
            varImportoNonDetraibile = Number(this.frmOfferta.get(["lav_preventivo", "importoNonDetraibile"]).value);
        }
        if (this.ControlloFisicaGiuridica === "giuridica") {
            this.frmOfferta.get(["lav_preventivo", "ImportoLavoriCaricoCliente"]).setValue(
                ((this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value - Number(varImportoNonDetraibile)) *
                    (100 - Number(this.frmOfferta.controls.lat_detrazione.value))) / 100);

            this.frmOfferta.get(["lav_preventivo", "contributoSpettante"]).setValue(
                ((this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value - Number(varImportoNonDetraibile)) *
                    Number(this.frmOfferta.controls.lat_detrazione.value)) / 100);

        } else {
            this.frmOfferta.get(["lav_preventivo", "ImportoLavoriCaricoCliente"]).setValue(
                ((this.totaleFatturaIVA - Number(varImportoNonDetraibile)) *
                    (100 - Number(this.frmOfferta.controls.lat_detrazione.value))) / 100);

            this.frmOfferta.get(["lav_preventivo", "contributoSpettante"]).setValue(
                ((this.totaleFatturaIVA - Number(varImportoNonDetraibile)) *
                    Number(this.frmOfferta.controls.lat_detrazione.value)) / 100);
        }


        // controllo contributo spettante sotto forma di sconto non deve essere maggiorn di 60000
        if (this.frmOfferta.controls.lav_asseverazione.value == "Ex DEI" ||
            this.frmOfferta.controls.lav_asseverazione.value === 'ALL. A' ||
            this.frmOfferta.controls.lav_asseverazione.value === 'PREZZARI') {
            // this.frmOfferta.get(["lav_preventivo", "importoNONDetraibileACaricoCliente"]).setValue(Number(varImporto_Non_Detraibile_Carico_Cliente))
        }
        else {

            if (this.ControlloFisicaGiuridica === "giuridica") {
                this.frmOfferta.get(["lav_preventivo", "importoNONDetraibileACaricoCliente"]).setValue(
                    this.totaleFatturaIVA - this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value +
                    this.frmOfferta.get(["lav_preventivo", "importoNonDetraibile"]).value
                );
            } else {
                this.frmOfferta.get(["lav_preventivo", "importoNONDetraibileACaricoCliente"]).setValue(this.frmOfferta.get(["lav_preventivo", "importoNonDetraibile"]).value);
            }
        }

        // controllo contributo spettante sotto forma di sconto non deve essere maggiorn di 60000
        if (Number(this.frmOfferta.get(["lav_preventivo", "contributoSpettante"]).value) > 60000) {
            this.frmOfferta.get(["lav_preventivo", "contributoSpettante"]).setValue(60000);
            this.eccedenteContributoSpettante = Number(this.frmOfferta.get(["lav_preventivo", "contributoSpettante"]).value) - 60000;
        } else {
            this.eccedenteContributoSpettante = 0
        }

        // controllo contributo spettante sotto forma di sconto non deve essere maggiorn di 60000
        if (Number(this.frmOfferta.get(["lav_preventivo", "ImportoLavoriCaricoCliente"]).value) > 60000) {
            this.frmOfferta.get(["lav_preventivo", "ImportoLavoriCaricoCliente"]).setValue(60000);
            this.eccedenteContributoSpettante = Number(this.frmOfferta.get(["lav_preventivo", "ImportoLavoriCaricoCliente"]).value) - 60000;
        } else {
            this.eccedenteContributoSpettante = 0
        }

        if (Number(this.frmOfferta.controls.lat_oneri.value == 2)) {
            this.frmOfferta.get(["lav_preventivo", "costiAttuazione"]).setValue(
                (this.frmOfferta.get(["lav_preventivo", "contributoSpettante"]).value * Number(this.percentualeOneri)) / 100);
            let ivaAtt: number = 0;
            if (this.frmOfferta.controls.lav_iva_attualizzazione.value == undefined || this.frmOfferta.controls.lav_iva_attualizzazione.value == null || Number(this.frmOfferta.controls.lav_iva_attualizzazione.value) == 0) {
                ivaAtt = 0;
            } else if (Number(this.frmOfferta.controls.lav_iva_attualizzazione.value) == 15) {
                ivaAtt = 22;
            } else {
                ivaAtt = Number(this.frmOfferta.controls.lav_iva_attualizzazione.value)
            }
            this.frmOfferta.get(["lav_preventivo", "costiAttuazioneIVA"]).setValue(Number(this.frmOfferta.get(["lav_preventivo", "costiAttuazione"]).value) +
                ((Number(this.frmOfferta.get(["lav_preventivo", "costiAttuazione"]).value)) * Number(ivaAtt)) / 100);
            // precedentemete era -1 * valore
        } else {
            this.frmOfferta.get(["lav_preventivo", "costiAttuazione"]).setValue(0)
            this.frmOfferta.get(["lav_preventivo", "costiAttuazioneIVA"]).setValue(0)
        }

        // aggiungo a importoNONDetraibileACaricoCliente l parte eccedente dei 60000 di contributo spettante sotto forma di sconto
        this.frmOfferta.get(["lav_preventivo", "importoNONDetraibileACaricoCliente"]).setValue(
            Number(this.frmOfferta.get(["lav_preventivo", "importoNONDetraibileACaricoCliente"]).value) +
            Number(this.eccedenteContributoSpettante)
        )

        this.frmOfferta.get(["lav_preventivo", "totaleDovutoCliente"]).setValue(
            this.frmOfferta.get(["lav_preventivo", "ImportoLavoriCaricoCliente"]).value +
            this.frmOfferta.get(["lav_preventivo", "costiAttuazione"]).value +
            this.frmOfferta.get(["lav_preventivo", "importoNONDetraibileACaricoCliente"]).value
        );

        this.frmOfferta.controls.lav_importo.setValue(this.totaleFatturaIVA);
        this.frmOfferta.get(["lav_preventivo", "totaleFatturaIva"]).setValue(this.totaleFatturaIVA);

        this.CalcoloFattura_SSCO();
        this.calcoloResoconto_CostiAttuazione();
    }

    CalcoloMassimali_SSCO(): void {
        this.frmOfferta.get(["lav_preventivo", "imponibileFornitura"]).setValue(
            this.frmOfferta.get(["lav_preventivo", "nuovoImponibile"]).value -
            Number(this.frmOfferta.controls.lav_posa_opera.value) -
            Number(this.frmOfferta.controls.lav_prestazioni_prof.value) -
            Number(this.frmOfferta.controls.lav_altre_opere.value)
        );
        if (this.offerta.lav_data_aggiunta < new Date("2022-05-05")) {
            this.frmOfferta.get(["lav_preventivo", "massimaleMQdaApplicare"]).setValue(230.0);
        } else {
            this.frmOfferta.get(["lav_preventivo", "massimaleMQdaApplicare"]).setValue(276.0);
        }

        this.frmOfferta.get(["lav_preventivo", "costoMQImponibile"]).setValue(
            this.frmOfferta.get(["lav_preventivo", "imponibileFornitura"]).value / Number(this.frmOfferta.controls.lav_mq_installati.value)
        );

        this.frmOfferta.get(["lav_preventivo", "imponibileMassimoDetraibile"]).setValue(
            Number(this.frmOfferta.controls.lav_mq_installati.value) *
            this.frmOfferta.get(["lav_preventivo", "massimaleMQdaApplicare"]).value
        );

        if (- 1 * (this.frmOfferta.get(["lav_preventivo", "imponibileFornitura"]).value -
            this.frmOfferta.get(["lav_preventivo", "imponibileMassimoDetraibile"]).value) > 0
        ) {
            this.frmOfferta.get(["lav_preventivo", "importoNonDetraibile"]).setValue(0);
        } else if (
            this.ControlloFisicaGiuridica === "giuridica" || this.frmOfferta.controls.lav_iva_offerta.value == "0"
        ) {
            this.frmOfferta.get(["lav_preventivo", "importoNonDetraibile"]).setValue(
                this.frmOfferta.get(["lav_preventivo", "imponibileFornitura"]).value -
                this.frmOfferta.get(["lav_preventivo", "imponibileMassimoDetraibile"]).value
            );
        }
        else if (this.frmOfferta.controls.lav_iva_offerta.value == "10" && this.frmOfferta.controls.lav_iva_prestazioni.value == "22" &&
            this.frmOfferta.controls.lav_asseverazione.value == 'NO' && this.frmOfferta.controls.lav_visto.value == 'NO') {
            this.frmOfferta.get(["lav_preventivo", "importoNonDetraibile"]).setValue(
                ((Number(this.frmOfferta.get(["lav_preventivo", "imponibileFornitura"]).value) -
                    Number(this.frmOfferta.get(["lav_preventivo", "imponibileMassimoDetraibile"]).value)) * (1 + Number(this.ivaPonderata)))
            );
        }
        else if (this.frmOfferta.controls.lav_iva_offerta.value == "22") {
            this.frmOfferta.get(["lav_preventivo", "importoNonDetraibile"]).setValue(
                (this.frmOfferta.get(["lav_preventivo", "imponibileFornitura"]).value -
                    this.frmOfferta.get(["lav_preventivo", "imponibileMassimoDetraibile"]).value) * 1.22
            );
        } else {
            this.frmOfferta.get(["lav_preventivo", "importoNonDetraibile"]).setValue(
                (this.frmOfferta.get(["lav_preventivo", "imponibileFornitura"]).value -
                    this.frmOfferta.get(["lav_preventivo", "imponibileMassimoDetraibile"]).value) * 1.1
            );
        }
    }
    CalcoloFattura_SSCO(): void {
        this.frmOfferta.get(["lav_preventivo", "posaOperaImponibile"]).setValue(Number(this.frmOfferta.controls.lav_posa_opera.value));

        if (this.frmOfferta.controls.lav_iva_offerta.value === "10") {
            this.frmOfferta.get(["lav_preventivo", "posaOpera10"]).setValue((this.frmOfferta.get(["lav_preventivo", "posaOperaImponibile"]).value * 10) / 100);
        } else {
            this.frmOfferta.get(["lav_preventivo", "posaOpera10"]).setValue(0);
        }

        if (this.frmOfferta.controls.lav_iva_offerta.value === "22") {
            this.frmOfferta.get(["lav_preventivo", "posaOpera22"]).setValue((this.frmOfferta.get(["lav_preventivo", "posaOperaImponibile"]).value * 22) / 100);
        } else {
            this.frmOfferta.get(["lav_preventivo", "posaOpera22"]).setValue(0);
        }

        this.frmOfferta.get(["lav_preventivo", "posaOperaTot"]).setValue(
            this.frmOfferta.get(["lav_preventivo", "posaOperaImponibile"]).value +
            this.frmOfferta.get(["lav_preventivo", "posaOpera10"]).value +
            this.frmOfferta.get(["lav_preventivo", "posaOpera22"]).value
        );

        // --------  Valore di vendita schermature solari = Altri Beni Accessori

        if (Number(this.frmOfferta.controls.lav_vendita_infissi.value) === 0) {
            this.frmOfferta.get(["lav_preventivo", "altibeniaccImponibile"]).setValue(0);
        } else if (
            Number(this.frmOfferta.controls.lav_altri_beni.value) === 0 && Number(this.frmOfferta.controls.lav_beni_significativi.value) === 0
        ) {
            this.frmOfferta.get(["lav_preventivo", "altibeniaccImponibile"]).setValue(
                Number(this.frmOfferta.controls.lav_vendita_infissi.value) + this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).value
            );
        } else if (
            Number(this.frmOfferta.controls.lav_altri_beni.value) === 0 ||
            Number(this.frmOfferta.controls.lav_beni_significativi.value) === 0
        ) {
            this.frmOfferta.get(["lav_preventivo", "altibeniaccImponibile"]).setValue(
                Number(this.frmOfferta.controls.lav_vendita_infissi.value) + this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).value / 2
            );
        } else {
            this.frmOfferta.get(["lav_preventivo", "altibeniaccImponibile"]).setValue(
                Number(this.frmOfferta.controls.lav_vendita_infissi.value) + this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).value / 3
            );
        }

        if (this.frmOfferta.controls.lav_iva_offerta.value == "10") {
            this.frmOfferta.get(["lav_preventivo", "altibeniacc10"]).setValue(
                (this.frmOfferta.get(["lav_preventivo", "altibeniaccImponibile"]).value * 10) / 100
            );
        } else {
            this.frmOfferta.get(["lav_preventivo", "altibeniacc10"]).setValue(0);
        }
        if (this.frmOfferta.controls.lav_iva_offerta.value == "22") {
            this.frmOfferta.get(["lav_preventivo", "altibeniacc22"]).setValue(
                (this.frmOfferta.get(["lav_preventivo", "altibeniaccImponibile"]).value * 22) / 100
            );
        } else {
            this.frmOfferta.get(["lav_preventivo", "altibeniacc22"]).setValue(0);
        }

        this.frmOfferta.get(["lav_preventivo", "altibeniaccTot"]).setValue(
            this.frmOfferta.get(["lav_preventivo", "altibeniaccImponibile"]).value +
            this.frmOfferta.get(["lav_preventivo", "altibeniacc10"]).value +
            this.frmOfferta.get(["lav_preventivo", "altibeniacc22"]).value
        );

        // --------  Valore di vendita chiusure oscuranti tecniche = Valore di Vendita Infissi al 10%
        if (Number(this.frmOfferta.controls.lav_beni_significativi.value) === 0) {
            this.frmOfferta.get(["lav_preventivo", "valore10Imponibile"]).setValue(0);
        } else if (
            Number(this.frmOfferta.controls.lav_altri_beni.value) === 0 &&
            Number(this.frmOfferta.controls.lav_vendita_infissi.value) === 0
        ) {
            this.frmOfferta.get(["lav_preventivo", "valore10Imponibile"]).setValue(
                Number(this.frmOfferta.controls.lav_beni_significativi.value) + this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).value
            );
        } else if (
            Number(this.frmOfferta.controls.lav_altri_beni.value) === 0 || Number(this.frmOfferta.controls.lav_vendita_infissi.value) === 0
        ) {
            this.frmOfferta.get(["lav_preventivo", "valore10Imponibile"]).setValue(
                Number(this.frmOfferta.controls.lav_beni_significativi.value) +
                this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).value /
                2
            );
        } else {
            this.frmOfferta.get(["lav_preventivo", "valore10Imponibile"]).setValue(
                Number(this.frmOfferta.controls.lav_beni_significativi.value) +
                this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).value / 3
            );
        }

        if (this.frmOfferta.controls.lav_iva_offerta.value == "10") {
            this.frmOfferta.get(["lav_preventivo", "valore1010"]).setValue(
                (this.frmOfferta.get(["lav_preventivo", "valore10Imponibile"]).value * 10) / 100
            );
        } else {
            this.frmOfferta.get(["lav_preventivo", "valore1010"]).setValue(0);
        }

        if (this.frmOfferta.controls.lav_iva_offerta.value == "22") {
            this.frmOfferta.get(["lav_preventivo", "valore1022"]).setValue(
                (this.frmOfferta.get(["lav_preventivo", "valore10Imponibile"]).value * 22) / 100);
        } else {
            this.frmOfferta.get(["lav_preventivo", "valore1022"]).setValue(0);
        }

        this.frmOfferta.get(["lav_preventivo", "valore10Tot"]).setValue(
            this.frmOfferta.get(["lav_preventivo", "valore10Imponibile"]).value +
            this.frmOfferta.get(["lav_preventivo", "valore1010"]).value +
            this.frmOfferta.get(["lav_preventivo", "valore1022"]).value
        );
        // --------  Valore di vendita chiusure oscuranti tecniche = Valore di Vendita Infissi al 22%
        if (Number(this.frmOfferta.controls.lav_altri_beni.value) === 0) {
            this.frmOfferta.get(["lav_preventivo", "valore22Imponibile"]).setValue(0);
        } else if (Number(this.frmOfferta.controls.lav_vendita_infissi.value) === 0 || Number(this.frmOfferta.controls.lav_beni_significativi.value) === 0
        ) {
            this.frmOfferta.get(["lav_preventivo", "valore22Imponibile"]).setValue(
                Number(this.frmOfferta.controls.lav_altri_beni.value) + this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).value / 2
            );
        } else {
            this.frmOfferta.get(["lav_preventivo", "valore22Imponibile"]).setValue(
                Number(this.frmOfferta.controls.lav_altri_beni.value) + this.frmOfferta.get(["lav_preventivo", "speseTecniche"]).value / 3
            );
        }

        if (this.frmOfferta.controls.lav_iva_offerta.value == "10") {
            this.frmOfferta.get(["lav_preventivo", "valore2210"]).setValue(
                (this.frmOfferta.get(["lav_preventivo", "valore22Imponibile"]).value * 10) / 100);
        } else {
            this.frmOfferta.get(["lav_preventivo", "valore2210"]).setValue(0);
        }

        if (this.frmOfferta.controls.lav_iva_offerta.value == "22") {
            this.frmOfferta.get(["lav_preventivo", "valore2222"]).setValue(
                (this.frmOfferta.get(["lav_preventivo", "valore22Imponibile"]).value * 22) / 100);
        } else {
            this.frmOfferta.get(["lav_preventivo", "valore2222"]).setValue(0);
        }

        this.frmOfferta.get(["lav_preventivo", "valore22Tot"]).setValue(
            this.frmOfferta.get(["lav_preventivo", "valore22Imponibile"]).value +
            this.frmOfferta.get(["lav_preventivo", "valore2210"]).value +
            this.frmOfferta.get(["lav_preventivo", "valore2222"]).value
        );

        this.frmOfferta.get(["lav_preventivo", "altreOpereImponibile"]).setValue(Number(this.frmOfferta.controls.lav_altre_opere.value));

        if (this.frmOfferta.controls.lav_iva_offerta.value == "10") {
            this.frmOfferta.get(["lav_preventivo", "altreOpere10"]).setValue(
                (this.frmOfferta.get(["lav_preventivo", "altreOpereImponibile"]).value * 10) / 100);
        } else {
            this.frmOfferta.get(["lav_preventivo", "altreOpere10"]).setValue(0);
        }

        if (this.frmOfferta.controls.lav_iva_offerta.value == "22") {
            this.frmOfferta.get(["lav_preventivo", "altreOpere22"]).setValue(
                (this.frmOfferta.get(["lav_preventivo", "altreOpereImponibile"]).value * 22) / 100);
        } else {
            this.frmOfferta.get(["lav_preventivo", "altreOpere22"]).setValue(0);
        }

        this.frmOfferta.get(["lav_preventivo", "altreOpereTot"]).setValue(
            this.frmOfferta.get(["lav_preventivo", "altreOpereImponibile"]).value +
            this.frmOfferta.get(["lav_preventivo", "altreOpere10"]).value +
            this.frmOfferta.get(["lav_preventivo", "altreOpere22"]).value
        );

        this.frmOfferta.get(["lav_preventivo", "prestProfImponibile"]).setValue(Number(this.frmOfferta.controls.lav_prestazioni_prof.value));

        if (this.frmOfferta.controls.lav_iva_prestazioni.value == "22") {
            this.frmOfferta.get(["lav_preventivo", "prestProf10"]).setValue(0);
        } else if (this.frmOfferta.controls.lav_iva_offerta.value == "10") {
            this.frmOfferta.get(["lav_preventivo", "prestProf10"]).setValue(
                (this.frmOfferta.get(["lav_preventivo", "prestProfImponibile"]).value * 10) / 100
            );
        } else {
            this.frmOfferta.get(["lav_preventivo", "prestProf10"]).setValue(0);
        }

        ///////   modifiche per calcolo EX dei e All.I con iva 10% o 0% e prestazione a 22%

        if (this.frmOfferta.controls.lav_asseverazione.value == "Ex DEI" || this.frmOfferta.controls.lav_asseverazione.value == "Ex All.I" ||
            this.frmOfferta.controls.lav_asseverazione.value === 'ALL. A' || this.frmOfferta.controls.lav_asseverazione.value === 'PREZZARI') {
            if (this.frmOfferta.controls.lav_iva_offerta.value === "0") {
                this.frmOfferta.get(["lav_preventivo", "prestProf10"]).setValue(0);
                this.frmOfferta.get(["lav_preventivo", "prestProf22"]).setValue(0);
            }
            else if (this.frmOfferta.controls.lav_iva_prestazioni.value === "22") {
                this.frmOfferta.get(["lav_preventivo", "prestProf22"]).setValue((Number(this.frmOfferta.get(["lav_preventivo", "prestProfImponibile"]).value) * 22) / 100);
                this.frmOfferta.get(["lav_preventivo", "prestProf10"]).setValue(0);
            }
            else {
                this.frmOfferta.get(["lav_preventivo", "prestProf10"]).setValue((Number(this.frmOfferta.get(["lav_preventivo", "prestProfImponibile"]).value) * 10) / 100);
                this.frmOfferta.get(["lav_preventivo", "prestProf22"]).setValue(0);
            }
        } else {
            if (this.frmOfferta.controls.lav_iva_offerta.value === "10" && this.frmOfferta.controls.lav_iva_prestazioni.value === "22") {
                this.frmOfferta.get(["lav_preventivo", "prestProf22"]).setValue((Number(this.frmOfferta.get(["lav_preventivo", "prestProfImponibile"]).value) * 22) / 100);
                this.frmOfferta.get(["lav_preventivo", "prestProf10"]).setValue(0);
            }
            else if (this.frmOfferta.controls.lav_iva_offerta.value === "0" || this.frmOfferta.controls.lav_iva_offerta.value === "10") {
                this.frmOfferta.get(["lav_preventivo", "prestProf22"]).setValue(0);
            } else if (this.frmOfferta.controls.lav_iva_offerta.value === "22" || this.frmOfferta.controls.lav_iva_prestazioni.value === "22") {
                this.frmOfferta.get(["lav_preventivo", "prestProf22"]).setValue((Number(this.frmOfferta.get(["lav_preventivo", "prestProfImponibile"]).value) * 22) / 100);
            } else { this.frmOfferta.get(["lav_preventivo", "prestProf22"]).setValue(0); }
        }
        ///////// ---------------------------------------------------

        this.frmOfferta.get(["lav_preventivo", "prestProfTot"]).setValue(
            this.frmOfferta.get(["lav_preventivo", "prestProfImponibile"]).value +
            this.frmOfferta.get(["lav_preventivo", "prestProf10"]).value +
            this.frmOfferta.get(["lav_preventivo", "prestProf22"]).value
        );

        this.frmOfferta.get(["lav_preventivo", "ftImponibile"]).setValue(
            this.frmOfferta.get(["lav_preventivo", "posaOperaImponibile"]).value +
            this.frmOfferta.get(["lav_preventivo", "altibeniaccImponibile"]).value +
            this.frmOfferta.get(["lav_preventivo", "valore10Imponibile"]).value +
            this.frmOfferta.get(["lav_preventivo", "valore22Imponibile"]).value +
            this.frmOfferta.get(["lav_preventivo", "altreOpereImponibile"]).value +
            this.frmOfferta.get(["lav_preventivo", "prestProfImponibile"]).value
        );

        this.frmOfferta.get(["lav_preventivo", "ft10"]).setValue(
            this.frmOfferta.get(["lav_preventivo", "posaOpera10"]).value +
            this.frmOfferta.get(["lav_preventivo", "altibeniacc10"]).value +
            this.frmOfferta.get(["lav_preventivo", "valore1010"]).value +
            this.frmOfferta.get(["lav_preventivo", "valore2210"]).value +
            this.frmOfferta.get(["lav_preventivo", "altreOpere10"]).value +
            this.frmOfferta.get(["lav_preventivo", "prestProf10"]).value
        );

        this.frmOfferta.get(["lav_preventivo", "ft22"]).setValue(
            this.frmOfferta.get(["lav_preventivo", "posaOpera22"]).value +
            this.frmOfferta.get(["lav_preventivo", "altibeniacc22"]).value +
            this.frmOfferta.get(["lav_preventivo", "valore1022"]).value +
            this.frmOfferta.get(["lav_preventivo", "valore2222"]).value +
            this.frmOfferta.get(["lav_preventivo", "altreOpere22"]).value +
            this.frmOfferta.get(["lav_preventivo", "prestProf22"]).value
        );

        this.frmOfferta.get(["lav_preventivo", "ftTot"]).setValue(
            this.frmOfferta.get(["lav_preventivo", "posaOperaTot"]).value +
            this.frmOfferta.get(["lav_preventivo", "altibeniaccTot"]).value +
            this.frmOfferta.get(["lav_preventivo", "valore10Tot"]).value +
            this.frmOfferta.get(["lav_preventivo", "valore22Tot"]).value +
            this.frmOfferta.get(["lav_preventivo", "altreOpereTot"]).value +
            this.frmOfferta.get(["lav_preventivo", "prestProfTot"]).value
        );
    }
    //FINE CALCOLO SSCO  
}
