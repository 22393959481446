import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AppBreadcrumbService } from 'src/app/app.breadcrumb.service';
import { LavoriStep45, AggregatiGroup, Aggregati } from 'src/app/_interfaces/step-lavori';
import { StepLavoriService } from 'src/app/_services/step-lavori.service';
import { UploadService } from 'src/app/_services/upload.service';
import { UtentiService } from 'src/app/_services/utenti.service';
import { environment } from 'src/environments/environment';
import { HtmlPreviewStep5Component } from '../html-preview-step5/html-preview-step5.component';
import { Utente, utenteAttr } from "./../../_interfaces/utente";
import { Serramentista } from 'src/app/_interfaces/serramentista';
import { SerramentistaService } from 'src/app/_services/serramentista.service';
@Component({
  selector: 'app-cessione-credito',
  templateUrl: './cessione-credito.component.html',
  styleUrls: ['./cessione-credito.component.scss']
})
export class CessioneCreditoComponent implements OnInit {
  @ViewChild('dt', { static: false }) dt;
  @ViewChild('searchField', { static: false }) searchField;

  lavStep45: LavoriStep45[];
  aggregati: AggregatiGroup[];
  aggregatiNome: Aggregati[];
  cessionari: Serramentista[];
  utenti: utenteAttr[];
  upFile: File;
  environment = environment;
  ruoloUtente: string;
  idSerramentista: number = 0;
  openModal: boolean;
  openModalAggregati: boolean;
  openModalValidazione: boolean;

  openUpload: boolean;

  selezionaDeseleziona: boolean;
  baseUrl: string = environment.baseUrl;
  ref: DynamicDialogRef;
  nomePerValidazione: string = "";
  serramentistaID: string = "";
  meseScelto: string = ""

  maxNumerazione: number = 0;

  mese: { label: string, value: string }[] = [
    { label: "Gennaio", value: "Gennaio" },
    { label: "Febbraio", value: "Febbraio" },
    { label: "Marzo", value: "Marzo" },
    { label: "Aprile", value: "Aprile" },
    { label: "Maggio", value: "Maggio" },
    { label: "Giugno", value: "Giugno" },
    { label: "Luglio", value: "Luglio" },
    { label: "Agosto", value: "Agosto" },
    { label: "Settembre", value: "Settembre" },
    { label: "Ottobre", value: "Ottobre" },
    { label: "Novembre", value: "Novembre" },
    { label: "Dicembre", value: "Dicembre" }
  ];
  annoScelto: string = "";
  anno: { label: string, value: string }[] = [
    { label: "2020", value: "2020" },
    { label: "2021", value: "2021" },
    { label: "2022", value: "2022" },
    { label: "2023", value: "2023" },
    { label: "2024", value: "2024" },
    { label: "2025", value: "2025" },
    { label: "2026", value: "2026" },
    { label: "2027", value: "2027" },
    { label: "2028", value: "2028" },
    { label: "2029", value: "2029" },
    { label: "2030", value: "2030" },
    { label: "2031", value: "2031" }
  ];

  constructor(
    private stepDocService: StepLavoriService,
    private breadcrumbService: AppBreadcrumbService,
    private msgService: MessageService,
    private dialogService: DialogService,
    private utenteSrv: UtentiService,
    private uploadSrv: UploadService,
    private confirmService: ConfirmationService,
    private serramentistaSrv: SerramentistaService,) { }

  ngOnInit(): void {
    this.openModal = false;
    this.openModalAggregati = false;
    this.openModalValidazione = false;
    this.selezionaDeseleziona = false;
    this.openUpload = false;
    this.idSerramentista = 0;
    this.serramentistaID = '0';
    this.meseScelto = "";
    this.annoScelto = "";
    this.nomePerValidazione = "";
    this.breadcrumbService.setItems([
      { label: 'Cessione Credito' },
    ]);
    this.ruoloUtente = sessionStorage.getItem('ruolo');
    if (this.ruoloUtente === 'AD' || this.ruoloUtente === 'SA') {
      this.refreshAggregati();
    } else {
      this.refreshAggregatiUser()
    }
  }

  refreshAggregati() {
    this.refreshCessionario();
    this.stepDocService.getStepAggredati('5').subscribe((res) => {
      this.aggregati = [...res.data]
    });
  }

  refreshCessionario(): void {
    this.serramentistaSrv.getCessionarifinali().subscribe((res) => {
      this.cessionari = [...res.data];
    });
  }

  refreshAggregatiUser() {
    this.stepDocService.getStepAggredatiUtenti('5', sessionStorage.getItem("id")).subscribe((res) => {
      this.aggregati = [...res.data]
    });
  }

  refreshAggregatiNome(nome: string, serr: string) {
    this.stepDocService.getStepAggregatiNome(nome, serr).subscribe((res) => {
      this.aggregatiNome = [...res.data]
    });
  }

  refreshLavoriStep57(idSerramentista: string) {
    this.stepDocService.getDocumentiStep45(idSerramentista).subscribe((res) => {
      this.lavStep45 = [...res.data];
      this.lavStep45.forEach((r) => r.sel = false);
    });
  }

  refreshUtenti(id: number): void {
    this.utenteSrv.getUtentiCess(id).subscribe((res) => {
      this.utenti = [...res.data];
    });
  }

  CaricaSerramentista(ev): void {
    this.refreshUtenti(ev.value);
  }

  onChangeSel(ev): void {
    const id = ev.target.value;
    const isChecked = ev.target.checked;
    this.lavStep45 = this.lavStep45.map((d) => {
      if (d.lav_id == id) {
        d.sel = isChecked;
        return d;
      }
      return d;
    })
  }

  submitAggregazione(): void {
    let lav: LavoriStep45[] = this.lavStep45.filter((i) => i.sel === true);
    let aggregato: AggregatiGroup = {};
    let aggregati: AggregatiGroup[] = [];
    lav.forEach((item) => {
      aggregato.la_nome = this.meseScelto + this.annoScelto;
      aggregato.la_lavoro = item.lav_id;
      aggregato.la_step = 5;
      aggregato.la_serr = item.lav_user;
      aggregati.push(aggregato);
      aggregato = {};
    })
    if (this.annoScelto === "" || this.meseScelto === "") {
      this.msgService.add({
        key: 'tst',
        severity: 'error',
        summary: `Attenzione`,
        detail: 'Inserire il Mese/Anno',
      });
      return;
    }
    if (aggregati.length > 0) {
      this.stepDocService.addAggregati(aggregati).subscribe((res) => {
        this.msgService.add({
          key: 'tst',
          severity: res.res === 'ok' ? 'success' : 'error',
          summary: `Offerta`,
          detail: res.res === 'ok' ? 'Aggregazione Salvata' : 'Errore in fase di Salvataggio',
        });
        this.refreshAggregati();
        this.refreshLavoriStep57(this.idSerramentista.toString());
        this.openModal = false;
      });
    }
  }

  UpdateGestoreStep5(name: any, step: string, numero: string, stato: number, serr: string): void {
    this.stepDocService.updGestoreAggregatiStep5(name, step, numero, stato, serr).subscribe((res) => {
      this.msgService.add({
        key: 'tst',
        severity: res.res === 'ok' ? 'success' : 'error',
        summary: `Convalida`,
        detail: res.message,
      });
      this.refreshAggregati();
    })
  }

  DeleteAggragato(id: number): void {
    this.stepDocService.delAggregatiStep5(id).subscribe((res) => {
      this.msgService.add({
        key: 'tst',
        severity: res.res === 'ok' ? 'success' : 'error',
        summary: `Convalida`,
        detail: res.message,
      });
      this.openModalAggregati = false;
      this.refreshAggregati();
    })
  }

  OpenModalAggregazione(): void {
    this.openModal = true;
  }


  closeModale(): void {
    this.openModal = false;
  }

  closeModaleAggragati(): void {
    this.openModalAggregati = false;
  }

  openModalDown(id, nome): void {
    this.ref = this.dialogService.open(HtmlPreviewStep5Component, {
      width: '70%',
      contentStyle: { "overflow": "auto" },
      baseZIndex: 10000,
      data: { id: id, nome: nome }
    });
  }

  Modalaggregati(nome: string, serr: string): void {
    this.openModalAggregati = true;
    this.refreshAggregatiNome(nome, serr);
  }

  ModaleValida(nomeAggregazione: string, serramentistaID: string): void {
    this.openModalValidazione = true;
    this.nomePerValidazione = nomeAggregazione
    this.serramentistaID = serramentistaID
  }

  SearchStep5(): void {
    this.refreshLavoriStep57(this.idSerramentista.toString());

  }

  SelDeselGrid(ev): void {
    this.lavStep45 = this.lavStep45.map((d) => {
      d.sel = this.selezionaDeseleziona;
      return d;
    })
  }

  onUpload(event, docStep: string, numDoc: string, docTipo: string, serrId: string) {
    for (let file of event.files) {
      this.upFile = file;
    }
    this.uploadFileToActivity(docStep, numDoc, docTipo, serrId);
  }


  uploadFileToActivity(docStep: string, numDoc: string, nomeDoc: string, serrid: string) {
    this.uploadSrv.uploadAggregatiStep5(this.upFile, nomeDoc, docStep, numDoc, serrid).subscribe((res) => {
      this.msgService.add({
        key: 'tst',
        severity: 'info',
        summary: `File Caricato`,
        detail: res.nome_file,
      });
      this.refreshAggregati();
      this.openUpload = false;
    }, error => {
      this.msgService.add({
        key: 'tst',
        severity: 'error',
        summary: `File Caricato`,
        detail: error.statusText,
        // detail: 'ERRORE: File NON Caricato',
      });
    });
  }

  IconaCheck(stato: number): string {
    if (stato === 1 || stato === 0) {
      return "pi pi-question-circle";
    } else if (stato === 2) {
      return "pi pi-info-circle";
    } else if (stato === 3) {
      return "pi pi-times-circle";
    } else {
      return "pi pi-check-circle";
    }
  }

  ColorButton(stato: number): string {
    if (stato === 1 || stato === 0) {
      return "p-button-secondary";
    } else if (stato === 2) {
      return "p-button-danger";
    } else if (stato === 3) {
      return "p-button-warning";
    } else {
      return "p-button-success";
    }
  }

  DocValida(Stato: number): void {
    this.UpdateGestoreStep5(this.nomePerValidazione, '5', '1', Stato, this.serramentistaID)
    this.openModalValidazione = false;
  }

  Cancelaggregati(id: string, lavoro: string, numerazione: string) {


    this.confirmService.confirm({
      header: `Elimina Cessione Credito`,
      message: 'Sicuro di Eseguire l\'operazione di Cancellazione?',
      icon: 'pi  pi-question-circle',
      accept: () => {
        this.stepDocService.delCessioneCredito(id, lavoro, numerazione).subscribe((res) => {
          this.msgService.add({
            key: 'tst',
            severity: res.res === 'ok' ? 'success' : 'error',
            summary: `Elimina`,
            detail: res.message,
          });
          this.openModalAggregati = false;
          this.refreshAggregati();
        })
      },
    });
  }

  RemoveFind(): void {
    this.dt.clear();
    this.searchField.value = "";
    sessionStorage.removeItem("stateCessione-session")
  }


  la_nome: string = "";
  la_serr: string = "";
  la_validato_5: number = 0;

  openModalUpload(nome: string, serr: string, validato_5: number) {
    this.openUpload = true;
    this.la_nome = nome;
    this.la_serr = serr;
    this.la_validato_5 = validato_5;
  }
}
