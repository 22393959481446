import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import * as _ from "lodash";
import { Preventivi } from "../_interfaces/preventivi";

export class GeneraForm {
  public static requiredIfFisica = (c: AbstractControl): ValidationErrors => {
    const frmCliente = c.root;
    if (!frmCliente) {
      return null;
    }
    const tipo = frmCliente.get("cl_tipo") ?? null;
    if (!_.isEmpty(tipo)) {
      if (tipo.value === "fisica" && _.isEmpty(_.toString(c.value))) {
        return { required: true };
      }
    }
    return null;
  };

  public static requiredIfGiuridica = (c: AbstractControl): ValidationErrors => {
    const frmCliente = c.root;
    if (!frmCliente) {
      return null;
    }
    const tipo = frmCliente.get("cl_tipo") ?? null;
    if (!_.isEmpty(tipo)) {
      if (tipo.value === "giuridica" && _.isEmpty(c.value)) {
        return { required: true };
      }
    }
    return null;
  };

  public static requiredIfItalianResidenza = (c: AbstractControl): ValidationErrors => {
    const frmCliente = c.root;
    if (!frmCliente) {
      return null;
    }
    const nazione = frmCliente.get("residenza.r_nazione") ?? null;
    if (!_.isEmpty(nazione)) {
      if (nazione.value === "Italia" && _.isEmpty(c.value)) {
        return { required: true };
      }
    }
    return null;
  };

  public static requiredIfItalian = (c: AbstractControl): ValidationErrors => {
    const frmCliente = c.root;
    if (!frmCliente) {
      return null;
    }
    const nazione = frmCliente.get("cl_nazione_nascita") ?? null;
    if (!_.isEmpty(nazione.value)) {
      if (nazione.value === "Italia" && _.isEmpty(c.value)) {
        return { required: true };
      }
    }
    return null;
  };

  public static createFormCliente = () =>
    new FormGroup({
      cl_id: new FormControl(0),
      cl_serramentista: new FormControl("", Validators.required),
      cl_tipo: new FormControl("fisica"),
      cl_ha_piva: new FormControl("", Validators.required),
      cl_cognome: new FormControl("", Validators.required),
      cl_nome: new FormControl("", [GeneraForm.requiredIfFisica]),
      cl_data_nascita: new FormControl("", [GeneraForm.requiredIfFisica]),
      cl_nazione_nascita: new FormControl("Italia", [GeneraForm.requiredIfFisica,]),
      cl_regione_nascita: new FormControl(""),
      cl_citta_nascita: new FormControl("", [GeneraForm.requiredIfFisica,]),
      cl_provincia_nascita: new FormControl("", []),
      cl_telefono: new FormControl("", Validators.required),
      cl_email: new FormControl("", [Validators.required, Validators.email,]),
      cl_pec: new FormControl("", [GeneraForm.requiredIfGiuridica, Validators.email,]),

      cl_cf: new FormControl(""),
      cl_piva: new FormControl(""),
      cl_cancellato: new FormControl(0),
      cl_note: new FormControl(""),
      cl_check_cf: new FormControl(false, Validators.required),

      documento: new FormGroup({
        doc_cliente: new FormControl(0, [GeneraForm.requiredIfFisica]),
        doc_tipo: new FormControl("", [GeneraForm.requiredIfFisica]),
        doc_rilasciato_da: new FormControl("", [GeneraForm.requiredIfFisica,]),
        doc_numero: new FormControl("", [GeneraForm.requiredIfFisica]),
        doc_emissione: new FormControl("", [GeneraForm.requiredIfFisica,]),
        doc_scadenza: new FormControl("", [GeneraForm.requiredIfFisica,]),
      }),

      legale: new FormGroup({
        rl_id: new FormControl(0),
        rl_cliente: new FormControl(0),
        rl_cognome: new FormControl("", [GeneraForm.requiredIfGiuridica,]),
        rl_nome: new FormControl("", [GeneraForm.requiredIfGiuridica]),
        rl_telefono: new FormControl("", [GeneraForm.requiredIfGiuridica,]),
        rl_email: new FormControl("", [GeneraForm.requiredIfGiuridica, Validators.email,]),
        rl_cf: new FormControl("", [GeneraForm.requiredIfGiuridica,]),
      }),

      residenza: new FormGroup({
        r_id: new FormControl(0),
        r_cliente: new FormControl(0),
        r_nazione: new FormControl("Italia", [Validators.required]),
        r_regione: new FormControl("", [GeneraForm.requiredIfItalianResidenza,]),
        r_citta: new FormControl("", [Validators.required]),
        r_cap: new FormControl("", [Validators.required]),
        r_indirizzo: new FormControl("", [Validators.required]),
        r_civico: new FormControl("", [Validators.required]),
        r_provincia: new FormControl("", [
          GeneraForm.requiredIfItalianResidenza,
        ]),
      }),
    });

  public static createFormAbitazione = (id) =>
    new FormGroup({
      ab_id: new FormControl(0),
      ab_cliente: new FormControl(id),
      ab_provincia: new FormControl("", Validators.required),
      ab_regione: new FormControl("", Validators.required),
      ab_citta: new FormControl("", Validators.required),
      ab_indirizzo: new FormControl("", Validators.required),
      ab_civico: new FormControl("", Validators.required),
      ab_cap: new FormControl("", Validators.required),
      ab_codice_comune: new FormControl("", Validators.required),
      ab_foglio: new FormControl("", Validators.required),
      ab_mappale: new FormControl("", Validators.required),
      ab_mq_immobile: new FormControl(0, Validators.required),
      ab_mq_infissi: new FormControl(0, Validators.required),
      ab_categoria_catastale: new FormControl("", Validators.required),
      ab_subalterno: new FormControl("", Validators.required),
      ab_titolarita: new FormControl("", Validators.required),
      ab_cancellata: new FormControl(0),
      ab_note_ui: new FormControl(""),
    });

  public static createFormClientePreventivo = () =>
    new FormGroup({
      cl_id: new FormControl(0),
      cl_nome: new FormControl("", [GeneraForm.requiredIfFisica]),
      cl_cognome: new FormControl("", Validators.required),
      cl_cf: new FormControl("", Validators.required),
      cl_email: new FormControl("", [Validators.required, Validators.email,]),
      cl_telefono: new FormControl("", Validators.required),
      cl_tipo: new FormControl("fisica"),
      cl_ha_piva: new FormControl("", Validators.required),
      cl_serramentista: new FormControl("", Validators.required),
      cl_check_cf: new FormControl(true, Validators.required),
    });

  public static createFormPreventivo = (preventivo?: Preventivi) =>
    new FormGroup({
      prev_id: new FormControl(preventivo?.prev_id ?? 0),
      prev_tipo: new FormControl("preventivo"),
      prev_user: new FormControl(preventivo?.prev_user ?? 0),
      prev_stato: new FormControl(preventivo?.prev_stato ?? 0),
      prev_importo: new FormControl(preventivo?.prev_importo ?? 0), //controllare
      prev_codice: new FormControl(preventivo?.prev_codice ?? ""),
      anagraficaForm: new FormGroup({ prev_cliente: new FormControl(preventivo?.prev_cliente ?? 0), }),
      abitazioniForm: new FormGroup({ prev_abitazione: new FormControl(preventivo?.prev_abitazione ?? ""), }),

      tipologiaSelectForm: new FormGroup({
        prev_tipo_detrazione: new FormControl(preventivo?.prev_tipo_detrazione ?? ""),
        prev_tipologia: new FormControl(preventivo?.prev_tipologia ?? "", Validators.required),
        prev_tipologia_intervento: new FormControl(preventivo?.prev_tipologia_intervento ?? 0, Validators.required),
        prev_tipo_bc: new FormControl(preventivo?.prev_tipo_bc ?? "", Validators.required),
        prev_tipologia_interventoBC: new FormControl(),
        prev_tipologia_agevolazione: new FormControl(preventivo?.prev_tipologia_agevolazione ?? 0, Validators.required),
        prev_tipo_unita: new FormControl(preventivo?.prev_tipo_unita ?? "", Validators.required),
        prev_descrizione: new FormControl(preventivo?.prev_descrizione ?? "", Validators.required),
        prev_chiusura: new FormControl(preventivo?.prev_chiusura ?? "", Validators.required),
        prev_zona_climatica: new FormControl(preventivo?.prev_zona_climatica ?? "", Validators.required),
        prev_transmittanza: new FormControl(preventivo?.prev_transmittanza ?? ""),
        prev_prodotto_installato: new FormControl(preventivo?.prev_prodotto_installato ?? "", Validators.required),
        prev_mq_installati: new FormControl(preventivo?.prev_mq_installati ?? 0, Validators.required),
        prev_mq_installati_co: new FormControl(preventivo?.prev_mq_installati_co ?? 0, Validators.required),
      }),

      preventivoForm: new FormGroup({
        prev_tipologia: new FormControl(preventivo?.prev_tipologia ?? ""), //Serve solo per la view
        prev_tipologia_intervento: new FormControl(preventivo?.prev_tipologia_intervento ?? ""), //Serve solo per la view
        prev_tipo_bc: new FormControl(preventivo?.prev_tipo_bc ?? ""),
        prev_tipo: new FormControl(preventivo?.prev_tipo ?? ""),
        prev_asseverazione: new FormControl(preventivo?.prev_asseverazione ?? "", Validators.required),
        prev_praticaenea: new FormControl(preventivo?.prev_praticaenea ?? 1, Validators.required),
        prev_costoenea: new FormControl(preventivo?.prev_costoenea ?? 11, Validators.required),
        prev_visto: new FormControl("NO", Validators.required),
        prev_iva_offerta: new FormControl(preventivo?.prev_iva_offerta ?? "", Validators.required),
        prev_iva_prestazioni: new FormControl(preventivo?.prev_iva_prestazioni ?? 0, Validators.required),
        prev_vendita_infissi: new FormControl(preventivo?.prev_vendita_infissi ?? ""),
        prev_posa_opera: new FormControl(preventivo?.prev_posa_opera ?? ""),
        prev_beni_significativi: new FormControl(preventivo?.prev_beni_significativi ?? ""),
        prev_altri_beni: new FormControl(preventivo?.prev_altri_beni ?? ""),
        prev_altre_opere: new FormControl(preventivo?.prev_altre_opere ?? ""),
        prev_prestazioni_prof: new FormControl(preventivo?.prev_prestazioni_prof ?? ""),
        prev_iva_attualizzazione: new FormControl(preventivo?.prev_iva_attualizzazione ?? 0),
        prev_max_detraibile: new FormControl(preventivo?.prev_max_detraibile ?? 0),
        prev_multi_detrazione: new FormControl(preventivo?.prev_multi_detrazione ?? false),
        prev_finanz: new FormControl(preventivo?.prev_finanz ?? false),
        prev_imp_detraibile_enea: new FormControl(preventivo?.prev_imp_detraibile_enea ?? 0),
        prev_recupero_fiscale_anno: new FormControl(preventivo?.prev_recupero_fiscale_anno ?? 0),
        prev_note_orc: new FormControl(preventivo?.prev_note_orc ?? ""),
        prev_note_admin: new FormControl(preventivo?.prev_note_admin ?? ""),
        prev_note_serr: new FormControl(preventivo?.prev_note_serr ?? ""),
        prev_imp_max_ass: new FormControl(preventivo?.prev_imp_max_ass ?? 0),
        prev_lav_id: new FormControl(preventivo?.prev_lav_id ?? 0),
        lat_id: new FormControl(preventivo?.lat_id ?? "0"),
        lat_lavoro: new FormControl(preventivo?.lat_lavoro ?? "0"),
        lat_tipo: new FormControl(preventivo?.lat_tipo ?? ""),
        lat_oneri: new FormControl(preventivo?.lat_oneri ?? ""),
        lat_detrazione: new FormControl(preventivo?.lat_detrazione ?? ""),
        lat_cessionario: new FormControl(preventivo?.lat_cessionario ? Number.parseInt(preventivo.lat_cessionario.toString()) : 0),
        lat_chiusura_pratica: new FormControl(preventivo?.lat_chiusura_pratica ?? ""),
        lat_range_min: new FormControl(preventivo?.lat_range_min ?? ""),
        lat_range_max: new FormControl(preventivo?.lat_range_max ?? ""),
        lat_costo_fisso: new FormControl(preventivo?.lat_costo_fisso ?? ""),
        lat_imp: new FormControl(preventivo?.lat_imp ?? ""),
        lat_costo_min: new FormControl(preventivo?.lat_costo_min ?? ""),
        lat_costo_max: new FormControl(preventivo?.lat_costo_max ?? ""),
        lat_oneri_att: new FormControl(preventivo?.lat_oneri_att ?? ""),
        lat_costo_asseverazione: new FormControl(preventivo?.lat_costo_asseverazione ?? "0"),
        lat_costo_visto: new FormControl(preventivo?.lat_costo_visto ?? "0"),
        lat_perc_asseverazione: new FormControl(preventivo?.lat_perc_asseverazione ?? "0"),
        lat_perc_visto: new FormControl(preventivo?.lat_perc_visto ?? "0"),
        prev_cessionario: new FormControl(preventivo?.prev_cessionario ?? 0),
        prev_tipo_detrazione: new FormControl(preventivo?.prev_tipo_detrazione ?? ""), //controllare
        prev_imp_asseverazione: new FormControl(preventivo?.prev_imp_asseverazione ?? 0), //controllare
        prev_pag_desc_1: new FormControl(preventivo?.prev_pag_desc_1 ?? ""),
        prev_pag_perc_1: new FormControl(preventivo?.prev_pag_perc_1 ?? ""),
        prev_pag_desc_2: new FormControl(preventivo?.prev_pag_desc_2 ?? ""),
        prev_pag_perc_2: new FormControl(preventivo?.prev_pag_perc_2 ?? ""),
        prev_pag_desc_3: new FormControl(preventivo?.prev_pag_desc_3 ?? ""),
        prev_pag_perc_3: new FormControl(preventivo?.prev_pag_perc_3 ?? ""),
        prev_consegna: new FormControl(preventivo?.prev_consegna ?? ""),
        prev_note: new FormControl(preventivo?.prev_note ?? ""),
        prev_preventivo: new FormGroup({
          imponibileFornitura: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).calcolo_massimali.imponibile : 0),
          massimaleMQdaApplicare: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).calcolo_massimali.massimale_mq : 0),
          massimaleMQdaApplicare_CON_CO: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).calcolo_massimali.massimale_mq_con_co : 0),
          costoMQImponibile: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).calcolo_massimali.costo_mq : 0),
          imponibileMassimoDetraibile: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).calcolo_massimali.imp_max_detaibile : 0),
          imponibileMassimoDetraibile_CON_CO: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).calcolo_massimali.imp_max_detaibile_con_co : 0),
          imponibileMassimoDetraibile_Totale: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).calcolo_massimali.imp_max_detaibile_totale : 0),
          importoNonDetraibile: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).calcolo_massimali.imp_non_detraibile : 0),
          manoOperaImponibile: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).preventivatore.mano_opera : 0),
          imponibileSerramentista: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).preventivatore.imponibile_serr : 0),
          speseTecniche: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).preventivatore.spese_tecniche : 0),
          nuovoImponibile: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).preventivatore.nuovo_imponibile : 0),
          totaleFatturaIva: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).preventivatore.tot_fattura_con_iva : 0),
          contributoSpettante: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).preventivatore.contributo : 0),
          ImportoLavoriCaricoCliente: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).preventivatore.imp_prev_cliente : 0),
          costiAttuazione: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).preventivatore.costi_attuazione : 0),
          totaleDovutoCliente: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).preventivatore.tot_dovuto_cliente : 0),
          posaOperaImponibile: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).fattura.posa_opera.imponibile : 0),
          posaOpera10: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).fattura.posa_opera.iva_10 : 0),
          posaOpera22: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).fattura.posa_opera.iva_22 : 0),
          posaOperaTot: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).fattura.posa_opera.tot : 0),
          altibeniaccImponibile: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).fattura.altri_beni_accessori.imponibile : 0),
          altibeniacc10: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).fattura.altri_beni_accessori.iva_10 : 0),
          altibeniacc22: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).fattura.altri_beni_accessori.iva_22 : 0),
          altibeniaccTot: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).fattura.altri_beni_accessori.tot : 0),
          valore10Imponibile: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).fattura.valore_vendita_infissi_10.imponibile : 0),
          valore1010: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).fattura.valore_vendita_infissi_10.iva_10 : 0),
          valore1022: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).fattura.valore_vendita_infissi_10.iva_22 : 0),
          valore10Tot: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).fattura.valore_vendita_infissi_10.tot : 0),
          valore22Imponibile: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).fattura.valore_vendita_infissi_22.imponibile : 0),
          valore2210: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).fattura.valore_vendita_infissi_22.iva_10 : 0),
          valore2222: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).fattura.valore_vendita_infissi_22.iva_22 : 0),
          valore22Tot: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).fattura.valore_vendita_infissi_22.tot : 0),
          altreOpereImponibile: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).fattura.altre_opere.imponibile : 0),
          altreOpere10: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).fattura.altre_opere.iva_10 : 0),
          altreOpere22: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).fattura.altre_opere.iva_22 : 0),
          altreOpereTot: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).fattura.altre_opere.tot : 0),
          prestProfImponibile: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).fattura.prestazioni_professionali.imponibile : 0),
          prestProf10: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).fattura.prestazioni_professionali.iva_10 : 0),
          prestProf22: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).fattura.prestazioni_professionali.iva_22 : 0),
          prestProfTot: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).fattura.prestazioni_professionali.tot : 0),
          ftImponibile: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).fattura.totale.imponibile : 0),
          ft10: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).fattura.totale.iva_10 : 0),
          ft22: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).fattura.totale.iva_22 : 0),
          ftTot: new FormControl(preventivo ? JSON.parse(`${preventivo.prev_preventivo}`).fattura.totale.tot : 0),
          CA_EsenteImponibile: new FormControl(0),
          CA_EsenteIva10: new FormControl(0),
          CA_EsenteIva22: new FormControl(0),
          CA_EsenteImporto: new FormControl(0),
          CA_10Imponibile: new FormControl(0),
          CA_10Iva10: new FormControl(0),
          CA_10Iva22: new FormControl(0),
          CA_10Totale: new FormControl(0),
          CA_22Imponibile: new FormControl(0),
          CA_22Iva10: new FormControl(0),
          CA_22Iva22: new FormControl(0),
          CA_22Totale: new FormControl(0),
          CAImponibile: new FormControl(0),
          CA10: new FormControl(0),
          CA22: new FormControl(0),
          CATot: new FormControl(0),
        }),
        prev_preventiviAttr: new FormGroup({
          paf_id: new FormControl(preventivo?.prev_preventiviAttr?.paf_id ?? "0"),
          paf_prev_id: new FormControl(preventivo?.prev_id ?? "0"),
          paf_imp_fornitore: new FormControl(preventivo?.prev_preventiviAttr?.paf_imp_fornitore ?? "0"),
          paf_imp_fornitura: new FormControl(preventivo?.prev_preventiviAttr?.paf_imp_fornitura ?? "0"),
          paf_costo_pratica: new FormControl(preventivo?.prev_preventiviAttr?.paf_costo_pratica ?? "0"),
          paf_nuovo_imponibile: new FormControl(preventivo?.prev_preventiviAttr?.paf_nuovo_imponibile ?? "0"),
          paf_iva: new FormControl(preventivo?.prev_preventiviAttr?.paf_iva ?? "0"),
          paf_tot_fattura: new FormControl(preventivo?.prev_preventiviAttr?.paf_tot_fattura ?? "0"),
          paf_imp_detraibile: new FormControl(preventivo?.prev_preventiviAttr?.paf_imp_detraibile ?? "0"),
          paf_imp_non_detraibile: new FormControl(preventivo?.prev_preventiviAttr?.paf_imp_non_detraibile ?? "0"),

          paf_fin_Tipo: new FormControl(preventivo?.prev_preventiviAttr?.paf_fin_Tipo ?? 'NO'),
          paf_fin_numero_rate: new FormControl(preventivo?.prev_preventiviAttr?.paf_fin_numero_rate ?? "0"),
          paf_fin_importo_rate: new FormControl(preventivo?.prev_preventiviAttr?.paf_fin_importo_rate ?? "0"),
          paf_fin_interessi: new FormControl(preventivo?.prev_preventiviAttr?.paf_fin_interessi ?? "0"),
          paf_fin_totale: new FormControl(preventivo?.prev_preventiviAttr?.paf_fin_totale ?? "0"),
          paf_fin_saldo: new FormControl(preventivo?.prev_preventiviAttr?.paf_fin_saldo ?? "0"),

          paf_re_tipo: new FormControl(preventivo?.prev_preventiviAttr?.paf_re_tipo ?? 'Gas'),
          paf_re_costomedio_elettrica: new FormControl(preventivo?.prev_preventiviAttr?.paf_re_costomedio_elettrica ?? "0.30"),
          paf_re_costomedio_gas: new FormControl(preventivo?.prev_preventiviAttr?.paf_re_costomedio_gas ?? "0.95"),
          paf_re_risparmio_elettrica: new FormControl(preventivo?.prev_preventiviAttr?.paf_re_risparmio_elettrica ?? "0"),
          paf_re_risparmio_gas: new FormControl(preventivo?.prev_preventiviAttr?.paf_re_risparmio_gas ?? "0"),
          paf_re_risparmio_annuo: new FormControl(preventivo?.prev_preventiviAttr?.paf_re_risparmio_annuo ?? "0"),
          paf_re_risparmio_tot10anni: new FormControl(preventivo?.prev_preventiviAttr?.paf_re_risparmio_tot10anni ?? "0"),
          paf_re_risparmio_tot30anni: new FormControl(preventivo?.prev_preventiviAttr?.paf_re_risparmio_tot30anni ?? "0"),

          paf_ba_co2_non_emessa: new FormControl(preventivo?.prev_preventiviAttr?.paf_ba_co2_non_emessa ?? "0"),
          paf_ba_consumo_auto: new FormControl(preventivo?.prev_preventiviAttr?.paf_ba_consumo_auto ?? "0"),
          paf_ba_nuovi_alberi: new FormControl(preventivo?.prev_preventiviAttr?.paf_ba_nuovi_alberi ?? "0"),

          paf_tot_costo_10anni: new FormControl(preventivo?.prev_preventiviAttr?.paf_tot_costo_10anni ?? "0"),
          paf_tot_costo_30anni: new FormControl(preventivo?.prev_preventiviAttr?.paf_tot_costo_30anni ?? "0"),

        }),
      }),
    });
}
