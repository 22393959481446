import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl, ValidationErrors, ControlContainer, } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Offerta, Preventivo } from 'src/app/_interfaces/lavori';
import {
    Catastale,
    TipoAgevolazione,
    TipoIntervento,
    TitolaritaImmobile,
    ZonaClimatica,
    ProdottiInstallati,
    CessionarioFiscale,
    InterventoStato
} from 'src/app/_interfaces/list-type';
import { LavoriService } from 'src/app/_services/lavori.service';
import { ListTypeService } from 'src/app/_services/list-type.service';
import { environment } from 'src/environments/environment';
import { AppBreadcrumbService } from 'src/app/app.breadcrumb.service';
import { MessageService } from 'primeng/api';
import { Utility } from 'src/app/_helpers/utility';
import { parseJSON } from 'date-fns';

@Component({
    selector: 'app-offerta',
    templateUrl: './offerta.component.html',
    styleUrls: ['./offerta.component.scss']
})

export class OffertaComponent implements OnInit {
    frmOfferta: FormGroup;
    environment = environment;
    offerta: Offerta;
    titImmobile: TitolaritaImmobile[];
    tipAgevolazioni: TipoAgevolazione[];
    tipoIntervento: TipoIntervento[];
    categorieCatastali: Catastale[];

    zonelimatiche: ZonaClimatica[] = [];
    filtroZonelimatiche: ZonaClimatica[];

    serramentoTipoProdotto: ProdottiInstallati[] = [];
    filtroTipoProdotto: ProdottiInstallati[];


    cessionariFiscali: CessionarioFiscale[];
    interventoStato: InterventoStato[];

    totaleFatturaIVA: number = 0;
    ivaPonderata: number = 0;
    lavoroCodice: string = '';
    ruoloUtente: string = "SE";
    chiusuraOscurante: { label: string; value: any }[] = [
        { label: 'SI', value: 1 },
        { label: 'NO', value: 0 },
        { label: "<=0.35", value: 2 }
    ];
    filtroChiusuraOscurante: { label: string; value: any }[] = this.chiusuraOscurante;

    AliquotaIvaPrestazioneOfferta: { label: string; value: any }[] = [
        { label: '10%', value: '10' },
        { label: '22%', value: '22' }
    ];

    AliquotaIvaOfferta: { label: string; value: any }[] = [
        { label: '0%', value: '0' },
        { label: '10%', value: '10' },
        { label: '10% / 22%', value: '1022' },
        { label: '22%', value: '22' }
    ];
    filtroAliquotaIvaOfferta: { label: string; value: any }[] = this.AliquotaIvaOfferta


    constructor(
        private offertaSrv: LavoriService,
        private router: Router,
        private route: ActivatedRoute,
        private listTypeSrv: ListTypeService,
        private msgService: MessageService,
        private breadcrumbService: AppBreadcrumbService) {
    }

    creaFormOfferta() {
        this.frmOfferta = new FormGroup({
            lav_id: new FormControl(''),
            lav_tipo: new FormControl(''),
            lav_user: new FormControl(''),
            lav_cliente: new FormControl(''),
            lav_abitazione: new FormControl(''),
            lav_titolarita: new FormControl(''),
            lav_codice: new FormControl(''),
            lav_descrizione: new FormControl('', Validators.required),
            lav_tipologia_intervento: new FormControl('', Validators.required),
            lav_tipologia_agevolazione: new FormControl('', Validators.required),
            lav_zona_climatica: new FormControl('', Validators.required),
            lav_prodotto_installato: new FormControl('', Validators.required),
            lav_mq_installati: new FormControl('', Validators.required),
            lav_transmittanza: new FormControl('', Validators.required),
            lav_iva_offerta: new FormControl('', Validators.required),
            lav_iva_prestazioni: new FormControl('', Validators.required),
            lav_pag_desc_1: new FormControl('', Validators.required),
            lav_pag_perc_1: new FormControl('', Validators.required),
            lav_pag_desc_2: new FormControl(''),
            lav_pag_perc_2: new FormControl(''),
            lav_pag_desc_3: new FormControl(''),
            lav_pag_perc_3: new FormControl(''),
            lav_consegna: new FormControl('90 giorni', Validators.required),
            lav_cessionario: new FormControl('', Validators.required),
            lav_vendita_infissi: new FormControl('', Validators.required),
            lav_beni_significativi: new FormControl('', Validators.required),
            lav_altri_beni: new FormControl('', Validators.required),
            lav_posa_opera: new FormControl('', Validators.required),
            lav_altre_opere: new FormControl('', Validators.required),
            lav_prestazioni_prof: new FormControl('', Validators.required),
            lav_chiusura: new FormControl(''),
            lav_stato: new FormControl(0),
            lav_cancellato: new FormControl(''),
            lav_note: new FormControl(''),
            lav_importo: new FormControl(0),           
            id: new FormControl(''),
            name: new FormControl(''),
            email: new FormControl(''),
            ruolo: new FormControl(''),
            abilitato: new FormControl(''),
            email_verified_at: new FormControl(''),
            password: new FormControl(''),
            remember_token: new FormControl(''),
            created_at: new FormControl(''),
            updated_at: new FormControl(''),
            cl_id: new FormControl(''),
            cl_serramentista: new FormControl(''),
            cl_tipo: new FormControl(''),
            cl_nome: new FormControl(''),
            cl_cognome: new FormControl(''),
            cl_data_nascita: new FormControl(''),
            cl_nazione_nascita: new FormControl(''),
            cl_regione_nascita: new FormControl(''),
            cl_citta_nascita: new FormControl(''),
            cl_provincia_nascita: new FormControl(''),
            cl_telefono: new FormControl(''),
            cl_email: new FormControl(''),
            cl_pec: new FormControl(''),
            cl_cf: new FormControl(''),
            cl_piva: new FormControl(''),
            cl_cancellato: new FormControl(''),
            cl_note: new FormControl(''),
            ab_id: new FormControl(''),
            ab_cliente: new FormControl(''),
            ab_provincia: new FormControl(''),
            ab_citta: new FormControl(''),
            ab_indirizzo: new FormControl(''),
            ab_civico: new FormControl(''),
            ab_cap: new FormControl(''),
            ab_codice_comune: new FormControl(''),
            ab_foglio: new FormControl(''),
            ab_mappale: new FormControl(''),
            ab_mq_immobile: new FormControl(''),
            ab_mq_infissi: new FormControl(''),
            ab_categoria_catastale: new FormControl(''),
            ab_subalterno: new FormControl(''),
            ab_titolarita: new FormControl(0),
            ab_cancellata: new FormControl(''),
            ab_data_aggiunta: new FormControl(''),
            ab_data_modifica: new FormControl(''),
            ti_id: new FormControl(''),
            ti_alias: new FormControl(''),
            ti_nome: new FormControl(''),
            tin_id: new FormControl(''),
            tin_alias: new FormControl(''),
            tin_nome: new FormControl(''),
            ta_id: new FormControl(''),
            ta_alias: new FormControl(''),
            ta_nome: new FormControl(''),
            zc_codice: new FormControl(''),
            pi_id: new FormControl(''),
            pi_alias: new FormControl(''),
            pi_nome: new FormControl(''),
            cf_id: new FormControl(''),
            cf_alias: new FormControl(''),
            cf_nome: new FormControl(''),
            lav_preventivo: new FormGroup({
                imponibileFornitura: new FormControl(0),
                massimaleMQdaApplicare: new FormControl(0),
                costoMQImponibile: new FormControl(0),
                imponibileMassimoDetraibile: new FormControl(0),
                importoNonDetraibile: new FormControl(0),
                manoOperaImponibile: new FormControl(0),
                imponibileSerramentista: new FormControl(0),
                speseTecniche: new FormControl(0),
                nuovoImponibile: new FormControl(0),
                totaleFatturaIva: new FormControl(0),
                contributoSpettante: new FormControl(0),
                ImportoLavoriCaricoCliente: new FormControl(0),
                importoNONDetraibileACaricoCliente: new FormControl(0),
                costiAttuazione: new FormControl(0),
                totaleDovutoCliente: new FormControl(0),
                posaOperaImponibile: new FormControl(0),
                posaOpera10: new FormControl(0),
                posaOpera22: new FormControl(0),
                posaOperaTot: new FormControl(0),
                altibeniaccImponibile: new FormControl(0),
                altibeniacc10: new FormControl(0),
                altibeniacc22: new FormControl(0),
                altibeniaccTot: new FormControl(0),
                valore10Imponibile: new FormControl(0),
                valore1010: new FormControl(0),
                valore1022: new FormControl(0),
                valore10Tot: new FormControl(0),
                valore22Imponibile: new FormControl(0),
                valore2210: new FormControl(0),
                valore2222: new FormControl(0),
                valore22Tot: new FormControl(0),
                altreOpereImponibile: new FormControl(0),
                altreOpere10: new FormControl(0),
                altreOpere22: new FormControl(0),
                altreOpereTot: new FormControl(0),
                prestProfImponibile: new FormControl(0),
                prestProf10: new FormControl(0),
                prestProf22: new FormControl(0),
                prestProfTot: new FormControl(0),
                ftImponibile: new FormControl(0),
                ft10: new FormControl(0),
                ft22: new FormControl(0),
                ftTot: new FormControl(0),
            }),
        });
    }

    ngOnInit(): void {
        this.breadcrumbService.setItems([
            { label: 'Offerta' },
        ]);
        this.ruoloUtente = sessionStorage.getItem('ruolo');
        this.creaFormOfferta();
        this.refreshTitolariImmobili();
        this.refreshTipologieAgevolazioni();
        this.refreshTipoloInterventi();
        this.refreshCategorieCatastali();
        this.refreshZoneClimatiche();
        this.refreshProdottiInstallati();
        this.refreshCessionarioFinale();
        this.refreshInterventoStati();
        this.refreshOfferta(this.route.snapshot.paramMap.get('id') ?? '0');
        if (this.frmOfferta.controls.lav_tipo.value === 'intervento') {
            this.frmOfferta.controls.lav_consegna.setValue('90 giorni');
            this.frmOfferta.controls.lav_cessionario.setValue('1');
            this.frmOfferta.controls.lav_stato.setValue('1');
        }
        Utility.updateFormValidity(this.frmOfferta);
    }

    refreshOfferta(id: string): void {
        this.offertaSrv.getInterventoID(id).subscribe((res) => {
            this.offerta = res.data;
            this.lavoroCodice = res.data.lav_codice;
            this.frmOfferta.patchValue(res.data);
            this.FiltraTipologiaIntervento(this.frmOfferta.controls.lav_tipologia_intervento.value)
            let prObj: Preventivo = JSON.parse(res.data.lav_preventivo.toString());

            this.frmOfferta.get('lav_preventivo.imponibileFornitura').setValue(prObj.imponibileFornitura);
            this.frmOfferta.get('lav_preventivo.massimaleMQdaApplicare').setValue(prObj.massimaleMQdaApplicare);
            this.frmOfferta.get('lav_preventivo.costoMQImponibile').setValue(prObj.costoMQImponibile);
            this.frmOfferta.get('lav_preventivo.imponibileMassimoDetraibile').setValue(prObj.imponibileMassimoDetraibile);
            this.frmOfferta.get('lav_preventivo.importoNonDetraibile').setValue(prObj.importoNonDetraibile);
            this.frmOfferta.get('lav_preventivo.manoOperaImponibile').setValue(prObj.manoOperaImponibile);
            this.frmOfferta.get('lav_preventivo.imponibileSerramentista').setValue(prObj.imponibileSerramentista);
            this.frmOfferta.get('lav_preventivo.speseTecniche').setValue(prObj.speseTecniche);
            this.frmOfferta.get('lav_preventivo.nuovoImponibile').setValue(prObj.nuovoImponibile);
            this.frmOfferta.get('lav_preventivo.totaleFatturaIva').setValue(prObj.totaleFatturaIva);
            this.frmOfferta.get('lav_preventivo.contributoSpettante').setValue(prObj.contributoSpettante);
            this.frmOfferta.get('lav_preventivo.ImportoLavoriCaricoCliente').setValue(prObj.ImportoLavoriCaricoCliente);
            this.frmOfferta.get('lav_preventivo.importoNONDetraibileACaricoCliente').setValue(prObj.importoNONDetraibileACaricoCliente);
            this.frmOfferta.get('lav_preventivo.costiAttuazione').setValue(prObj.costiAttuazione);
            this.frmOfferta.get('lav_preventivo.totaleDovutoCliente').setValue(prObj.totaleDovutoCliente);
            this.frmOfferta.get('lav_preventivo.posaOperaImponibile').setValue(prObj.posaOperaImponibile);
            this.frmOfferta.get('lav_preventivo.posaOpera10').setValue(prObj.posaOpera10);
            this.frmOfferta.get('lav_preventivo.posaOpera22').setValue(prObj.posaOpera22);
            this.frmOfferta.get('lav_preventivo.posaOperaTot').setValue(prObj.posaOperaTot);
            this.frmOfferta.get('lav_preventivo.altibeniaccImponibile').setValue(prObj.altibeniaccImponibile);
            this.frmOfferta.get('lav_preventivo.altibeniacc10').setValue(prObj.altibeniacc10);
            this.frmOfferta.get('lav_preventivo.altibeniacc22').setValue(prObj.altibeniacc22);
            this.frmOfferta.get('lav_preventivo.altibeniaccTot').setValue(prObj.altibeniaccTot);
            this.frmOfferta.get('lav_preventivo.valore10Imponibile').setValue(prObj.valore10Imponibile);
            this.frmOfferta.get('lav_preventivo.valore1010').setValue(prObj.valore1010);
            this.frmOfferta.get('lav_preventivo.valore1022').setValue(prObj.valore1022);
            this.frmOfferta.get('lav_preventivo.valore10Tot').setValue(prObj.valore10Tot);
            this.frmOfferta.get('lav_preventivo.valore22Imponibile').setValue(prObj.valore22Imponibile);
            this.frmOfferta.get('lav_preventivo.valore2210').setValue(prObj.valore2210);
            this.frmOfferta.get('lav_preventivo.valore2222').setValue(prObj.valore2222);
            this.frmOfferta.get('lav_preventivo.valore22Tot').setValue(prObj.valore22Tot);
            this.frmOfferta.get('lav_preventivo.altreOpereImponibile').setValue(prObj.altreOpereImponibile);
            this.frmOfferta.get('lav_preventivo.altreOpere10').setValue(prObj.altreOpere10);
            this.frmOfferta.get('lav_preventivo.altreOpere22').setValue(prObj.altreOpere22);
            this.frmOfferta.get('lav_preventivo.altreOpereTot').setValue(prObj.altreOpereTot);
            this.frmOfferta.get('lav_preventivo.prestProfImponibile').setValue(prObj.prestProfImponibile);
            this.frmOfferta.get('lav_preventivo.prestProf10').setValue(prObj.prestProf10);
            this.frmOfferta.get('lav_preventivo.prestProf22').setValue(prObj.prestProf22);
            this.frmOfferta.get('lav_preventivo.prestProfTot').setValue(prObj.prestProfTot);
            this.frmOfferta.get('lav_preventivo.ftImponibile').setValue(prObj.ftImponibile);
            this.frmOfferta.get('lav_preventivo.ft10').setValue(prObj.ft10);
            this.frmOfferta.get('lav_preventivo.ft22').setValue(prObj.ft22);
            this.frmOfferta.get('lav_preventivo.ftTot').setValue(prObj.ftTot);
        });
    }

    refreshTitolariImmobili(): void {
        this.listTypeSrv.getTitolaritaImmobile().subscribe((res) => {
            this.titImmobile = [...res.data];
        });
    }

    refreshTipologieAgevolazioni(): void {
        this.listTypeSrv.getTipoAgevolazione().subscribe((res) => {
            this.tipAgevolazioni = [...res.data];
        });
    }

    refreshTipoloInterventi(): void {
        this.listTypeSrv.getTipoInterventi().subscribe((res) => {
            this.tipoIntervento = [...res.data];
        });
    }

    refreshCategorieCatastali(): void {
        this.listTypeSrv.getCatastale().subscribe((res) => {
            this.categorieCatastali = [...res.data];
        });
    }

    refreshZoneClimatiche(): void {
        this.listTypeSrv.getZonaClimatica().subscribe((res) => {
            this.zonelimatiche = [...res.data];
            this.filtroZonelimatiche = [...res.data];
        });
    }

    refreshProdottiInstallati(): void {
        this.listTypeSrv.getProdottiInstallati().subscribe((res) => {
            this.serramentoTipoProdotto = [...res.data];
            this.filtroTipoProdotto = [...res.data];
        });
    }

    refreshCessionarioFinale(): void {
        this.listTypeSrv.getCessionariFiscali().subscribe((res) => {
            this.cessionariFiscali = [...res.data];
        });
    }

    refreshInterventoStati(): void {
        this.listTypeSrv.getInterventoStati().subscribe((res) => {
            this.interventoStato = [...res.data];
        });
    }

    Trasmittanza(ev): void {
        if (ev.value === 'A' || ev.value === 'B') {
            this.frmOfferta.controls.lav_transmittanza.setValue('<= a 2,60 W/m2k');
        } else if (ev.value === 'C') {
            this.frmOfferta.controls.lav_transmittanza.setValue('<= a 1,75 W/m2k');
        } else if (ev.value === 'D') {
            this.frmOfferta.controls.lav_transmittanza.setValue('<= a 1,67 W/m2k');
        } else if (ev.value === 'E') {
            this.frmOfferta.controls.lav_transmittanza.setValue('<= a 1,30 W/m2k');
        } else if (ev.value === "F") {
            this.frmOfferta.controls.lav_transmittanza.setValue("<= a 1,00 W/m2k");
        }
        else {
            this.frmOfferta.controls.lav_transmittanza.setValue("0,08 Km2/w <= Rsup <= 0,455 Km2/w");
        }
    }

    submit(): void {

        if (!this.VerificaValidazioneForm()) { return };

        this.frmOfferta.controls.lav_tipo.setValue('offerta');
        this.frmOfferta.controls.lav_stato.setValue('1');

        this.offertaSrv.addIntervento(this.frmOfferta.value).subscribe((res) => {
            this.msgService.add({
                key: 'tst',
                severity: res.res === 'ok' ? 'success' : 'error',
                summary: `Offerta`,
                detail: res.res === 'ok' ? 'Offerta Salvata' : 'Errore in fase di Salvataggio',
            });
            this.router.navigate(['offerte']);
        });
    }

    VerificaValidazioneForm(): boolean {
        if (this.frmOfferta.controls.lav_tipologia_intervento.value === 1) {
            if ((Number(this.frmOfferta.controls.lav_beni_significativi.value) > 0) && (Number(this.frmOfferta.controls.lav_beni_significativi.value) > Number(this.frmOfferta.controls.lav_vendita_infissi.value))) {
                this.msgService.add({
                    key: 'tst',
                    severity: 'error',
                    summary: `ERRORE`,
                    detail: 'Attenzione, il valore dei beni significativi deve essere < o = al valore di vendita infissi',
                });
                return false;
            }
        } else if (this.frmOfferta.controls.lav_tipologia_intervento.value === 2) {
            if (Number(this.frmOfferta.controls.lav_vendita_infissi.value) <= 0) {
                this.msgService.add({
                    key: 'tst',
                    severity: 'error',
                    summary: `ERRORE`,
                    detail: 'Attenzione, il valore Valore di vendita schermature solari deve essere > 0',
                });
                return false;;
            }
        } else if (this.frmOfferta.controls.lav_tipologia_intervento.value === 5) {
            if (Number(this.frmOfferta.controls.lav_beni_significativi.value) <= 0) {
                this.msgService.add({
                    key: 'tst',
                    severity: 'error',
                    summary: `ERRORE`,
                    detail: 'Attenzione, il valore di vendita chiusure oscuranti tecniche deve essere > 0',
                });
                return false;;
            }
        }
        if (Number(this.frmOfferta.controls.lav_posa_opera.value) === 0) {
            this.msgService.add({
                key: 'tst',
                severity: 'error',
                summary: `ERRORE`,
                detail: 'Attenzione, è obbligatorio valorizzare la posa in opera',
            });
            return false;;
        }
        if (this.frmOfferta.controls.lav_importo.value === 0) {
            this.offertaSrv.addIntervento(this.frmOfferta.value).subscribe((res) => {
                this.msgService.add({
                    key: 'tst',
                    severity: 'error',
                    summary: `Importo Offerta`,
                    detail: 'Importo offerta NON Calcolato',
                });
                this.router.navigate(['offerte']);
            });
            return false;;
        }
        return true;
    }

    FiltraTipologiaIntervento(idTipInt: number): void {
        if (idTipInt === 1) {
            this.filtroChiusuraOscurante = this.chiusuraOscurante.filter((f) => f.value < 2);
            this.filtroZonelimatiche = this.zonelimatiche.filter((f) => f.zc_codice === "A" || f.zc_codice === "B" || f.zc_codice === "C" || f.zc_codice === "D" || f.zc_codice === "E" || f.zc_codice === "F");
            this.filtroTipoProdotto = this.serramentoTipoProdotto.filter((f) => f.pi_id <= 2);
            this.filtroAliquotaIvaOfferta = this.AliquotaIvaOfferta;
        } else {
            this.filtroChiusuraOscurante = this.chiusuraOscurante.filter((f) => f.value === 2);
            this.filtroZonelimatiche = this.zonelimatiche.filter((f) => f.zc_codice != "A" && f.zc_codice != "B" && f.zc_codice != "C" && f.zc_codice != "D" && f.zc_codice != "E" && f.zc_codice != "F");
            this.filtroTipoProdotto = this.serramentoTipoProdotto.filter((f) => f.pi_id > 2);
            this.filtroAliquotaIvaOfferta = this.AliquotaIvaOfferta.filter((f) => f.value != '1022');
        }

    }


    CalcolaPreventivo(): void {
        if (!this.VerificaValidazioneForm()) { return };

        if (this.frmOfferta.controls.lav_tipologia_intervento.value === 1) {
            this.CalcolaPreventivo_SSI();
        } else {
            this.CalcolaPreventivo_SSCO();
        }
    }
    //INIZIO CALCOLO SSI
    CalcolaPreventivo_SSI(): void {

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['manoOperaImponibile'].setValue(
            Number(this.frmOfferta.controls.lav_posa_opera.value) +
            Number(this.frmOfferta.controls.lav_vendita_infissi.value) -
            Number(this.frmOfferta.controls.lav_beni_significativi.value)
        );

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['imponibileSerramentista'].setValue(
            Number(this.frmOfferta.controls.lav_vendita_infissi.value) +
            Number(this.frmOfferta.controls.lav_posa_opera.value) +
            Number(this.frmOfferta.controls.lav_altri_beni.value) +
            Number(this.frmOfferta.controls.lav_altre_opere.value) +
            Number(this.frmOfferta.controls.lav_prestazioni_prof.value)
        );

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['speseTecniche'].setValue(
            ((this.frmOfferta.get('lav_preventivo') as FormGroup).get('imponibileSerramentista').value * 7.5) / 100
        );

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['nuovoImponibile'].setValue(
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('imponibileSerramentista').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('speseTecniche').value
        );


        // Calcolo Iva Ponderata 10 %
        let IvaPonderata10: number = 0;
        if (this.frmOfferta.controls.lav_iva_prestazioni.value == '22' && (((
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('manoOperaImponibile').value +
            Number(this.frmOfferta.controls.lav_altri_beni.value) +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('speseTecniche').value +
            Number(this.frmOfferta.controls.lav_altre_opere.value)) * 2) /
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('nuovoImponibile').value) > 1
        ) {
            IvaPonderata10 = 1 - Number(this.frmOfferta.controls.lav_prestazioni_prof.value) /
                (this.frmOfferta.get('lav_preventivo') as FormGroup).get('nuovoImponibile').value;
        } else if (this.frmOfferta.controls.lav_iva_prestazioni.value == '22') {
            IvaPonderata10 = (((this.frmOfferta.get('lav_preventivo') as FormGroup).get('manoOperaImponibile').value +
                Number(this.frmOfferta.controls.lav_altri_beni.value) +
                (this.frmOfferta.get('lav_preventivo') as FormGroup).get('speseTecniche').value +
                Number(this.frmOfferta.controls.lav_altre_opere.value)) * 2) /
                (this.frmOfferta.get('lav_preventivo') as FormGroup).get('nuovoImponibile').value;
        } else if (
            ((((this.frmOfferta.get('lav_preventivo') as FormGroup).get('manoOperaImponibile').value +
                Number(this.frmOfferta.controls.lav_altri_beni.value) +
                (this.frmOfferta.get('lav_preventivo') as FormGroup).get('speseTecniche').value +
                Number(this.frmOfferta.controls.lav_altre_opere.value) +
                Number(this.frmOfferta.controls.lav_prestazioni_prof.value)) * 2) /
                (this.frmOfferta.get('lav_preventivo') as FormGroup).get('nuovoImponibile').value) > 1
        ) {
            IvaPonderata10 = 1;
        } else {
            IvaPonderata10 = (((this.frmOfferta.get('lav_preventivo') as FormGroup).get('manoOperaImponibile').value +
                Number(this.frmOfferta.controls.lav_altri_beni.value) +
                (this.frmOfferta.get('lav_preventivo') as FormGroup).get('speseTecniche').value +
                Number(this.frmOfferta.controls.lav_altre_opere.value) +
                Number(this.frmOfferta.controls.lav_prestazioni_prof.value)) * 2) /
                (this.frmOfferta.get('lav_preventivo') as FormGroup).get('nuovoImponibile').value;
        }
        ;

        // Calcolo Iva Ponderata 22 %
        let IvaPonderata22: number = 0;
        if (this.frmOfferta.controls.lav_iva_prestazioni.value == '22' && ((
            Number(this.frmOfferta.controls.lav_beni_significativi.value) -
            Number(this.frmOfferta.controls.lav_altri_beni.value) -
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('manoOperaImponibile').value -
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('speseTecniche').value -
            Number(this.frmOfferta.controls.lav_altre_opere.value) +
            Number(this.frmOfferta.controls.lav_prestazioni_prof.value)) /
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('nuovoImponibile').value) <= 0
        ) {
            IvaPonderata22 = Number(this.frmOfferta.controls.lav_prestazioni_prof.value) /
                (this.frmOfferta.get('lav_preventivo') as FormGroup).get('nuovoImponibile').value;
        } else if (this.frmOfferta.controls.lav_iva_prestazioni.value == '22') {
            IvaPonderata22 = ((Number(this.frmOfferta.controls.lav_beni_significativi.value) -
                Number(this.frmOfferta.controls.lav_altri_beni.value) -
                (this.frmOfferta.get('lav_preventivo') as FormGroup).get('manoOperaImponibile').value -
                (this.frmOfferta.get('lav_preventivo') as FormGroup).get('speseTecniche').value -
                Number(this.frmOfferta.controls.lav_altre_opere.value)) /
                (this.frmOfferta.get('lav_preventivo') as FormGroup).get('nuovoImponibile').value) +
                (Number(this.frmOfferta.controls.lav_prestazioni_prof.value) / (this.frmOfferta.get('lav_preventivo') as FormGroup).get('nuovoImponibile').value);
        } else if (((Number(this.frmOfferta.controls.lav_beni_significativi.value) -
            Number(this.frmOfferta.controls.lav_altri_beni.value) -
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('manoOperaImponibile').value -
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('speseTecniche').value -
            Number(this.frmOfferta.controls.lav_altre_opere.value) -
            Number(this.frmOfferta.controls.lav_prestazioni_prof.value)) /
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('nuovoImponibile').value) <= 0
        ) {
            IvaPonderata22 = 0;
        } else {
            IvaPonderata22 = ((Number(this.frmOfferta.controls.lav_beni_significativi.value) -
                Number(this.frmOfferta.controls.lav_altri_beni.value) -
                (this.frmOfferta.get('lav_preventivo') as FormGroup).get('manoOperaImponibile').value -
                (this.frmOfferta.get('lav_preventivo') as FormGroup).get('speseTecniche').value -
                Number(this.frmOfferta.controls.lav_altre_opere.value) -
                Number(this.frmOfferta.controls.lav_prestazioni_prof.value)) /
                (this.frmOfferta.get('lav_preventivo') as FormGroup).get('nuovoImponibile').value);
        }

        this.ivaPonderata = 0
        if (IvaPonderata22 <= 0) {
            this.ivaPonderata = 10;
        } else {
            this.ivaPonderata = ((IvaPonderata10 * 10) / 100) + ((IvaPonderata22 * 22) / 100);
        }

        if (this.frmOfferta.controls.lav_iva_offerta.value === '0') {
            this.totaleFatturaIVA = (this.frmOfferta.get('lav_preventivo') as FormGroup).get('nuovoImponibile').value;
        } else if (this.frmOfferta.controls.lav_iva_offerta.value === '10') {
            this.totaleFatturaIVA = ((this.frmOfferta.get('lav_preventivo') as FormGroup).get('nuovoImponibile').value * 1.1);
        } else if (this.frmOfferta.controls.lav_iva_offerta.value === '22') {
            this.totaleFatturaIVA = ((this.frmOfferta.get('lav_preventivo') as FormGroup).get('nuovoImponibile').value * 1.22);
        } else {
            if (this.ivaPonderata != 10) {
                this.ivaPonderata = this.ivaPonderata * 100;
            }
            this.ivaPonderata = 100 + this.ivaPonderata;
            this.totaleFatturaIVA = ((this.frmOfferta.get('lav_preventivo') as FormGroup).get('nuovoImponibile').value * this.ivaPonderata) / 100;
        }

        this.frmOfferta.controls.lav_importo.setValue(this.totaleFatturaIVA);
        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['totaleFatturaIva'].setValue(this.totaleFatturaIVA);

        this.CalcoloMassimali_SSI();

        if (this.frmOfferta.controls.cl_tipo.value === 'fisica') {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['ImportoLavoriCaricoCliente'].setValue(
                ((this.totaleFatturaIVA - (this.frmOfferta.get('lav_preventivo') as FormGroup).get('importoNonDetraibile').value) * 50) / 100
            );
        } else {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['ImportoLavoriCaricoCliente'].setValue(
                (((this.frmOfferta.get('lav_preventivo') as FormGroup).get('nuovoImponibile').value -
                    (this.frmOfferta.get('lav_preventivo') as FormGroup).get('importoNonDetraibile').value) * 50) / 100
            );
        }
        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['contributoSpettante'].setValue(
            0 - (this.frmOfferta.get('lav_preventivo') as FormGroup).get('ImportoLavoriCaricoCliente').value);


        if (this.frmOfferta.controls.cl_tipo.value === 'giuridica') {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['importoNONDetraibileACaricoCliente'].setValue(
                (this.totaleFatturaIVA - (this.frmOfferta.get('lav_preventivo') as FormGroup).get('nuovoImponibile').value) +
                (this.frmOfferta.get('lav_preventivo') as FormGroup).get('importoNonDetraibile').value
            );
        } else {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['importoNONDetraibileACaricoCliente'].setValue((this.frmOfferta.get('lav_preventivo') as FormGroup).get('importoNonDetraibile').value);
        }

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['costiAttuazione'].setValue(((0 - (this.frmOfferta.get('lav_preventivo') as FormGroup).get('contributoSpettante').value) * 10) / 100);
        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['totaleDovutoCliente'].setValue(
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('ImportoLavoriCaricoCliente').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('costiAttuazione').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('importoNONDetraibileACaricoCliente').value
        );

        this.CalcoloFattura_SSI();
    }
    CalcoloMassimali_SSI(): void {
        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['imponibileFornitura'].setValue(
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('nuovoImponibile').value -
            Number(this.frmOfferta.controls.lav_posa_opera.value) -
            Number(this.frmOfferta.controls.lav_prestazioni_prof.value) -
            Number(this.frmOfferta.controls.lav_altre_opere.value)
        );

        if (this.frmOfferta.controls.lav_chiusura.value === 0 &&
            (this.frmOfferta.controls.lav_zona_climatica.value === 'A' ||
                this.frmOfferta.controls.lav_zona_climatica.value === 'B' ||
                this.frmOfferta.controls.lav_zona_climatica.value === 'C')) {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['massimaleMQdaApplicare'].setValue(550.00);
        } else if (this.frmOfferta.controls.lav_chiusura.value === 1 &&
            (this.frmOfferta.controls.lav_zona_climatica.value === 'D' ||
                this.frmOfferta.controls.lav_zona_climatica.value === 'E' ||
                this.frmOfferta.controls.lav_zona_climatica.value === 'F')) {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['massimaleMQdaApplicare'].setValue(750.00);
        } else {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['massimaleMQdaApplicare'].setValue(650.00);
        }

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['costoMQImponibile'].setValue(
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('imponibileFornitura').value /
            Number(this.frmOfferta.controls.lav_mq_installati.value));

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['imponibileMassimoDetraibile'].setValue(
            Number(this.frmOfferta.controls.lav_mq_installati.value) *
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('massimaleMQdaApplicare').value
        );


        if ((-1 * ((this.frmOfferta.get('lav_preventivo') as FormGroup).get('imponibileFornitura').value -
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('imponibileMassimoDetraibile').value)) > 0) {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['importoNonDetraibile'].setValue(0);
        } else if (this.frmOfferta.controls.cl_tipo.value === 'giuridica' || this.frmOfferta.controls.lav_iva_offerta.value == '0') {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['importoNonDetraibile'].setValue(
                (this.frmOfferta.get('lav_preventivo') as FormGroup).get('imponibileFornitura').value -
                (this.frmOfferta.get('lav_preventivo') as FormGroup).get('imponibileMassimoDetraibile').value);
        } else if ((this.frmOfferta.controls.lav_iva_offerta.value == '10')) {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['importoNonDetraibile'].setValue(
                ((this.frmOfferta.get('lav_preventivo') as FormGroup).get('imponibileFornitura').value -
                    (this.frmOfferta.get('lav_preventivo') as FormGroup).get('imponibileMassimoDetraibile').value) * 1.1);
        } else if ((this.frmOfferta.controls.lav_iva_offerta.value == '22')) {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['importoNonDetraibile'].setValue(
                ((this.frmOfferta.get('lav_preventivo') as FormGroup).get('imponibileFornitura').value -
                    (this.frmOfferta.get('lav_preventivo') as FormGroup).get('imponibileMassimoDetraibile').value) * 1.22);
        } else {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['importoNonDetraibile'].setValue(
                (((this.frmOfferta.get('lav_preventivo') as FormGroup).get('imponibileFornitura').value -
                    (this.frmOfferta.get('lav_preventivo') as FormGroup).get('imponibileMassimoDetraibile').value) * this.ivaPonderata) / 100);
        }


    }
    CalcoloFattura_SSI(): void {

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['posaOperaImponibile'].setValue(Number(this.frmOfferta.controls.lav_posa_opera.value));
        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['posaOpera10'].setValue(this.FatturaIva10_SSI((this.frmOfferta.get('lav_preventivo') as FormGroup).get('posaOperaImponibile').value));
        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['posaOpera22'].setValue(this.FatturaIva22_SSI((this.frmOfferta.get('lav_preventivo') as FormGroup).get('posaOperaImponibile').value));
        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['posaOperaTot'].setValue(
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('posaOperaImponibile').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('posaOpera10').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('posaOpera22').value
        );

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['altibeniaccImponibile'].setValue(
            (Number(this.frmOfferta.controls.lav_altri_beni.value) == 0
                ?
                0
                :
                Number(this.frmOfferta.controls.lav_altri_beni.value) + ((this.frmOfferta.get('lav_preventivo') as FormGroup).get('speseTecniche').value / 2))
        );
        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['altibeniacc10'].setValue(this.FatturaIva10_SSI((this.frmOfferta.get('lav_preventivo') as FormGroup).get('altibeniaccImponibile').value));
        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['altibeniacc22'].setValue(this.FatturaIva22_SSI((this.frmOfferta.get('lav_preventivo') as FormGroup).get('altibeniaccImponibile').value));
        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['altibeniaccTot'].setValue(
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('altibeniaccImponibile').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('altibeniacc10').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('altibeniacc22').value
        );


        if ((this.frmOfferta.controls.lav_iva_offerta.value == '0' || this.frmOfferta.controls.lav_iva_offerta.value == '22') &&
            Number(this.frmOfferta.controls.lav_altri_beni.value) == 0
        ) {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['valore22Imponibile'].setValue(
                Number(this.frmOfferta.controls.lav_vendita_infissi.value) +
                (this.frmOfferta.get('lav_preventivo') as FormGroup).get('speseTecniche').value);
        } else if (this.frmOfferta.controls.lav_iva_offerta.value == '0' || this.frmOfferta.controls.lav_iva_offerta.value == '22') {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['valore22Imponibile'].setValue(
                Number(this.frmOfferta.controls.lav_vendita_infissi.value) + (((this.frmOfferta.get('lav_preventivo') as FormGroup).get('speseTecniche').value) / 2));
        } else if (this.frmOfferta.controls.lav_iva_offerta.value == '10' ||
            (Number(this.frmOfferta.controls.lav_beni_significativi.value -
                Number(this.frmOfferta.controls.lav_altri_beni.value) -
                (this.frmOfferta.get('lav_preventivo') as FormGroup).get('manoOperaImponibile').value -
                (this.frmOfferta.get('lav_preventivo') as FormGroup).get('speseTecniche').value -
                Number(this.frmOfferta.controls.lav_altre_opere.value) -
                Number(this.frmOfferta.controls.lav_prestazioni_prof.value))) <= 0) {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['valore22Imponibile'].setValue(0);
        } else if (Number(this.frmOfferta.controls.lav_iva_prestazioni.value) == 22) {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['valore22Imponibile'].setValue(
                Number(this.frmOfferta.controls.lav_beni_significativi.value) -
                Number(this.frmOfferta.controls.lav_altri_beni.value) -
                (this.frmOfferta.get('lav_preventivo') as FormGroup).get('manoOperaImponibile').value -
                (this.frmOfferta.get('lav_preventivo') as FormGroup).get('speseTecniche').value -
                Number(this.frmOfferta.controls.lav_altre_opere.value));
        } else {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['valore22Imponibile'].setValue(
                Number(this.frmOfferta.controls.lav_beni_significativi.value) -
                Number(this.frmOfferta.controls.lav_altri_beni.value) -
                (this.frmOfferta.get('lav_preventivo') as FormGroup).get('manoOperaImponibile').value -
                (this.frmOfferta.get('lav_preventivo') as FormGroup).get('speseTecniche').value -
                Number(this.frmOfferta.controls.lav_altre_opere.value) -
                Number(this.frmOfferta.controls.lav_prestazioni_prof.value));
        }

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['valore2210'].setValue(0);
        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['valore2222'].setValue(
            (this.frmOfferta.controls.lav_iva_offerta.value == '0'
                ?
                0
                :
                ((this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore22Imponibile').value * 22) / 100)
        );
        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['valore22Tot'].setValue(
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore22Imponibile').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore2210').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore2222').value);


        if (this.frmOfferta.controls.lav_iva_offerta.value == '0' || this.frmOfferta.controls.lav_iva_offerta.value == '22') {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['valore10Imponibile'].setValue(0);
        } else if (Number(this.frmOfferta.controls.lav_altri_beni.value) == 0) {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['valore10Imponibile'].setValue(Number(this.frmOfferta.controls.lav_vendita_infissi.value) + (this.frmOfferta.get('lav_preventivo') as FormGroup).get('speseTecniche').value - (this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore22Imponibile').value);
        } else {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['valore10Imponibile'].setValue((0 - (this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore22Imponibile').value) + Number(this.frmOfferta.controls.lav_vendita_infissi.value) + ((this.frmOfferta.get('lav_preventivo') as FormGroup).get('speseTecniche').value / 2));
        }

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['valore1010'].setValue(((this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore10Imponibile').value * 10) / 100);
        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['valore1022'].setValue(0);
        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['valore10Tot'].setValue(
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore10Imponibile').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore1010').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore1022').value
        );

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['altreOpereImponibile'].setValue(Number(this.frmOfferta.controls.lav_altre_opere.value));
        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['altreOpere10'].setValue(this.FatturaIva10_SSI((this.frmOfferta.get('lav_preventivo') as FormGroup).get('altreOpereImponibile').value));
        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['altreOpere22'].setValue(this.FatturaIva22_SSI((this.frmOfferta.get('lav_preventivo') as FormGroup).get('altreOpereImponibile').value));
        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['altreOpereTot'].setValue(
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('altreOpereImponibile').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('altreOpere10').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('altreOpere22').value
        );

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['prestProfImponibile'].setValue(
            Number(this.frmOfferta.controls.lav_prestazioni_prof.value));

        if (this.frmOfferta.controls.lav_iva_offerta.value === '10') {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['prestProf10'].setValue(
                ((this.frmOfferta.get('lav_preventivo') as FormGroup).get('prestProfImponibile').value * 10) / 100);
        } else if (this.frmOfferta.controls.lav_iva_offerta.value === '0' ||
            this.frmOfferta.controls.lav_iva_offerta.value === '22' ||
            this.frmOfferta.controls.lav_iva_prestazioni.value === '22') {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['prestProf10'].setValue(0);
        } else {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['prestProf10'].setValue(
                ((this.frmOfferta.get('lav_preventivo') as FormGroup).get('prestProfImponibile').value * 10) / 100);
        }

        if (this.frmOfferta.controls.lav_iva_offerta.value === '0' ||
            this.frmOfferta.controls.lav_iva_offerta.value === '10') {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['prestProf22'].setValue(0);
        } else if (this.frmOfferta.controls.lav_iva_offerta.value === '22' ||
            this.frmOfferta.controls.lav_iva_prestazioni.value === '22') {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['prestProf22'].setValue(
                ((this.frmOfferta.get('lav_preventivo') as FormGroup).get('prestProfImponibile').value * 22) / 100);
        } else {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['prestProf22'].setValue(0);
        }

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['prestProfTot'].setValue(
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('prestProfImponibile').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('prestProf10').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('prestProf22').value
        );
        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['ftImponibile'].setValue(
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('posaOperaImponibile').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('altibeniaccImponibile').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore10Imponibile').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore22Imponibile').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('altreOpereImponibile').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('prestProfImponibile').value);

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['ft10'].setValue(
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('posaOpera10').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('altibeniacc10').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore1010').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore2210').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('altreOpere10').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('prestProf10').value);

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['ft22'].setValue(
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('posaOpera22').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('altibeniacc22').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore1022').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore2222').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('altreOpere22').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('prestProf22').value);

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['ftTot'].setValue(
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('posaOperaTot').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('altibeniaccTot').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore10Tot').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore22Tot').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('altreOpereTot').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('prestProfTot').value);
    }
    FatturaIva10_SSI(importo: number) {
        if (this.frmOfferta.controls.lav_iva_offerta.value == '0' || this.frmOfferta.controls.lav_iva_offerta.value == '22') {
            return 0;
        } else {
            return (importo * 10) / 100;
        }
    }
    FatturaIva22_SSI(importo: number) {
        if (this.frmOfferta.controls.lav_iva_offerta.value == '22') {
            return (importo * 22) / 100;
        } else {
            return 0;
        }
    }
    //FINE CALCOLO SSI

    //------------------------------------------------------------------------------------------------------//

    //INIZIO CALCOLO SSCO
    CalcolaPreventivo_SSCO(): void {

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['imponibileSerramentista'].setValue(
            Number(this.frmOfferta.controls.lav_vendita_infissi.value) +
            Number(this.frmOfferta.controls.lav_posa_opera.value) +
            Number(this.frmOfferta.controls.lav_beni_significativi.value) +
            Number(this.frmOfferta.controls.lav_altri_beni.value) +
            Number(this.frmOfferta.controls.lav_altre_opere.value) +
            Number(this.frmOfferta.controls.lav_prestazioni_prof.value)
        );

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['speseTecniche'].setValue(
            ((this.frmOfferta.get('lav_preventivo') as FormGroup).get('imponibileSerramentista').value * 7.5) / 100
        );

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['nuovoImponibile'].setValue(
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('imponibileSerramentista').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('speseTecniche').value
        );

        if (this.frmOfferta.controls.lav_iva_offerta.value === '0') {
            this.totaleFatturaIVA = (this.frmOfferta.get('lav_preventivo') as FormGroup).get('nuovoImponibile').value;
        } else if (this.frmOfferta.controls.lav_iva_offerta.value === '10' &&
            this.frmOfferta.controls.lav_iva_prestazioni.value === '22') {
            this.totaleFatturaIVA =
                (((this.frmOfferta.get('lav_preventivo') as FormGroup).get('nuovoImponibile').value - Number(this.frmOfferta.controls.lav_prestazioni_prof.value)) * 1.1) +
                (Number(this.frmOfferta.controls.lav_prestazioni_prof.value) * 1.22)
        } else if (this.frmOfferta.controls.lav_iva_offerta.value === '10') {
            this.totaleFatturaIVA = (this.frmOfferta.get('lav_preventivo') as FormGroup).get('nuovoImponibile').value * 1.1
        } else {
            this.totaleFatturaIVA = (this.frmOfferta.get('lav_preventivo') as FormGroup).get('nuovoImponibile').value * 1.22
        }

        this.CalcoloMassimali_SSCO()

        if (this.frmOfferta.controls.cl_tipo.value === 'giuridica') {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['ImportoLavoriCaricoCliente'].setValue(
                (((this.frmOfferta.get('lav_preventivo') as FormGroup).get('nuovoImponibile').value -
                    (this.frmOfferta.get('lav_preventivo') as FormGroup).get('importoNonDetraibile').value) * 50) / 100
            );
        } else {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['ImportoLavoriCaricoCliente'].setValue(
                ((this.totaleFatturaIVA -
                    (this.frmOfferta.get('lav_preventivo') as FormGroup).get('importoNonDetraibile').value) * 50) / 100)
        }

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['contributoSpettante'].setValue(
            -1 * (this.frmOfferta.get('lav_preventivo') as FormGroup).get('ImportoLavoriCaricoCliente').value);

        if (this.frmOfferta.controls.cl_tipo.value === 'giuridica') {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['importoNONDetraibileACaricoCliente'].setValue(
                (this.totaleFatturaIVA - (this.frmOfferta.get('lav_preventivo') as FormGroup).get('nuovoImponibile').value) +
                (this.frmOfferta.get('lav_preventivo') as FormGroup).get('importoNonDetraibile').value
            );
        } else {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['importoNONDetraibileACaricoCliente'].setValue((this.frmOfferta.get('lav_preventivo') as FormGroup).get('importoNonDetraibile').value);
        }

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['costiAttuazione'].setValue(
            ((-1 * (this.frmOfferta.get('lav_preventivo') as FormGroup).get('contributoSpettante').value) * 10) / 100
        );

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['totaleDovutoCliente'].setValue(
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('ImportoLavoriCaricoCliente').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('costiAttuazione').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('importoNONDetraibileACaricoCliente').value
        );

        this.frmOfferta.controls.lav_importo.setValue(this.totaleFatturaIVA);
        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['totaleFatturaIva'].setValue(this.totaleFatturaIVA);

        this.CalcoloFattura_SSCO();
    }

    CalcoloMassimali_SSCO(): void {
        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['imponibileFornitura'].setValue(
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('nuovoImponibile').value -
            Number(this.frmOfferta.controls.lav_posa_opera.value) -
            Number(this.frmOfferta.controls.lav_prestazioni_prof.value) -
            Number(this.frmOfferta.controls.lav_altre_opere.value)
        );

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['massimaleMQdaApplicare'].setValue(230.00);

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['costoMQImponibile'].setValue(
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('imponibileFornitura').value /
            Number(this.frmOfferta.controls.lav_mq_installati.value));

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['imponibileMassimoDetraibile'].setValue(
            Number(this.frmOfferta.controls.lav_mq_installati.value) *
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('massimaleMQdaApplicare').value
        );

        if ((-1 * ((this.frmOfferta.get('lav_preventivo') as FormGroup).get('imponibileFornitura').value -
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('imponibileMassimoDetraibile').value)) > 0) {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['importoNonDetraibile'].setValue(0);
        } else if (this.frmOfferta.controls.cl_tipo.value === 'giuridica' || this.frmOfferta.controls.lav_iva_offerta.value == '0') {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['importoNonDetraibile'].setValue(
                (this.frmOfferta.get('lav_preventivo') as FormGroup).get('imponibileFornitura').value -
                (this.frmOfferta.get('lav_preventivo') as FormGroup).get('imponibileMassimoDetraibile').value);
        } else if ((this.frmOfferta.controls.lav_iva_offerta.value == '22')) {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['importoNonDetraibile'].setValue(
                ((this.frmOfferta.get('lav_preventivo') as FormGroup).get('imponibileFornitura').value -
                    (this.frmOfferta.get('lav_preventivo') as FormGroup).get('imponibileMassimoDetraibile').value) * 1.22);
        } else {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['importoNonDetraibile'].setValue(
                (((this.frmOfferta.get('lav_preventivo') as FormGroup).get('imponibileFornitura').value -
                    (this.frmOfferta.get('lav_preventivo') as FormGroup).get('imponibileMassimoDetraibile').value)) * 1.1);
        }
    }

    CalcoloFattura_SSCO(): void {
        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['posaOperaImponibile'].setValue(Number(this.frmOfferta.controls.lav_posa_opera.value));

        if (this.frmOfferta.controls.lav_iva_offerta.value === '10') {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['posaOpera10'].setValue(
                ((this.frmOfferta.get('lav_preventivo') as FormGroup).get('posaOperaImponibile').value * 10) / 100
            );
        } else {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['posaOpera10'].setValue(0);
        }

        if (this.frmOfferta.controls.lav_iva_offerta.value === '22') {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['posaOpera22'].setValue(
                ((this.frmOfferta.get('lav_preventivo') as FormGroup).get('posaOperaImponibile').value * 22) / 100
            );
        } else {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['posaOpera22'].setValue(0);
        }

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['posaOperaTot'].setValue(
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('posaOperaImponibile').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('posaOpera10').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('posaOpera22').value
        );

        // --------  Valore di vendita schermature solari = Altri Beni Accessori

        if (Number(this.frmOfferta.controls.lav_vendita_infissi.value) === 0) {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['altibeniaccImponibile'].setValue(0);
        } else if ((Number(this.frmOfferta.controls.lav_altri_beni.value) === 0) && (Number(this.frmOfferta.controls.lav_beni_significativi.value) === 0)) {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['altibeniaccImponibile'].setValue(
                Number(this.frmOfferta.controls.lav_vendita_infissi.value) + (this.frmOfferta.get('lav_preventivo') as FormGroup).get('speseTecniche').value
            );
        } else if ((Number(this.frmOfferta.controls.lav_altri_beni.value) === 0) || (Number(this.frmOfferta.controls.lav_beni_significativi.value) === 0)) {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['altibeniaccImponibile'].setValue(
                Number(this.frmOfferta.controls.lav_vendita_infissi.value) + (this.frmOfferta.get('lav_preventivo') as FormGroup).get('speseTecniche').value / 2);
        } else {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['altibeniaccImponibile'].setValue(
                Number(this.frmOfferta.controls.lav_vendita_infissi.value) + (this.frmOfferta.get('lav_preventivo') as FormGroup).get('speseTecniche').value / 3);
        }


        if (this.frmOfferta.controls.lav_iva_offerta.value == '10') {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['altibeniacc10'].setValue(
                ((this.frmOfferta.get('lav_preventivo') as FormGroup).get('altibeniaccImponibile').value * 10) / 100);
        } else {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['altibeniacc10'].setValue(0);
        }
        if (this.frmOfferta.controls.lav_iva_offerta.value == '22') {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['altibeniacc22'].setValue(
                ((this.frmOfferta.get('lav_preventivo') as FormGroup).get('altibeniaccImponibile').value * 22) / 100);
        } else {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['altibeniacc22'].setValue(0);
        }

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['altibeniaccTot'].setValue(
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('altibeniaccImponibile').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('altibeniacc10').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('altibeniacc22').value
        );

        // --------  Valore di vendita chiusure oscuranti tecniche = Valore di Vendita Infissi al 10%
        if (Number(this.frmOfferta.controls.lav_beni_significativi.value) === 0) {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['valore10Imponibile'].setValue(0);
        } else if ((Number(this.frmOfferta.controls.lav_altri_beni.value) === 0) && (Number(this.frmOfferta.controls.lav_vendita_infissi.value) === 0)) {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['valore10Imponibile'].setValue(
                Number(this.frmOfferta.controls.lav_beni_significativi.value) + (this.frmOfferta.get('lav_preventivo') as FormGroup).get('speseTecniche').value
            );
        } else if ((Number(this.frmOfferta.controls.lav_altri_beni.value) === 0) || (Number(this.frmOfferta.controls.lav_vendita_infissi.value) === 0)) {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['valore10Imponibile'].setValue(
                Number(this.frmOfferta.controls.lav_beni_significativi.value) + (this.frmOfferta.get('lav_preventivo') as FormGroup).get('speseTecniche').value / 2);
        } else {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['valore10Imponibile'].setValue(
                Number(this.frmOfferta.controls.lav_beni_significativi.value) + (this.frmOfferta.get('lav_preventivo') as FormGroup).get('speseTecniche').value / 3);
        }


        if (this.frmOfferta.controls.lav_iva_offerta.value == '10') {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['valore1010'].setValue(
                ((this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore10Imponibile').value * 10) / 100);
        } else {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['valore1010'].setValue(0);
        }

        if (this.frmOfferta.controls.lav_iva_offerta.value == '22') {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['valore1022'].setValue(
                ((this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore10Imponibile').value * 22) / 100);
        } else {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['valore1022'].setValue(0);
        }

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['valore10Tot'].setValue(
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore10Imponibile').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore1010').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore1022').value
        );
        // --------  Valore di vendita chiusure oscuranti tecniche = Valore di Vendita Infissi al 22%
        if (Number(this.frmOfferta.controls.lav_altri_beni.value) === 0) {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['valore22Imponibile'].setValue(0);
        } else if ((Number(this.frmOfferta.controls.lav_vendita_infissi.value) === 0) || (Number(this.frmOfferta.controls.lav_beni_significativi.value) === 0)) {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['valore22Imponibile'].setValue(
                Number(this.frmOfferta.controls.lav_altri_beni.value) + (this.frmOfferta.get('lav_preventivo') as FormGroup).get('speseTecniche').value / 2);
        } else {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['valore22Imponibile'].setValue(
                Number(this.frmOfferta.controls.lav_altri_beni.value) + (this.frmOfferta.get('lav_preventivo') as FormGroup).get('speseTecniche').value / 3);
        }

        if (this.frmOfferta.controls.lav_iva_offerta.value == '10') {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['valore2210'].setValue(
                ((this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore22Imponibile').value * 10) / 100);
        } else {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['valore2210'].setValue(0);
        }

        if (this.frmOfferta.controls.lav_iva_offerta.value == '22') {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['valore2222'].setValue(
                ((this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore22Imponibile').value * 22) / 100);
        } else {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['valore2222'].setValue(0);
        }

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['valore22Tot'].setValue(
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore22Imponibile').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore2210').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore2222').value);




        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['altreOpereImponibile'].setValue(Number(this.frmOfferta.controls.lav_altre_opere.value));


        if (this.frmOfferta.controls.lav_iva_offerta.value == '10') {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['altreOpere10'].setValue(
                ((this.frmOfferta.get('lav_preventivo') as FormGroup).get('altreOpereImponibile').value * 10) / 100);
        } else {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['altreOpere10'].setValue(0);
        }

        if (this.frmOfferta.controls.lav_iva_offerta.value == '22') {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['altreOpere22'].setValue(
                ((this.frmOfferta.get('lav_preventivo') as FormGroup).get('altreOpereImponibile').value * 22) / 100);
        } else {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['altreOpere22'].setValue(0);
        }

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['altreOpereTot'].setValue(
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('altreOpereImponibile').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('altreOpere10').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('altreOpere22').value
        );


        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['prestProfImponibile'].setValue(Number(this.frmOfferta.controls.lav_prestazioni_prof.value));

        if (this.frmOfferta.controls.lav_iva_prestazioni.value == '22') {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['prestProf10'].setValue(0);
        } else if (this.frmOfferta.controls.lav_iva_offerta.value == '10') {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['prestProf10'].setValue(((this.frmOfferta.get('lav_preventivo') as FormGroup).get('prestProfImponibile').value * 10) / 100);
        } else {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['prestProf10'].setValue(0);
        }

        if (this.frmOfferta.controls.lav_iva_offerta.value == '0') {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['prestProf22'].setValue(0);
        } else if (this.frmOfferta.controls.lav_iva_offerta.value == '22' ||
            this.frmOfferta.controls.lav_iva_prestazioni.value == '22') {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['prestProf22'].setValue(((this.frmOfferta.get('lav_preventivo') as FormGroup).get('prestProfImponibile').value * 22) / 100);
        } else {
            (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['prestProf22'].setValue(0);
        }

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['prestProfTot'].setValue(
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('prestProfImponibile').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('prestProf10').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('prestProf22').value
        );

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['ftImponibile'].setValue(
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('posaOperaImponibile').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('altibeniaccImponibile').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore10Imponibile').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore22Imponibile').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('altreOpereImponibile').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('prestProfImponibile').value);

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['ft10'].setValue(
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('posaOpera10').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('altibeniacc10').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore1010').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore2210').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('altreOpere10').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('prestProf10').value);

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['ft22'].setValue(
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('posaOpera22').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('altibeniacc22').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore1022').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore2222').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('altreOpere22').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('prestProf22').value);

        (this.frmOfferta.controls.lav_preventivo as FormGroup).controls['ftTot'].setValue(
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('posaOperaTot').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('altibeniaccTot').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore10Tot').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('valore22Tot').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('altreOpereTot').value +
            (this.frmOfferta.get('lav_preventivo') as FormGroup).get('prestProfTot').value);
    }

    //FINE CALCOLO SSCO
}
