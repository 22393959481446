import { Component, OnInit } from "@angular/core";
import { MessageService } from "primeng/api";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { environment } from "src/environments/environment";
import { ActivatedRoute } from "@angular/router";
import { Utente } from "./../../_interfaces/utente";
import { UtenteAttrs } from "./../../_interfaces/utente-attrs";
import { UtentiService } from "./../../_services/utenti.service";
import { SerramentistaService } from "src/app/_services/serramentista.service";
import { UtentiAttrsService } from "src/app/_services/utenti-attrs.service";


@Component({
    selector: "app-utenti-profilazione",
    templateUrl: "./utenti-profilazione.component.html",
    styleUrls: ["./utenti-profilazione.component.scss"],
})
export class UtentiProfilazioneComponent implements OnInit {
    idUtente: string;
    viewPassword = false;
    environment = environment;
    selectedBonus: string[] = [];
    utente: Utente;
    frmUser: FormGroup;
    frmSI: FormGroup;
    frmSI_DD: FormGroup;
    frmSSCO: FormGroup;
    frmSSCO_DD: FormGroup;
    frmBC: FormGroup;
    frmBA: FormGroup;
    frmBC_DD: FormGroup;
    frmBC_DD_AI: FormGroup;
    frmBA_DD_SI: FormGroup;
    frmBA_DD_AI: FormGroup;


    usrAtrr: UtenteAttrs[];
    ruolo: string;
    id_user: string;
    roles: { label: string; value: any }[] = [
        // { label: 'SuperAdmin', value: 'SA' },
        { label: "Admin", value: "AD" },
        { label: "Serramentista", value: "SE" },
    ];

    cessionarioFinale: { label: string; value: any }[] = [];

    TipologiaUtrente: { label: string; value: any }[] = [
        { label: "SI + SSCO", value: "1" },
        { label: "SI", value: "0" },
        { label: "SSCO", value: "2" },
    ];
    AbilitaOneriAttuazione: { label: string; value: any }[] = [
        { label: 'Immodificabili', value: 0 },
        { label: 'Modificabili', value: 1 },
    ];

    Step: { label: string; value: any }[] = [
        { label: "Step 2", value: "2" },
        { label: "Step 4", value: "4" },
        { label: "Step 7", value: "7" },
    ];

    Step_DD: { label: string; value: any }[] = [
        { label: "Step 2", value: "2" },
        { label: "Step 3", value: "3" },
        { label: "Step 4", value: "4" },
        { label: "Step 7", value: "7" },
    ];

    Preventivatore: { label: string; value: any }[] = [
        { label: "Oneri Annegati", value: "1" },
        { label: "Oneri Esposti", value: "2" }
    ];

    Preventivatore_DD: { label: string; value: any }[] = [
        { label: "Oneri NON Presenti", value: "3" }
    ];



    TipoBc: { label: string; value: any }[] = [{ label: "BC", value: "0" }];

    constructor(
        private utenteSrv: UtentiService,
        private SerramentistaSrv: SerramentistaService,
        private msgService: MessageService,
        private utenteAttrsSrv: UtentiAttrsService,
        private route: ActivatedRoute
    ) { this.ruolo = sessionStorage.getItem('ruolo'); }

    ngOnInit(): void {

        this.idUtente = this.route.snapshot.paramMap.get("id") ?? "0";
        this.id_user = sessionStorage.getItem('id');
        this.creaform();
        this.creaformSI();
        this.creaformSI_DD();
        this.creaformSSCO();
        this.creaformSSCO_DD();
        this.creaformBC();
        this.creaformBC_DD();
        this.creaformBC_DD_AI();
        this.creaformBA();
        this.creaformBA_DD_SI();
        this.creaformBA_DD_AI();
        this.refreshCessionariFinali();
        this.getUtenteByID();
        this.getUtenteAttrsSI();
        this.getUtenteAttrsSI_DD();
        this.getUtenteAttrsSSCO();
        this.getUtenteAttrsSSCO_DD();
        this.getUtenteAttrsBC();
        this.getUtenteAttrsBC_DD();
        this.getUtenteAttrsBC_DD_AI();
        this.getUtenteAttrsBA();
        this.getUtenteAttrsBA_DD_SI();
        this.getUtenteAttrsBA_DD_AI();

    }

    creaform(): void {
        this.frmUser = new FormGroup({
            id: new FormControl(0, Validators.required),
            name: new FormControl("", Validators.required),
            email: new FormControl("", [Validators.required, Validators.email]),
            ruolo: new FormControl("", Validators.required),
            account: new FormControl("", Validators.required),
            account_email: new FormControl("", [Validators.required, Validators.email]),
            password: new FormControl(),
            abilitato: new FormControl(1, Validators.required),
            abilita_oneri_attuazione: new FormControl(0, Validators.required),
            interventi: new FormControl("1"),
        });
    }




    creaformSI(): void {
        this.frmSI = new FormGroup({
            si_check: new FormControl(false),
            si_detrazione: new FormControl("0"),
            si_cessionario: new FormControl(),
            si_preventivatore: new FormControl(),
            si_chiusuraPratica: new FormControl(),
            si_1rangeMin: new FormControl("0"),
            si_1rangeMax: new FormControl("0"),
            si_1costoFisso: new FormControl("0"),
            si_1percImp: new FormControl("0"),
            si_1costoMin: new FormControl("0"),
            si_1costoMax: new FormControl("0"),
            si_1percOneri: new FormControl("0"),
            si_1costoAssev: new FormControl("0"),
            si_1percAssev: new FormControl("0"),
            si_1costoVisto: new FormControl("0"),
            si_1percVisto: new FormControl("0"),

            si_2rangeMin: new FormControl("0"),
            si_2rangeMax: new FormControl("0"),
            si_2costoFisso: new FormControl("0"),
            si_2percImp: new FormControl("0"),
            si_2costoMin: new FormControl("0"),
            si_2costoMax: new FormControl("0"),
            si_2percOneri: new FormControl("0"),
            si_2costoAssev: new FormControl("0"),
            si_2percAssev: new FormControl("0"),
            si_2costoVisto: new FormControl("0"),
            si_2percVisto: new FormControl("0"),

            si_3rangeMin: new FormControl("0"),
            si_3rangeMax: new FormControl("999999"),
            si_3costoFisso: new FormControl("0"),
            si_3percImp: new FormControl("0"),
            si_3costoMin: new FormControl("0"),
            si_3costoMax: new FormControl("999999"),
            si_3percOneri: new FormControl("0"),
            si_3costoAssev: new FormControl("0"),
            si_3percAssev: new FormControl("0"),
            si_3costoVisto: new FormControl("0"),
            si_3percVisto: new FormControl("0"),
        });
    }

    creaformSI_DD(): void {
        this.frmSI_DD = new FormGroup({
            si_dd_check: new FormControl(false),
            si_dd_detrazione: new FormControl("0"),
            si_dd_cessionario: new FormControl(),
            si_dd_preventivatore: new FormControl(3),
            si_dd_chiusuraPratica: new FormControl(),
            si_dd_1rangeMin: new FormControl("0"),
            si_dd_1rangeMax: new FormControl("0"),
            si_dd_1costoFisso: new FormControl("0"),
            si_dd_1percImp: new FormControl("0"),
            si_dd_1costoMin: new FormControl("0"),
            si_dd_1costoMax: new FormControl("0"),
            si_dd_1percOneri: new FormControl("0"),
            si_dd_1costoAssev: new FormControl("0"),
            si_dd_1percAssev: new FormControl("0"),
            si_dd_1costoVisto: new FormControl("0"),
            si_dd_1percVisto: new FormControl("0"),

            si_dd_2rangeMin: new FormControl("0"),
            si_dd_2rangeMax: new FormControl("0"),
            si_dd_2costoFisso: new FormControl("0"),
            si_dd_2percImp: new FormControl("0"),
            si_dd_2costoMin: new FormControl("0"),
            si_dd_2costoMax: new FormControl("0"),
            si_dd_2percOneri: new FormControl("0"),
            si_dd_2costoAssev: new FormControl("0"),
            si_dd_2percAssev: new FormControl("0"),
            si_dd_2costoVisto: new FormControl("0"),
            si_dd_2percVisto: new FormControl("0"),

            si_dd_3rangeMin: new FormControl("0"),
            si_dd_3rangeMax: new FormControl("999999"),
            si_dd_3costoFisso: new FormControl("0"),
            si_dd_3percImp: new FormControl("0"),
            si_dd_3costoMin: new FormControl("0"),
            si_dd_3costoMax: new FormControl("999999"),
            si_dd_3percOneri: new FormControl("0"),
            si_dd_3costoAssev: new FormControl("0"),
            si_dd_3percAssev: new FormControl("0"),
            si_dd_3costoVisto: new FormControl("0"),
            si_dd_3percVisto: new FormControl("0"),
        });
    }

    creaformSSCO(): void {
        this.frmSSCO = new FormGroup({
            ssco_check: new FormControl(false),
            ssco_detrazione: new FormControl("0"),
            ssco_cessionario: new FormControl(),
            ssco_preventivatore: new FormControl(),
            ssco_chiusuraPratica: new FormControl(),

            ssco_1rangeMin: new FormControl("0"),
            ssco_1rangeMax: new FormControl("0"),
            ssco_1costoFisso: new FormControl("0"),
            ssco_1percImp: new FormControl("0"),
            ssco_1costoMin: new FormControl("0"),
            ssco_1costoMax: new FormControl("0"),
            ssco_1percOneri: new FormControl("0"),
            ssco_1costoAssev: new FormControl("0"),
            ssco_1percAssev: new FormControl("0"),
            ssco_1costoVisto: new FormControl("0"),
            ssco_1percVisto: new FormControl("0"),

            ssco_2rangeMin: new FormControl("0"),
            ssco_2rangeMax: new FormControl("0"),
            ssco_2costoFisso: new FormControl("0"),
            ssco_2percImp: new FormControl("0"),
            ssco_2costoMin: new FormControl("0"),
            ssco_2costoMax: new FormControl("0"),
            ssco_2percOneri: new FormControl("0"),
            ssco_2costoAssev: new FormControl("0"),
            ssco_2percAssev: new FormControl("0"),
            ssco_2costoVisto: new FormControl("0"),
            ssco_2percVisto: new FormControl("0"),

            ssco_3rangeMin: new FormControl("0"),
            ssco_3rangeMax: new FormControl("999999"),
            ssco_3costoFisso: new FormControl("0"),
            ssco_3percImp: new FormControl("0"),
            ssco_3costoMin: new FormControl("0"),
            ssco_3costoMax: new FormControl("999999"),
            ssco_3percOneri: new FormControl("0"),
            ssco_3costoAssev: new FormControl("0"),
            ssco_3percAssev: new FormControl("0"),
            ssco_3costoVisto: new FormControl("0"),
            ssco_3percVisto: new FormControl("0"),
        });
    }

    creaformSSCO_DD(): void {
        this.frmSSCO_DD = new FormGroup({
            ssco_dd_check: new FormControl(false),
            ssco_dd_detrazione: new FormControl("0"),
            ssco_dd_cessionario: new FormControl(),
            ssco_dd_preventivatore: new FormControl(3),
            ssco_dd_chiusuraPratica: new FormControl(),
            ssco_dd_1rangeMin: new FormControl("0"),
            ssco_dd_1rangeMax: new FormControl("0"),
            ssco_dd_1costoFisso: new FormControl("0"),
            ssco_dd_1percImp: new FormControl("0"),
            ssco_dd_1costoMin: new FormControl("0"),
            ssco_dd_1costoMax: new FormControl("0"),
            ssco_dd_1percOneri: new FormControl("0"),
            ssco_dd_1costoAssev: new FormControl("0"),
            ssco_dd_1percAssev: new FormControl("0"),
            ssco_dd_1costoVisto: new FormControl("0"),
            ssco_dd_1percVisto: new FormControl("0"),
            ssco_dd_2rangeMin: new FormControl("0"),
            ssco_dd_2rangeMax: new FormControl("0"),
            ssco_dd_2costoFisso: new FormControl("0"),
            ssco_dd_2percImp: new FormControl("0"),
            ssco_dd_2costoMin: new FormControl("0"),
            ssco_dd_2costoMax: new FormControl("0"),
            ssco_dd_2percOneri: new FormControl("0"),
            ssco_dd_2costoAssev: new FormControl("0"),
            ssco_dd_2percAssev: new FormControl("0"),
            ssco_dd_2costoVisto: new FormControl("0"),
            ssco_dd_2percVisto: new FormControl("0"),
            ssco_dd_3rangeMin: new FormControl("0"),
            ssco_dd_3rangeMax: new FormControl("999999"),
            ssco_dd_3costoFisso: new FormControl("0"),
            ssco_dd_3percImp: new FormControl("0"),
            ssco_dd_3costoMin: new FormControl("0"),
            ssco_dd_3costoMax: new FormControl("999999"),
            ssco_dd_3percOneri: new FormControl("0"),
            ssco_dd_3costoAssev: new FormControl("0"),
            ssco_dd_3percAssev: new FormControl("0"),
            ssco_dd_3costoVisto: new FormControl("0"),
            ssco_dd_3percVisto: new FormControl("0"),
        });
    }

    creaformBC(): void {
        this.frmBC = new FormGroup({
            bc_check: new FormControl(false),
            bc_detrazione: new FormControl("0"),
            bc_cessionario: new FormControl(),
            bc_preventivatore: new FormControl(),
            bc_chiusuraPratica: new FormControl(),
            bc_1rangeMin: new FormControl("0"),
            bc_1rangeMax: new FormControl("0"),
            bc_1costoFisso: new FormControl("0"),
            bc_1percImp: new FormControl("0"),
            bc_1costoMin: new FormControl("0"),
            bc_1costoMax: new FormControl("0"),
            bc_1percOneri: new FormControl("0"),
            bc_1costoAssev: new FormControl("0"),
            bc_1percAssev: new FormControl("0"),
            bc_1costoVisto: new FormControl("0"),
            bc_1percVisto: new FormControl("0"),
            bc_2rangeMin: new FormControl("0"),
            bc_2rangeMax: new FormControl("0"),
            bc_2costoFisso: new FormControl("0"),
            bc_2percImp: new FormControl("0"),
            bc_2costoMin: new FormControl("0"),
            bc_2costoMax: new FormControl("0"),
            bc_2percOneri: new FormControl("0"),
            bc_2costoAssev: new FormControl("0"),
            bc_2percAssev: new FormControl("0"),
            bc_2costoVisto: new FormControl("0"),
            bc_2percVisto: new FormControl("0"),
            bc_3rangeMin: new FormControl("0"),
            bc_3rangeMax: new FormControl("999999"),
            bc_3costoFisso: new FormControl("0"),
            bc_3percImp: new FormControl("0"),
            bc_3costoMin: new FormControl("0"),
            bc_3costoMax: new FormControl("999999"),
            bc_3percOneri: new FormControl("0"),
            bc_3costoAssev: new FormControl("0"),
            bc_3percAssev: new FormControl("0"),
            bc_3costoVisto: new FormControl("0"),
            bc_3percVisto: new FormControl("0"),
            VenditaBeni: new FormControl("Valore di vendita beni"),
            PosaOpera: new FormControl("Posa in opera"),
            BeniSignificativi: new FormControl("Beni significativi"),
            VenditaMateriali: new FormControl("Valore di vendita materiali"),
            AltreOpere: new FormControl("Altre opere complementari all'installazione delle tecnologie"),
            Prestazioni: new FormControl("Prestazioni professionali"),
        });
    }

    creaformBC_DD(): void {
        this.frmBC_DD = new FormGroup({
            bc_dd_check: new FormControl(false),
            bc_dd_detrazione: new FormControl("0"),
            bc_dd_cessionario: new FormControl(),
            bc_dd_preventivatore: new FormControl(3),
            bc_dd_chiusuraPratica: new FormControl(),
            bc_dd_1rangeMin: new FormControl("0"),
            bc_dd_1rangeMax: new FormControl("0"),
            bc_dd_1costoFisso: new FormControl("0"),
            bc_dd_1percImp: new FormControl("0"),
            bc_dd_1costoMin: new FormControl("0"),
            bc_dd_1costoMax: new FormControl("0"),
            bc_dd_1percOneri: new FormControl("0"),
            bc_dd_1costoAssev: new FormControl("0"),
            bc_dd_1percAssev: new FormControl("0"),
            bc_dd_1costoVisto: new FormControl("0"),
            bc_dd_1percVisto: new FormControl("0"),
            bc_dd_2rangeMin: new FormControl("0"),
            bc_dd_2rangeMax: new FormControl("0"),
            bc_dd_2costoFisso: new FormControl("0"),
            bc_dd_2percImp: new FormControl("0"),
            bc_dd_2costoMin: new FormControl("0"),
            bc_dd_2costoMax: new FormControl("0"),
            bc_dd_2percOneri: new FormControl("0"),
            bc_dd_2costoAssev: new FormControl("0"),
            bc_dd_2percAssev: new FormControl("0"),
            bc_dd_2costoVisto: new FormControl("0"),
            bc_dd_2percVisto: new FormControl("0"),
            bc_dd_3rangeMin: new FormControl("0"),
            bc_dd_3rangeMax: new FormControl("999999"),
            bc_dd_3costoFisso: new FormControl("0"),
            bc_dd_3percImp: new FormControl("0"),
            bc_dd_3costoMin: new FormControl("0"),
            bc_dd_3costoMax: new FormControl("999999"),
            bc_dd_3percOneri: new FormControl("0"),
            bc_dd_3costoAssev: new FormControl("0"),
            bc_dd_3percAssev: new FormControl("0"),
            bc_dd_3costoVisto: new FormControl("0"),
            bc_dd_3percVisto: new FormControl("0"),
            bc_dd_VenditaBeni: new FormControl("Valore di vendita beni"),
            bc_dd_PosaOpera: new FormControl("Posa in opera"),
            bc_dd_BeniSignificativi: new FormControl("Beni significativi"),
            bc_dd_VenditaMateriali: new FormControl("Valore di vendita materiali"),
            bc_dd_AltreOpere: new FormControl("Altre opere complementari all'installazione delle tecnologie"),
            bc_dd_Prestazioni: new FormControl("Prestazioni professionali"),
        });


    }

    creaformBC_DD_AI(): void {
        this.frmBC_DD_AI = new FormGroup({
            bc_dd_ai_check: new FormControl(false),
            bc_dd_ai_detrazione: new FormControl("0"),
            bc_dd_ai_cessionario: new FormControl(),
            bc_dd_ai_preventivatore: new FormControl(3),
            bc_dd_ai_chiusuraPratica: new FormControl(),
            bc_dd_ai_1rangeMin: new FormControl("0"),
            bc_dd_ai_1rangeMax: new FormControl("0"),
            bc_dd_ai_1costoFisso: new FormControl("0"),
            bc_dd_ai_1percImp: new FormControl("0"),
            bc_dd_ai_1costoMin: new FormControl("0"),
            bc_dd_ai_1costoMax: new FormControl("0"),
            bc_dd_ai_1percOneri: new FormControl("0"),
            bc_dd_ai_1costoAssev: new FormControl("0"),
            bc_dd_ai_1percAssev: new FormControl("0"),
            bc_dd_ai_1costoVisto: new FormControl("0"),
            bc_dd_ai_1percVisto: new FormControl("0"),
            bc_dd_ai_2rangeMin: new FormControl("0"),
            bc_dd_ai_2rangeMax: new FormControl("0"),
            bc_dd_ai_2costoFisso: new FormControl("0"),
            bc_dd_ai_2percImp: new FormControl("0"),
            bc_dd_ai_2costoMin: new FormControl("0"),
            bc_dd_ai_2costoMax: new FormControl("0"),
            bc_dd_ai_2percOneri: new FormControl("0"),
            bc_dd_ai_2costoAssev: new FormControl("0"),
            bc_dd_ai_2percAssev: new FormControl("0"),
            bc_dd_ai_2costoVisto: new FormControl("0"),
            bc_dd_ai_2percVisto: new FormControl("0"),
            bc_dd_ai_3rangeMin: new FormControl("0"),
            bc_dd_ai_3rangeMax: new FormControl("999999"),
            bc_dd_ai_3costoFisso: new FormControl("0"),
            bc_dd_ai_3percImp: new FormControl("0"),
            bc_dd_ai_3costoMin: new FormControl("0"),
            bc_dd_ai_3costoMax: new FormControl("999999"),
            bc_dd_ai_3percOneri: new FormControl("0"),
            bc_dd_ai_3costoAssev: new FormControl("0"),
            bc_dd_ai_3percAssev: new FormControl("0"),
            bc_dd_ai_3costoVisto: new FormControl("0"),
            bc_dd_ai_3percVisto: new FormControl("0"),
            bc_dd_ai_VenditaBeni: new FormControl("Valore di vendita beni"),
            bc_dd_ai_PosaOpera: new FormControl("Posa in opera"),
            bc_dd_ai_BeniSignificativi: new FormControl("Beni significativi"),
            bc_dd_ai_VenditaMateriali: new FormControl("Valore di vendita materiali"),
            bc_dd_ai_AltreOpere: new FormControl("Altre opere complementari all'installazione delle tecnologie"),
            bc_dd_ai_Prestazioni: new FormControl("Prestazioni professionali"),
        });


    }

    creaformBA(): void {
        this.frmBA = new FormGroup({
            ba_check: new FormControl(false),
            ba_detrazione: new FormControl("0"),
            ba_cessionario: new FormControl(),
            ba_preventivatore: new FormControl(),
            ba_chiusuraPratica: new FormControl(),
            ba_1rangeMin: new FormControl("0"),
            ba_1rangeMax: new FormControl("0"),
            ba_1costoFisso: new FormControl("0"),
            ba_1percImp: new FormControl("0"),
            ba_1costoMin: new FormControl("0"),
            ba_1costoMax: new FormControl("0"),
            ba_1percOneri: new FormControl("0"),
            ba_1costoAssev: new FormControl("0"),
            ba_1percAssev: new FormControl("0"),
            ba_1costoVisto: new FormControl("0"),
            ba_1percVisto: new FormControl("0"),
            ba_2rangeMin: new FormControl("0"),
            ba_2rangeMax: new FormControl("0"),
            ba_2costoFisso: new FormControl("0"),
            ba_2percImp: new FormControl("0"),
            ba_2costoMin: new FormControl("0"),
            ba_2costoMax: new FormControl("0"),
            ba_2percOneri: new FormControl("0"),
            ba_2costoAssev: new FormControl("0"),
            ba_2percAssev: new FormControl("0"),
            ba_2costoVisto: new FormControl("0"),
            ba_2percVisto: new FormControl("0"),
            ba_3rangeMin: new FormControl("0"),
            ba_3rangeMax: new FormControl("999999"),
            ba_3costoFisso: new FormControl("0"),
            ba_3percImp: new FormControl("0"),
            ba_3costoMin: new FormControl("0"),
            ba_3costoMax: new FormControl("999999"),
            ba_3percOneri: new FormControl("0"),
            ba_3costoAssev: new FormControl("0"),
            ba_3percAssev: new FormControl("0"),
            ba_3costoVisto: new FormControl("0"),
            ba_3percVisto: new FormControl("0"),
            ba_VenditaBeni: new FormControl("Valore di vendita beni"),
            ba_PosaOpera: new FormControl("Posa in opera"),
            ba_BeniSignificativi: new FormControl("Beni significativi"),
            ba_VenditaMateriali: new FormControl("Valore di vendita materiali"),
            ba_AltreOpere: new FormControl("Altre opere complementari all'installazione delle tecnologie"),
            ba_Prestazioni: new FormControl("Prestazioni professionali"),
        });
    }

    creaformBA_DD_SI(): void {
        this.frmBA_DD_SI = new FormGroup({
            ba_dd_si_check: new FormControl(false),
            ba_dd_si_detrazione: new FormControl("0"),
            ba_dd_si_cessionario: new FormControl(),
            ba_dd_si_preventivatore: new FormControl(),
            ba_dd_si_chiusuraPratica: new FormControl(),
            ba_dd_si_1rangeMin: new FormControl("0"),
            ba_dd_si_1rangeMax: new FormControl("0"),
            ba_dd_si_1costoFisso: new FormControl("0"),
            ba_dd_si_1percImp: new FormControl("0"),
            ba_dd_si_1costoMin: new FormControl("0"),
            ba_dd_si_1costoMax: new FormControl("0"),
            ba_dd_si_1percOneri: new FormControl("0"),
            ba_dd_si_1costoAssev: new FormControl("0"),
            ba_dd_si_1percAssev: new FormControl("0"),
            ba_dd_si_1costoVisto: new FormControl("0"),
            ba_dd_si_1percVisto: new FormControl("0"),
            ba_dd_si_2rangeMin: new FormControl("0"),
            ba_dd_si_2rangeMax: new FormControl("0"),
            ba_dd_si_2costoFisso: new FormControl("0"),
            ba_dd_si_2percImp: new FormControl("0"),
            ba_dd_si_2costoMin: new FormControl("0"),
            ba_dd_si_2costoMax: new FormControl("0"),
            ba_dd_si_2percOneri: new FormControl("0"),
            ba_dd_si_2costoAssev: new FormControl("0"),
            ba_dd_si_2percAssev: new FormControl("0"),
            ba_dd_si_2costoVisto: new FormControl("0"),
            ba_dd_si_2percVisto: new FormControl("0"),
            ba_dd_si_3rangeMin: new FormControl("0"),
            ba_dd_si_3rangeMax: new FormControl("999999"),
            ba_dd_si_3costoFisso: new FormControl("0"),
            ba_dd_si_3percImp: new FormControl("0"),
            ba_dd_si_3costoMin: new FormControl("0"),
            ba_dd_si_3costoMax: new FormControl("999999"),
            ba_dd_si_3percOneri: new FormControl("0"),
            ba_dd_si_3costoAssev: new FormControl("0"),
            ba_dd_si_3percAssev: new FormControl("0"),
            ba_dd_si_3costoVisto: new FormControl("0"),
            ba_dd_si_3percVisto: new FormControl("0"),
            ba_dd_si_VenditaBeni: new FormControl("Valore di vendita beni"),
            ba_dd_si_PosaOpera: new FormControl("Posa in opera"),
            ba_dd_si_BeniSignificativi: new FormControl("Beni significativi"),
            ba_dd_si_VenditaMateriali: new FormControl("Valore di vendita materiali"),
            ba_dd_si_AltreOpere: new FormControl("Altre opere complementari all'installazione delle tecnologie"),
            ba_dd_si_Prestazioni: new FormControl("Prestazioni professionali"),
        });
    }

    creaformBA_DD_AI(): void {
        this.frmBA_DD_AI = new FormGroup({
            ba_dd_ai_check: new FormControl(false),
            ba_dd_ai_detrazione: new FormControl("0"),
            ba_dd_ai_cessionario: new FormControl(),
            ba_dd_ai_preventivatore: new FormControl(),
            ba_dd_ai_chiusuraPratica: new FormControl(),
            ba_dd_ai_1rangeMin: new FormControl("0"),
            ba_dd_ai_1rangeMax: new FormControl("0"),
            ba_dd_ai_1costoFisso: new FormControl("0"),
            ba_dd_ai_1percImp: new FormControl("0"),
            ba_dd_ai_1costoMin: new FormControl("0"),
            ba_dd_ai_1costoMax: new FormControl("0"),
            ba_dd_ai_1percOneri: new FormControl("0"),
            ba_dd_ai_1costoAssev: new FormControl("0"),
            ba_dd_ai_1percAssev: new FormControl("0"),
            ba_dd_ai_1costoVisto: new FormControl("0"),
            ba_dd_ai_1percVisto: new FormControl("0"),
            ba_dd_ai_2rangeMin: new FormControl("0"),
            ba_dd_ai_2rangeMax: new FormControl("0"),
            ba_dd_ai_2costoFisso: new FormControl("0"),
            ba_dd_ai_2percImp: new FormControl("0"),
            ba_dd_ai_2costoMin: new FormControl("0"),
            ba_dd_ai_2costoMax: new FormControl("0"),
            ba_dd_ai_2percOneri: new FormControl("0"),
            ba_dd_ai_2costoAssev: new FormControl("0"),
            ba_dd_ai_2percAssev: new FormControl("0"),
            ba_dd_ai_2costoVisto: new FormControl("0"),
            ba_dd_ai_2percVisto: new FormControl("0"),
            ba_dd_ai_3rangeMin: new FormControl("0"),
            ba_dd_ai_3rangeMax: new FormControl("999999"),
            ba_dd_ai_3costoFisso: new FormControl("0"),
            ba_dd_ai_3percImp: new FormControl("0"),
            ba_dd_ai_3costoMin: new FormControl("0"),
            ba_dd_ai_3costoMax: new FormControl("999999"),
            ba_dd_ai_3percOneri: new FormControl("0"),
            ba_dd_ai_3costoAssev: new FormControl("0"),
            ba_dd_ai_3percAssev: new FormControl("0"),
            ba_dd_ai_3costoVisto: new FormControl("0"),
            ba_dd_ai_3percVisto: new FormControl("0"),
            ba_dd_ai_VenditaBeni: new FormControl("Valore di vendita beni"),
            ba_dd_ai_PosaOpera: new FormControl("Posa in opera"),
            ba_dd_ai_BeniSignificativi: new FormControl("Beni significativi"),
            ba_dd_ai_VenditaMateriali: new FormControl("Valore di vendita materiali"),
            ba_dd_ai_AltreOpere: new FormControl("Altre opere complementari all'installazione delle tecnologie"),
            ba_dd_ai_Prestazioni: new FormControl("Prestazioni professionali"),
        });
    }




    getUtenteByID(): void {
        this.utenteSrv.getUtenteID(this.idUtente).subscribe((res) => {
            this.frmUser.patchValue({ ...res.data });
        });
    }

    getUtenteAttrsSI(): void {
        this.utenteAttrsSrv.getUserAttrs(this.idUtente, 'SI').subscribe((res) => {
            [...res.data].forEach((item) => {
                if (item.ua_chiave == "si_check") {
                    this.frmSI.controls[item.ua_chiave].setValue(item.ua_valore == "true" ? true : false)
                } else {
                    this.frmSI.controls[item.ua_chiave].setValue(item.ua_valore)
                }
            });
        });
    }

    getUtenteAttrsSI_DD(): void {
        this.utenteAttrsSrv.getUserAttrs(this.idUtente, 'SI_DD').subscribe((res) => {
            [...res.data].forEach((item) => {
                if (item.ua_chiave == "si_dd_check") {
                    this.frmSI_DD.controls[item.ua_chiave].setValue(item.ua_valore == "true" ? true : false)
                } else {
                    this.frmSI_DD.controls[item.ua_chiave].setValue(item.ua_valore)
                }
            });
        });
    }


    getUtenteAttrsSSCO(): void {
        this.utenteAttrsSrv.getUserAttrs(this.idUtente, 'SSCO').subscribe((res) => {
            [...res.data].forEach((item) => {
                if (item.ua_chiave == "ssco_check") {
                    this.frmSSCO.controls[item.ua_chiave].setValue(item.ua_valore == "true" ? true : false)
                } else {
                    this.frmSSCO.controls[item.ua_chiave].setValue(item.ua_valore)
                }
            });
        });
    }

    getUtenteAttrsSSCO_DD(): void {
        this.utenteAttrsSrv.getUserAttrs(this.idUtente, 'SSCO_DD').subscribe((res) => {
            [...res.data].forEach((item) => {
                if (item.ua_chiave == "ssco_dd_check") {
                    this.frmSSCO_DD.controls[item.ua_chiave].setValue(item.ua_valore == "true" ? true : false)
                } else {
                    this.frmSSCO_DD.controls[item.ua_chiave].setValue(item.ua_valore)
                }
            });
        });
    }

    getUtenteAttrsBC(): void {
        this.utenteAttrsSrv.getUserAttrs(this.idUtente, 'BC').subscribe((res) => {
            [...res.data].forEach((item) => {
                if (item.ua_chiave == "bc_check") {
                    this.frmBC.controls[item.ua_chiave].setValue(item.ua_valore == "true" ? true : false)
                } else {
                    this.frmBC.controls[item.ua_chiave].setValue(item.ua_valore)
                }
            });
        });
    }

    getUtenteAttrsBC_DD(): void {
        this.utenteAttrsSrv.getUserAttrs(this.idUtente, 'BC_DD').subscribe((res) => {
            [...res.data].forEach((item) => {
                if (item.ua_chiave == "bc_dd_check") {
                    this.frmBC_DD.controls[item.ua_chiave].setValue(item.ua_valore == "true" ? true : false)
                } else {
                    this.frmBC_DD.controls[item.ua_chiave].setValue(item.ua_valore)
                }
            });
        });
    }

    getUtenteAttrsBC_DD_AI(): void {
        this.utenteAttrsSrv.getUserAttrs(this.idUtente, 'BC_DD_AI').subscribe((res) => {
            [...res.data].forEach((item) => {
                if (item.ua_chiave == "bc_dd_ai_check") {
                    this.frmBC_DD_AI.controls[item.ua_chiave].setValue(item.ua_valore == "true" ? true : false)
                } else {
                    this.frmBC_DD_AI.controls[item.ua_chiave].setValue(item.ua_valore)
                }
            });
        });
    }


    getUtenteAttrsBA(): void {
        this.utenteAttrsSrv.getUserAttrs(this.idUtente, 'BA').subscribe((res) => {
            [...res.data].forEach((item) => {
                if (item.ua_chiave == "ba_check") {
                    this.frmBA.controls[item.ua_chiave].setValue(item.ua_valore == "true" ? true : false)
                } else {
                    this.frmBA.controls[item.ua_chiave].setValue(item.ua_valore)
                }
            });
        });
    }

    getUtenteAttrsBA_DD_SI(): void {
        this.utenteAttrsSrv.getUserAttrs(this.idUtente, 'BA_DD_SI').subscribe((res) => {
            [...res.data].forEach((item) => {
                if (item.ua_chiave == "ba_dd_si_check") {
                    this.frmBA_DD_SI.controls[item.ua_chiave].setValue(item.ua_valore == "true" ? true : false)
                } else {
                    this.frmBA_DD_SI.controls[item.ua_chiave].setValue(item.ua_valore)
                }
            });
        });
    }

    getUtenteAttrsBA_DD_AI(): void {
        this.utenteAttrsSrv.getUserAttrs(this.idUtente, 'BA_DD_AI').subscribe((res) => {
            [...res.data].forEach((item) => {
                if (item.ua_chiave == "ba_dd_ai_check") {
                    this.frmBA_DD_AI.controls[item.ua_chiave].setValue(item.ua_valore == "true" ? true : false)
                } else {
                    this.frmBA_DD_AI.controls[item.ua_chiave].setValue(item.ua_valore)
                }
            });
        });
    }

    refreshCessionariFinali(): void {
        this.SerramentistaSrv.getCessionarifinali().subscribe((res) => {
            this.cessionarioFinale = [];
            [...res.data].forEach((item) => {
                this.cessionarioFinale.push({ label: item.serr_ragsoc, value: String(item.serr_id) })
            })
        });
    }

    cancel(): void {
        this.creaform();
    }

    submit() {
        this.usrAtrr = [];
        Object.keys(this.frmSI.controls).forEach((k) => {
            this.usrAtrr.push({
                ua_utente: this.frmUser.controls.id.value,
                ua_tipo: "SI",
                ua_chiave: k,
                ua_valore: String(this.frmSI.get(k).value),
            });
        });
        Object.keys(this.frmSI_DD.controls).forEach((k) => {
            this.usrAtrr.push({
                ua_utente: this.frmUser.controls.id.value,
                ua_tipo: "SI_DD",
                ua_chiave: k,
                ua_valore: String(this.frmSI_DD.get(k).value),
            });
        });
        Object.keys(this.frmSSCO.controls).forEach((k) => {
            this.usrAtrr.push({
                ua_utente: this.frmUser.controls.id.value,
                ua_tipo: "SSCO",
                ua_chiave: k,
                ua_valore: String(this.frmSSCO.get(k).value),
            });
        });
        Object.keys(this.frmSSCO_DD.controls).forEach((k) => {
            this.usrAtrr.push({
                ua_utente: this.frmUser.controls.id.value,
                ua_tipo: "SSCO_DD",
                ua_chiave: k,
                ua_valore: String(this.frmSSCO_DD.get(k).value),
            });
        });
        Object.keys(this.frmBC.controls).forEach((k) => {
            this.usrAtrr.push({
                ua_utente: this.frmUser.controls.id.value,
                ua_tipo: "BC",
                ua_chiave: k,
                ua_valore: String(this.frmBC.get(k).value),
            });
        });
        Object.keys(this.frmBC_DD.controls).forEach((k) => {
            this.usrAtrr.push({
                ua_utente: this.frmUser.controls.id.value,
                ua_tipo: "BC_DD",
                ua_chiave: k,
                ua_valore: String(this.frmBC_DD.get(k).value),
            });
        });
        Object.keys(this.frmBC_DD_AI.controls).forEach((k) => {
            this.usrAtrr.push({
                ua_utente: this.frmUser.controls.id.value,
                ua_tipo: "BC_DD_AI",
                ua_chiave: k,
                ua_valore: String(this.frmBC_DD_AI.get(k).value),
            });
        });
        Object.keys(this.frmBA.controls).forEach((k) => {
            this.usrAtrr.push({
                ua_utente: this.frmUser.controls.id.value,
                ua_tipo: "BA",
                ua_chiave: k,
                ua_valore: String(this.frmBA.get(k).value),
            });
        });

        Object.keys(this.frmBA_DD_SI.controls).forEach((k) => {
            this.usrAtrr.push({
                ua_utente: this.frmUser.controls.id.value,
                ua_tipo: "BA_DD_SI",
                ua_chiave: k,
                ua_valore: String(this.frmBA_DD_SI.get(k).value),
            });
        });

        Object.keys(this.frmBA_DD_AI.controls).forEach((k) => {
            this.usrAtrr.push({
                ua_utente: this.frmUser.controls.id.value,
                ua_tipo: "BA_DD_AI",
                ua_chiave: k,
                ua_valore: String(this.frmBA_DD_AI.get(k).value),
            });
        });


        const updUtente: { id: number; ruolo: string; interventi: string; abilitato: number; abilita_oneri_attuazione: number; email: string; account: string; account_email: string; } = {
            id: this.frmUser.controls.id.value,
            ruolo: this.frmUser.controls.ruolo.value,
            interventi: this.frmUser.controls.interventi.value,
            email: this.frmUser.controls.email.value,
            abilitato: this.frmUser.controls.abilitato.value,
            abilita_oneri_attuazione: this.frmUser.controls.abilita_oneri_attuazione.value,
            account: this.frmUser.controls.account.value,
            account_email: this.frmUser.controls.account_email.value,
        };
        this.utenteSrv.updUtente(updUtente).subscribe(() => {
            this.msgService.add({
                key: "tst",
                severity: "success",
                summary: "Aggiunto Utente",
                detail: "Utente Registrato Correttamente!",
            });
        });
        this.utenteAttrsSrv.addAttrs(this.usrAtrr).subscribe();
        this.getUtenteByID();
    }



}
