import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AppBreadcrumbService} from '../../app.breadcrumb.service';
import {Clienti} from 'src/app/_interfaces/clienti';
import {ClientiService} from 'src/app/_services/clienti.service';
import {Table} from 'primeng/table';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import { dateToLocalArray } from '@fullcalendar/core/datelib/marker';
import { InputText } from 'primeng/inputtext';

@Component({
    selector: 'app-schede-interventi',
    templateUrl: './schede-interventi.component.html',
    styleUrls: ['./schede-interventi.component.scss']
})

export class SchedeInterventiComponent implements OnInit {

    clienti: Clienti[];

    constructor(
        private breadcrumbService: AppBreadcrumbService,
        private clientiSrv: ClientiService,
        private msgService: MessageService,
        private confirmService: ConfirmationService) {
        this.breadcrumbService.setItems([{label: 'Schede Intervento'}]);
    }

    clear(table: Table) {
        table.clear();
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh(): void {
        if (sessionStorage.getItem('ruolo') != 'SE') {
            this.clientiSrv.getClienti().subscribe((res) => {
                this.clienti = [...res.data];
            });
        } else {
            this.clientiSrv.getClientiByUser(sessionStorage.getItem('id')).subscribe((res) => {
                this.clienti = [...res.data];
            });
        }
    }

    delCliente(idCliente: number): void {
        this.confirmService.confirm({
            header: `Elimina Cliente`,
            message: 'Sicuro di Eseguire l\'operazione di Cancellazione?',
            icon: 'pi  pi-question-circle',
            accept: () => {
                this.clientiSrv.delCliente(idCliente, true).subscribe((res) => {
                    this.msgService.add({
                        key: 'tst',
                        severity: res.res === 'ok' ? 'success' : 'danger',
                        summary: `Elimina Cliente`,
                        detail: res.message,
                    });
                    this.refresh();
                });
            },
        });
    }


    @ViewChild('dt', { static: false }) dt: Table;
    @ViewChild('searchField', { static: false }) searchField;

    RemoveFind(): void {
        this.dt.clear();
        this.searchField.value="";
        sessionStorage.removeItem("stateSchedeInt-session")
    }

    editUser(){
        this.clientiSrv.editUser(true);
    }

}
