import { Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { UtentiService } from './../../_services/utenti.service';
import { SerramentistaService } from './../../_services/serramentista.service';
import { Utente } from './../../_interfaces/utente';
import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { AppBreadcrumbService } from '../../app.breadcrumb.service';
import * as CryptoJS from 'crypto-js';
import { Serramentista } from 'src/app/_interfaces/serramentista';
import * as FileSaver from "file-saver";

@Component({
    selector: 'app-utenti',
    templateUrl: './utenti.component.html',
    styleUrls: ['./utenti.component.scss'],
})

export class UtentiComponent implements OnInit {
    utenti: Utente[];
    display = false;
    environment = environment;
    ruoloSel: string;
    viewPassword = false;
    cessionarioFinale: Serramentista[];

    //da verificare quando operativo
    selectedBonus: string[] = [];


    rolesSearch: { label: string; value: any }[] = [
        { label: 'Tutti', value: '' },
        { label: 'Admin', value: 'AD' },
        { label: 'Serramentista', value: 'SE' },
    ];

    roles: { label: string; value: any }[] = [
        { label: 'Admin', value: 'AD' },
        { label: 'Serramentista', value: 'SE' },
    ];

    AbilitaOneriAttuazione: { label: string; value: any }[] = [
        { label: 'Immodificabili', value: 0 },
        { label: 'Modificabili', value: 1 },
    ];

    TipologiaUtrente: { label: string; value: any }[] = [
        { label: "SI + SSCO", value: "1" },
        { label: "SI", value: "0" },
        { label: "SSCO", value: "2" },
    ];

    Step: { label: string; value: any }[] = [
        { label: "Step 2", value: "2" },
        { label: "Step 4", value: "4" },
        { label: "Step 7", value: "7" }
    ];

    Preventivatore: { label: string; value: any }[] = [
        { label: "1 Percentuale", value: "1" },
        { label: "2 Percentuali", value: "2" }
    ];

    TipoBc: { label: string; value: any }[] = [
        { label: "BC", value: "0" }
    ];

    frmUser: FormGroup;

    constructor(
        private utenteSrv: UtentiService,
        private SerramentistaSrv: SerramentistaService,
        private msgService: MessageService,
        private breadcrumbService: AppBreadcrumbService,
        private confirmService: ConfirmationService
    ) {
    }

    ngOnInit(): void {
        this.creaform();
        this.refreshCessionariFinali();
        this.breadcrumbService.setItems([
            { label: 'Utenti/Ruolo/Serramentista' },
        ]);
        this.ruoloSel = '';
        this.refreshUtenti('');
    }

    creaform(): void {
        this.frmUser = new FormGroup({
            id: new FormControl(0, Validators.required),
            name: new FormControl('', Validators.required),
            email: new FormControl('', [Validators.required, Validators.email]),
            ruolo: new FormControl('', Validators.required),
            password: new FormControl('', Validators.required),
            account: new FormControl('', Validators.required),
            account_email: new FormControl('', [Validators.required, Validators.email]),
            abilitato: new FormControl(1, Validators.required),
            abilita_oneri_attuazione: new FormControl(0, Validators.required),
            interventi: new FormControl("1,2")
        });
    }

    ricercaPerRuolo(): void {
        this.refreshUtenti(this.ruoloSel);
        this.breadcrumbService.setItems([
            {
                label:
                    this.ruoloSel === 'SA'
                        ? 'Utenti/Ruolo/SupeAdmin'
                        : this.ruoloSel === 'AD'
                            ? 'Utenti/Ruolo/Admin'
                            : 'Utenti/Ruolo/Serramentista',
            },
        ]);
    }

    refreshUtenti(ruolo: string): void {
        this.utenteSrv.getUtenti(ruolo).subscribe((res) => {
            this.utenti = [...res.data];
        });
    }

    refreshCessionariFinali(): void {
        this.SerramentistaSrv.getCessionarifinali().subscribe((res) => {
            this.cessionarioFinale = [...res.data];
        });
    }

    submit() {
        if (this.frmUser.controls.id.value === 0) {
            const credenziali: Utente = { ...this.frmUser.value };
            credenziali.password = CryptoJS.SHA256(
                credenziali.password
            ).toString();
            this.utenteSrv.addUtente(credenziali).subscribe((res) => {
                this.display = false;
                this.creaform();

                if (res.res === 'ok') {
                    this.msgService.add({
                        key: 'tst',
                        severity: 'success',
                        summary: 'Aggiunto Utente',
                        detail: 'Utente Registrato Correttamente!',
                    });
                    this.refreshUtenti('');
                } else {
                    this.msgService.add({
                        key: 'tst',
                        severity: 'error',
                        summary: 'Errore Utente',
                        detail: 'Email già esistente!',
                    });
                }
            });
        } else {
            const updUtente: { id: number; ruolo: string; interventi: string; abilitato: number; abilita_oneri_attuazione: number; email: string } = {
                id: this.frmUser.controls.id.value,
                ruolo: this.frmUser.controls.ruolo.value,
                interventi: this.frmUser.controls.interventi.value,
                email: this.frmUser.controls.email.value,
                abilitato: this.frmUser.controls.abilitato.value,
                abilita_oneri_attuazione: this.frmUser.controls.abilita_oneri_attuazione.value
            };
            this.utenteSrv.updUtente(updUtente).subscribe(() => {
                this.display = false;
                this.creaform();
                this.msgService.add({
                    key: 'tst',
                    severity: 'success',
                    summary: 'Aggiunto Utente',
                    detail: 'Utente Registrato Correttamente!',
                });
                this.refreshUtenti('');
            });
        }
    }

    editUser(user: Utente): void {
        this.display = true;
        this.frmUser.patchValue(user);
    }

    DisabilitaAbilitaUtente(utente: Utente, abilita: number): void {
        const updUtente: { id: number; ruolo: string; interventi: string; abilitato: number; abilita_oneri_attuazione: number; email: string; account: string; account_email: string } = {
            id: utente.id,
            ruolo: utente.ruolo,
            interventi: utente.interventi,
            email: utente.email,
            abilitato: abilita,
            abilita_oneri_attuazione: utente.abilita_oneri_attuazione,
            account: utente.account,
            account_email: utente.account_email
        };
        this.utenteSrv.updUtente(updUtente).subscribe(() => {
            this.display = false;
            this.creaform();
            this.msgService.add({
                key: 'tst',
                severity: 'success',
                summary: 'Utente',
                detail: 'Utente Modificato Correttamente!',
            });
            this.refreshUtenti('');
        });
    }


    showDialog(): void {
        this.creaform();
        this.display = true;
    }

    cancel(): void {
        this.display = false;
        this.creaform();
    }

    exportExcel(): void {
        const exportUtenti: Utente[] = this.utenti;

        exportUtenti.forEach((x) => {
            delete x.password;
            delete x.id;
            delete x.interventi;
            delete x.remember_token;
            delete x.email_verified_at;
            if (x.ruolo == "AD") {
                x.ruolo = "Amministratore"
            } else if (x.ruolo == "AD") {
                x.ruolo = "Serramentista"
            }
        })

        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(exportUtenti);
            const workbook = {
                Sheets: { data: worksheet },
                SheetNames: ["data"],
            };
            const excelBuffer: any = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
            this.saveAsExcelFile(excelBuffer, "Gino");
        });
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }
}
