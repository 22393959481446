import {
    DatePipe,
    HashLocationStrategy,
    LocationStrategy,
    registerLocaleData,
} from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import localeIt from "@angular/common/locales/it";
import { LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AccordionModule } from "primeng/accordion";
import { ConfirmationService, MessageService } from "primeng/api";
import { AutoCompleteModule } from "primeng/autocomplete";
import { AvatarModule } from "primeng/avatar";
import { AvatarGroupModule } from "primeng/avatargroup";
import { BadgeModule } from "primeng/badge";
import { BlockUIModule } from "primeng/blockui";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { ButtonModule } from "primeng/button";
import { CalendarModule } from "primeng/calendar";
import { CardModule } from "primeng/card";
import { CarouselModule } from "primeng/carousel";
import { CascadeSelectModule } from "primeng/cascadeselect";
import { ChartModule } from "primeng/chart";
import { CheckboxModule } from "primeng/checkbox";
import { ChipModule } from "primeng/chip";
import { ChipsModule } from "primeng/chips";
import { CodeHighlighterModule } from "primeng/codehighlighter";
import { ColorPickerModule } from "primeng/colorpicker";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ConfirmPopupModule } from "primeng/confirmpopup";
import { ContextMenuModule } from "primeng/contextmenu";
import { DataViewModule } from "primeng/dataview";
import { DialogModule } from "primeng/dialog";
import { DividerModule } from "primeng/divider";
import { DropdownModule } from "primeng/dropdown";
import {
    DialogService,
    DynamicDialogConfig,
    DynamicDialogRef,
} from "primeng/dynamicdialog";
import { FieldsetModule } from "primeng/fieldset";
import { FileUploadModule } from "primeng/fileupload";
import { FullCalendarModule } from "primeng/fullcalendar";
import { GalleriaModule } from "primeng/galleria";
import { InplaceModule } from "primeng/inplace";
import { InputMaskModule } from "primeng/inputmask";
import { InputNumberModule } from "primeng/inputnumber";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { KnobModule } from "primeng/knob";
import { LightboxModule } from "primeng/lightbox";
import { ListboxModule } from "primeng/listbox";
import { MegaMenuModule } from "primeng/megamenu";
import { MenuModule } from "primeng/menu";
import { MenubarModule } from "primeng/menubar";
import { MessageModule } from "primeng/message";
import { MessagesModule } from "primeng/messages";
import { MultiSelectModule } from "primeng/multiselect";
import { OrderListModule } from "primeng/orderlist";
import { OrganizationChartModule } from "primeng/organizationchart";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { PaginatorModule } from "primeng/paginator";
import { PanelModule } from "primeng/panel";
import { PanelMenuModule } from "primeng/panelmenu";
import { PasswordModule } from "primeng/password";
import { PickListModule } from "primeng/picklist";
import { ProgressBarModule } from "primeng/progressbar";
import { RadioButtonModule } from "primeng/radiobutton";
import { RatingModule } from "primeng/rating";
import { RippleModule } from "primeng/ripple";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { ScrollTopModule } from "primeng/scrolltop";
import { SelectButtonModule } from "primeng/selectbutton";
import { SidebarModule } from "primeng/sidebar";
import { SkeletonModule } from "primeng/skeleton";
import { SlideMenuModule } from "primeng/slidemenu";
import { SliderModule } from "primeng/slider";
import { SplitButtonModule } from "primeng/splitbutton";
import { SplitterModule } from "primeng/splitter";
import { StepsModule } from "primeng/steps";
import { TableModule } from "primeng/table";
import { TabMenuModule } from "primeng/tabmenu";
import { TabViewModule } from "primeng/tabview";
import { TagModule } from "primeng/tag";
import { TerminalModule } from "primeng/terminal";
import { TieredMenuModule } from "primeng/tieredmenu";
import { TimelineModule } from "primeng/timeline";
import { ToastModule } from "primeng/toast";
import { ToggleButtonModule } from "primeng/togglebutton";
import { ToolbarModule } from "primeng/toolbar";
import { TooltipModule } from "primeng/tooltip";
import { TreeModule } from "primeng/tree";
import { TreeTableModule } from "primeng/treetable";
import { VirtualScrollerModule } from "primeng/virtualscroller";
import { OffertaComponent } from "./_components/Offerta/offerta.component";
import { ArchivioCategoriaComponent } from "./_components/archivio-categoria/archivio-categoria.component";
import { ArchivioComponent } from "./_components/archivio/archivio.component";
import { AttestazioneCessioneComponent } from "./_components/attestazione-cessione/attestazione-cessione.component";
import { CessionarioFinaleListComponent } from "./_components/cessionario-finale-list/cessionario-finale-list.component";
import { CessionarioFinaleComponent } from "./_components/cessionario-finale/cessionario-finale.component";
import { CessioneCreditoComponent } from "./_components/cessione-credito/cessione-credito.component";
import { HtmlPreviewStep5Component } from "./_components/html-preview-step5/html-preview-step5.component";
import { HtmlPreviewStep7Component } from "./_components/html-preview-step7/html-preview-step7.component";
import { HtmlPreviewComponent } from "./_components/html-preview/html-preview.component";
import { LavoriExportComponent } from "./_components/lavori-export/lavori-export.component";
import { LavoriPraticheComponent } from "./_components/lavori-pratiche/lavori-pratiche.component";
import { LavoriStepComponent } from "./_components/lavori-step/lavori-step.component";
import { LiquidazioneComponent } from "./_components/liquidazione/liquidazione.component";
import { OffertaBCComponent } from "./_components/offerta-bc/offerta-bc.component";
import { OffertaNewComponent } from "./_components/offerta-new/offerta-new.component";
import { OfferteComponent } from "./_components/offerte/offerte.component";
import { AbitazioneSelectComponent } from "./_components/preventivi/abitazione-select/abitazione-select.component";
import { AnagraficaSelectComponent } from "./_components/preventivi/anagrafica-select/anagrafica-select.component";
import { PreventiviListPageComponent } from "./_components/preventivi/pages/preventivi-list-page/preventivi-list-page.component";
import { PreventivoPageComponent } from "./_components/preventivi/pages/preventivo-page/preventivo-page.component";
import { PreventiviExportComponent } from "./_components/preventivi/preventivi-export/preventivi-export.component";
import { PreventiviListComponent } from "./_components/preventivi/preventivi-list/preventivi-list.component";
import { PreventivoFormComponent } from "./_components/preventivi/preventivo-form/preventivo-form.component";
import { TipologiaSelectComponent } from "./_components/preventivi/tipologia-select/tipologia-select.component";
import { SchedaInterventoComponent } from "./_components/scheda-intervento/scheda-intervento.component";
import { SchedeInterventiComponent } from "./_components/schede-interventi/schede-interventi.component";
import { SerramentistaComponent } from "./_components/serramentista/serramentista.component";
import { UtentiExportComponent } from "./_components/utenti-export/utenti-export.component";
import { UtentiProfilazioneComponent } from "./_components/utenti-profilazione/utenti-profilazione.component";
import { UtentiComponent } from "./_components/utenti/utenti.component";
import { RequestInterceptor } from "./_interceptors/request.interceptor";
import { UtcDatePipe } from "./_pipes/utc-date.pipe";
import { AuthService } from "./_services/auth.service";
import { AppRoutingModule } from "./app-routing.module";
import { AppBreadcrumbComponent } from "./app.breadcrumb.component";
import { AppBreadcrumbService } from "./app.breadcrumb.service";
import { AppCodeModule } from "./app.code.component";
import { AppComponent } from "./app.component";
import { AppConfigComponent } from "./app.config.component";
import { AppFooterComponent } from "./app.footer.component";
import { AppMainComponent } from "./app.main.component";
import { AppMenuComponent } from "./app.menu.component";
import { MenuService } from "./app.menu.service";
import { AppMenuitemComponent } from "./app.menuitem.component";
import { AppTopBarComponent } from "./app.topbar.component";
import { CountryService } from "./demo/service/countryservice";
import { CustomerService } from "./demo/service/customerservice";
import { EventService } from "./demo/service/eventservice";
import { IconService } from "./demo/service/iconservice";
import { NodeService } from "./demo/service/nodeservice";
import { PhotoService } from "./demo/service/photoservice";
import { ProductService } from "./demo/service/productservice";
import { ButtonDemoComponent } from "./demo/view/buttondemo.component";
import { ChartsDemoComponent } from "./demo/view/chartsdemo.component";
import { DashboardDemoComponent } from "./demo/view/dashboarddemo.component";
import { DocumentationComponent } from "./demo/view/documentation.component";
import { EmptyDemoComponent } from "./demo/view/emptydemo.component";
import { FileDemoComponent } from "./demo/view/filedemo.component";
import { FloatLabelDemoComponent } from "./demo/view/floatlabeldemo.component";
import { FormLayoutDemoComponent } from "./demo/view/formlayoutdemo.component";
import { InputDemoComponent } from "./demo/view/inputdemo.component";
import { InvalidStateDemoComponent } from "./demo/view/invalidstatedemo.component";
import { ListDemoComponent } from "./demo/view/listdemo.component";
import { MediaDemoComponent } from "./demo/view/mediademo.component";
import { MenusDemoComponent } from "./demo/view/menusdemo.component";
import { MessagesDemoComponent } from "./demo/view/messagesdemo.component";
import { MiscDemoComponent } from "./demo/view/miscdemo.component";
import { OverlaysDemoComponent } from "./demo/view/overlaysdemo.component";
import { PanelsDemoComponent } from "./demo/view/panelsdemo.component";
import { TableDemoComponent } from "./demo/view/tabledemo.component";
import { TreeDemoComponent } from "./demo/view/treedemo.component";
import { AppAccessdeniedComponent } from "./pages/app.accessdenied.component";
import { AppCalendarComponent } from "./pages/app.calendar.component";
import { AppCrudComponent } from "./pages/app.crud.component";
import { AppErrorComponent } from "./pages/app.error.component";
import { AppHelpComponent } from "./pages/app.help.component";
import { AppInvoiceComponent } from "./pages/app.invoice.component";
import { AppLoginComponent } from "./pages/app.login.component";
import { AppNotfoundComponent } from "./pages/app.notfound.component";
import { RecoverComponent } from "./pages/app.recover.component";
import { AppTimelineDemoComponent } from "./pages/app.timelinedemo.component";
import { DisplayComponent } from "./utilities/display.component";
import { ElevationComponent } from "./utilities/elevation.component";
import { FlexboxComponent } from "./utilities/flexbox.component";
import { GridComponent } from "./utilities/grid.component";
import { IconsComponent } from "./utilities/icons.component";
import { SpacingComponent } from "./utilities/spacing.component";
import { TextComponent } from "./utilities/text.component";
import { TypographyComponent } from "./utilities/typography.component";
import { WidgetsComponent } from "./utilities/widgets.component";
import { CreditiComponent } from "./_components/crediti/crediti/crediti.component";
import { CreditiPageComponent } from './_components/crediti/crediti-page/crediti-page.component';
import { StepPreventiviComponent } from "./_components/preventivi/step-preventivi/step-preventivi.component";
import { ParametrREComponent } from "./_components/parametr-re/parametr-re.component";

registerLocaleData(localeIt, "it");

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        AvatarModule,
        AvatarGroupModule,
        BadgeModule,
        BreadcrumbModule,
        BlockUIModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        CascadeSelectModule,
        ChartModule,
        CheckboxModule,
        ChipModule,
        ChipsModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ConfirmPopupModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DividerModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        FullCalendarModule,
        GalleriaModule,
        InplaceModule,
        InputNumberModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        KnobModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        RippleModule,
        ScrollPanelModule,
        ScrollTopModule,
        SelectButtonModule,
        SidebarModule,
        SkeletonModule,
        SlideMenuModule,
        SliderModule,
        SplitButtonModule,
        SplitterModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TagModule,
        TerminalModule,
        TimelineModule,
        TieredMenuModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        AppCodeModule,
        ReactiveFormsModule,
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppConfigComponent,
        AppTopBarComponent,
        AppFooterComponent,
        AppBreadcrumbComponent,
        DashboardDemoComponent,
        FormLayoutDemoComponent,
        FloatLabelDemoComponent,
        InvalidStateDemoComponent,
        InputDemoComponent,
        ButtonDemoComponent,
        TableDemoComponent,
        ListDemoComponent,
        TreeDemoComponent,
        PanelsDemoComponent,
        OverlaysDemoComponent,
        MediaDemoComponent,
        MenusDemoComponent,
        MessagesDemoComponent,
        MessagesDemoComponent,
        MiscDemoComponent,
        ChartsDemoComponent,
        EmptyDemoComponent,
        FileDemoComponent,
        DocumentationComponent,
        DisplayComponent,
        ElevationComponent,
        FlexboxComponent,
        GridComponent,
        IconsComponent,
        WidgetsComponent,
        SpacingComponent,
        TypographyComponent,
        TextComponent,
        AppCrudComponent,
        AppCalendarComponent,
        AppTimelineDemoComponent,
        AppLoginComponent,
        AppInvoiceComponent,
        AppHelpComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        AppAccessdeniedComponent,
        SchedeInterventiComponent,
        SchedaInterventoComponent,
        UtcDatePipe,
        UtentiComponent,
        OffertaComponent,
        OfferteComponent,
        LavoriPraticheComponent,
        LavoriStepComponent,
        SerramentistaComponent,
        RecoverComponent,
        HtmlPreviewComponent,
        ArchivioCategoriaComponent,
        ArchivioComponent,
        CessioneCreditoComponent,
        LiquidazioneComponent,
        HtmlPreviewStep5Component,
        LavoriExportComponent,
        AttestazioneCessioneComponent,
        HtmlPreviewStep7Component,
        CessionarioFinaleListComponent,
        CessionarioFinaleComponent,
        UtentiProfilazioneComponent,
        OffertaNewComponent,
        OffertaBCComponent,
        UtentiExportComponent,
        AbitazioneSelectComponent,
        PreventiviListComponent,
        PreventiviListPageComponent,
        AnagraficaSelectComponent,
        PreventivoPageComponent,
        TipologiaSelectComponent,
        PreventivoFormComponent,
        PreventiviExportComponent,
        CreditiComponent,
        CreditiPageComponent,
        StepPreventiviComponent,
        ParametrREComponent
    ],
    providers: [
        { provide: LOCALE_ID, useValue: "it" },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true,
        },
        AuthService,
        CountryService,
        CustomerService,
        EventService,
        IconService,
        NodeService,
        PhotoService,
        ProductService,
        MessageService,
        MenuService,
        AppBreadcrumbService,
        ConfirmationService,
        UtcDatePipe,
        DialogService,
        DynamicDialogRef,
        DynamicDialogConfig,
        DatePipe,
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
