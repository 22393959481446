import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse,
} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {AuthService} from '../_services/auth.service';
import {MessageService} from 'primeng/api';
import {map, catchError} from 'rxjs/operators';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private msgService: MessageService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        if (this.authService.isLoggedIn()) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.authService.getToken()}`,
                },
            });
            return next.handle(request);
        }

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    const {res, message} = event.body;
                    if (res && res === 'ko') {
                        throw new HttpErrorResponse({
                            status: 401,
                            statusText: 'Non Autorizzato',
                            error: {message},
                        });
                    }
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                this.msgService.add({
                    key: 'tst',
                    severity: 'error',
                    summary: `${error.status} - ${error.statusText}`,
                    detail: error.error.message,
                });
                return of(error);
            })
        );
    }
}
