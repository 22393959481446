import {Component, OnInit, ViewChild} from '@angular/core';
import {MessageService} from 'primeng/api';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {AppBreadcrumbService} from 'src/app/app.breadcrumb.service';
import {AggregatiGroup, Aggregati} from 'src/app/_interfaces/step-lavori';
import {StepLavoriService} from 'src/app/_services/step-lavori.service';
import {environment} from 'src/environments/environment';
import {HtmlPreviewStep7Component} from '../html-preview-step7/html-preview-step7.component';
import {UploadService} from 'src/app/_services/upload.service';

@Component({
    selector: 'app-liquidazione',
    templateUrl: './liquidazione.component.html',
    styleUrls: ['./liquidazione.component.scss']
})
export class LiquidazioneComponent implements OnInit {
    aggregati: AggregatiGroup[];
    aggregatiSelect: Aggregati[];
    aggregatiSelectEsclusi: Aggregati[];
    ruoloUtente: string;
    idUtente: string;
    openModalSelect: boolean;
    openUpload: boolean;
    environment = environment;
    ref: DynamicDialogRef;
    upFile: File;
    openModalValidazione: boolean;
    nomePerValidazione: string = "";
    p_la_validato_7: number;
    serramentistaID: string="";
    baseUrl: string = environment.baseUrl;
    
    constructor(
        private breadcrumbService: AppBreadcrumbService,
        private stepDocService: StepLavoriService,
        private msgService: MessageService,
        private dialogService: DialogService,
        private uploadSrv: UploadService
    ) {
    }

    ngOnInit(): void {
        this.openUpload=false;
        this.ruoloUtente = sessionStorage.getItem('ruolo');
        this.idUtente = sessionStorage.getItem('id');
        this.serramentistaID ="0";
        this.breadcrumbService.setItems([
            {label: 'Liquidazione'},
        ]);
        if (this.ruoloUtente === 'AD' || this.ruoloUtente === 'SA') {
            this.refreshAggregati();
        } else {
            this.refreshAggregatiUser()
        }
    }

    refreshAggregati() {
        this.stepDocService.getGroupAggregatiNomeCheckNrValidazione(7).subscribe((res) => {
            this.aggregati = [...res.data]
        });
    }

    refreshAggregatiUser() {
        this.stepDocService.getGroupAggregatiNomeCheckNrValidazioneSERR(7, this.idUtente).subscribe((res) => {
            this.aggregati = [...res.data]
        });
    }

    refreshLavoriAggregatiSelect(nomeAggregante: string, serr: number) {
        this.stepDocService.getAggregatiNomeCheck(nomeAggregante, 7, serr).subscribe((res) => {
            this.aggregatiSelect = [...res.data];
            this.aggregatiSelect.forEach((item) => {
                if (item.la_validato_7 > -1) {
                    item.sel = true;
                } else {
                    item.sel = false;
                }
            })
        })
    }

    refreshLavoriAggregatiSelectEsclusi(nomeAggregante: string, serr: number) {
        this.stepDocService.getAggregatiNomeCheckEsclusi(nomeAggregante, 7, serr).subscribe((res) => {
            this.aggregatiSelectEsclusi = [...res.data];
            this.aggregatiSelectEsclusi.forEach((item) => {
                if (item.la_validato_7 > -1) {
                    item.sel = true;
                } else {
                    item.sel = false;
                }
            })
        })
    }

    SubmitSelezionati(): void {
        this.aggregatiSelect.forEach((item) => {
            if (item.sel === false) {
                this.stepDocService.updAggregati_LavoriSelezionatiPerStepSuccessivo(item.la_id, -1, 7).subscribe((res) => {
                    this.msgService.add({
                        key: 'tst',
                        severity: res.res === 'ok' ? 'success' : 'error',
                        summary: res.res === 'ok' ? 'Aggregazione Modificata con Successo' : 'Errore nella modifica di Aggregazione',
                        detail: res.message,
                    });
                });
            }
        })
        this.openModalSelect = false;
    }

    SubmitSelezionatiEsclusi(): void {
        this.aggregatiSelectEsclusi.forEach((item) => {
            if (item.sel === true) {
                this.stepDocService.updAggregati_LavoriSelezionatiPerStepSuccessivo(item.la_id, this.p_la_validato_7, 7).subscribe((res) => {
                    this.msgService.add({
                        key: 'tst',
                        severity: res.res === 'ok' ? 'success' : 'error',
                        summary: res.res === 'ok' ? 'Aggregazione' : 'Errore nella modifica di Aggregazione',
                        detail: res.res === 'ok' ? 'Aggregazione Modificata con Successo' : res.message,
                    });
                });
            }
        })
        this.openModalSelect = false;
    }

    UpdateGestoreStep5(name: any, step: string, numero: string, stato: number, serrID: string): void {
        this.stepDocService.updGestoreAggregatiStep5(name, step, numero, stato, serrID).subscribe((res) => {
            this.msgService.add({
                key: 'tst',
                severity: res.res === 'ok' ? 'success' : 'error',
                summary: `Convalida`,
                detail: res.message,
            });
            this.refreshAggregati();
        })

    }

    OpenSelect(agg: Aggregati): void {
        this.openModalSelect = true;
        this.p_la_validato_7 = agg.la_validato_7
        this.refreshLavoriAggregatiSelect(agg.la_nome, agg.la_serr);
        this.refreshLavoriAggregatiSelectEsclusi(agg.la_nome, agg.la_serr);
    }


    openModalDown(id, nome, serr): void {
        this.ref = this.dialogService.open(HtmlPreviewStep7Component, {
            width: '70%',
            contentStyle: {"overflow": "auto"},
            baseZIndex: 10000,
            data: {id: id, nome: nome, serramentista: serr}
        });
    }

    onChangeSel(ev): void {
        const id = ev.target.value;
        const isChecked = ev.target.checked;
        this.aggregatiSelect = this.aggregatiSelect.map((d) => {
            if (d.lav_id == id) {
                d.sel = isChecked;
                return d;
            }
            return d;
        })
    }

    onChangeSelEsclusi(ev): void {
        const id = ev.target.value;
        const isChecked = ev.target.checked;
        this.aggregatiSelectEsclusi = this.aggregatiSelectEsclusi.map((d) => {
            if (d.lav_id == id) {
                d.sel = isChecked;
                return d;
            }
            return d;
        })
    }

    onUpload(event, docStep: string, numDoc: string, docTipo: string, serrid:string) {
        for (let file of event.files) {
            this.upFile = file;
        }
        this.uploadFileToActivity(docStep, numDoc, docTipo, serrid);
    }

    uploadFileToActivity(docStep: string, numDoc: string, nomeDoc: string, serrID:string) {
        this.uploadSrv.uploadAggregatiStep5(this.upFile, nomeDoc, docStep, numDoc, serrID).subscribe((res) => {
            this.msgService.add({
                key: 'tst',
                severity: 'info',
                summary: `File Caricato`,
                detail: res.nome_file,
            });
            this.refreshAggregati();
            this.openUpload=false;
        }, error => {
            this.msgService.add({
                key: 'tst',
                severity: 'error',
                summary: `File Caricato`,
                detail: error.statusText,
                // detail: 'ERRORE: File NON Caricato',
            });
        });
    }

    ModaleValida(nomeAggregazione: string, serrId: string): void {
        this.openModalValidazione = true;
        this.nomePerValidazione = nomeAggregazione;
        this.serramentistaID = serrId;
    }

    IconaCheck(stato: number): string {
        if (stato === 1 || stato === 0) {
            return "pi pi-question-circle";
        } else if (stato === 2) {
            return "pi pi-info-circle";
        } else if (stato === 3) {
            return "pi pi-times-circle";
        } else {
            return "pi pi-check-circle";
        }
    }

    ColorButton(stato: number): string {
        if (stato === 1 || stato === 0) {
            return "p-button-secondary";
        } else if (stato === 2) {
            return "p-button-danger";
        } else if (stato === 3) {
            return "p-button-warning";
        } else {
            return "p-button-success";
        }
    }

    DocValida(Stato: number): void {
        this.UpdateGestoreStep5(this.nomePerValidazione, '7', '1', Stato, this.serramentistaID)
        this.openModalValidazione = false;
    }

    @ViewChild('dt', { static: false }) dt;
    @ViewChild('searchField', { static: false }) searchField;
  
    RemoveFind(): void {
        this.dt.clear();
        this.searchField.value="";
        sessionStorage.removeItem("stateLiquidazione-session")
    }

    la_nome: string = "";
    la_serr: string = "";
    la_validato_7: number = 0;
  
    openModalUpload(nome: string, serr: string, la_validato_7: number) {
      this.openUpload = true;
      this.la_nome = nome;
      this.la_serr = serr;
      this.la_validato_7 = la_validato_7;
    }


}
