import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AppBreadcrumbService } from 'src/app/app.breadcrumb.service';
import { AggregatiGroup, Aggregati } from 'src/app/_interfaces/step-lavori';
import { StepLavoriService } from 'src/app/_services/step-lavori.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-attestazione-cessione',
  templateUrl: './attestazione-cessione.component.html',
  styleUrls: ['./attestazione-cessione.component.scss']
})
export class AttestazioneCessioneComponent implements OnInit {

  aggregati: AggregatiGroup[];
  aggregatiSelect: Aggregati[];
  aggregatiSelectEsclusi: Aggregati[];
  ruoloUtente: string;
  idUtente: string;
  openModalSelect: boolean;
  openModalValidazione: boolean;
  selezionaDeseleziona: boolean;
  environment = environment;
  baseUrl: string = environment.baseUrl;
  nomePerValidazione: string;
  serramentistaID: string="";
  p_la_validato_6: number;
  p_la_data_upload: Date | string;
  p_la_nome: string;
  p_la_serrID: string;
  chkDichiarazione: boolean;
  chkDichiarazioneEsclusi: boolean;

  constructor(
    private breadcrumbService: AppBreadcrumbService,
    private stepDocService: StepLavoriService,
    private msgService: MessageService
  ) { }

  ngOnInit(): void {
    this.openModalSelect = false;
    this.openModalValidazione = false;
    this.chkDichiarazione = false
    this.nomePerValidazione = "";
    this.serramentistaID='0';
    this.breadcrumbService.setItems([
      { label: 'Attestazione Cessione' },
    ]);
    this.ruoloUtente = sessionStorage.getItem('ruolo');
    this.idUtente = sessionStorage.getItem('id');
    if (this.ruoloUtente === 'AD' || this.ruoloUtente === 'SA') {
      this.refreshAggregati();
    } else {
      this.refreshAggregatiUser()
    }
  }

  refreshAggregati() {
    this.stepDocService.getGroupAggregatiNomeCheckNrValidazione(6).subscribe((res) => {
      this.aggregati = [...res.data]
    });
  }

  refreshAggregatiUser() {
    this.stepDocService.getGroupAggregatiNomeCheckNrValidazioneSERR(6, this.idUtente).subscribe((res) => {
      this.aggregati = [...res.data]
    });
  }

  refreshLavoriAggregatiSelect(nomeAggregante: string, serr: number) {
    this.stepDocService.getAggregatiNomeCheck(nomeAggregante, 6, serr).subscribe((res) => {
      this.aggregatiSelect = [...res.data];
      this.aggregatiSelect.forEach((item) => {
        if (item.la_validato_6 > -1) {
          item.sel = true;
        } else {
          item.sel = false;
        }
      })
    })
  }

  refreshLavoriAggregatiSelectEsclusi(nomeAggregante: string, serr: number) {
    this.stepDocService.getAggregatiNomeCheckEsclusi(nomeAggregante, 6, serr).subscribe((res) => {
      this.aggregatiSelectEsclusi = [...res.data];
      this.aggregatiSelectEsclusi.forEach((item) => {
        if (item.la_validato_6 > -1) {
          item.sel = true;
        } else {
          item.sel = false;
        }
      })
    })
  }

  OpenSelect(agg: Aggregati): void {
    this.chkDichiarazione = false;
    this.p_la_validato_6 = agg.la_validato_6;
    this.p_la_data_upload = agg.la_data_upload;
    this.p_la_nome = agg.la_nome;
    this.p_la_serrID = agg.la_serr.toString();
    this.refreshLavoriAggregatiSelect(agg.la_nome, agg.la_serr);
    this.refreshLavoriAggregatiSelectEsclusi(agg.la_nome, agg.la_serr);
    this.openModalSelect = true;
  }

  SubmitSelezionati(): void {
    this.aggregatiSelect.forEach((item) => {
      if (item.sel === false) {
        this.stepDocService.updAggregati_LavoriSelezionatiPerStepSuccessivo(item.la_id, -1, 6).subscribe((res) => {
          this.msgService.add({
            key: 'tst',
            severity: res.res === 'ok' ? 'success' : 'error',
            summary: res.res === 'ok' ? 'Aggregazione Modificata con Successo' : 'Errore nella modifica di Aggregazione',
            detail: res.message,
          });
        });
      }
    })
    this.openModalSelect = false;
  }

  SubmitSelezionatiEsclusi(): void {
    this.aggregatiSelectEsclusi.forEach((item) => {
      if (item.sel === true) {
        this.stepDocService.updAggregati_LavoriSelezionatiPerStepSuccessivo(item.la_id, this.p_la_validato_6, 6).subscribe((res) => {
          this.msgService.add({
            key: 'tst',
            severity: res.res === 'ok' ? 'success' : 'error',
            summary: res.res === 'ok' ? 'Aggregazione' : 'Errore nella modifica di Aggregazione',
            detail:  res.res === 'ok' ? 'Aggregazione Modificata con Successo' :  res.message,
          });
        });
      }
    })
    this.openModalSelect = false;
  }

  RiaggregaAlloStep6(id: number): void {
    this.stepDocService.updAggregati_LavoriSelezionatiPerStepSuccessivo(id, this.p_la_validato_6, 6).subscribe((res) => {
      this.msgService.add({
        key: 'tst',
        severity: res.res === 'ok' ? 'success' : 'error',
        summary: res.res === 'ok' ? 'Aggregazione' : 'Errore nella modifica di Aggregazione',
        detail:  res.res === 'ok' ? 'Aggregazione Modificata con Successo' :  res.message,
      });
    });
    this.openModalSelect = false;
  }

  UpdateGestoreStep(name: any, step: string, numero: string, stato: number, serr: string): void {
    this.stepDocService.updGestoreAggregatiStep5(name, step, numero, stato, serr).subscribe((res) => {
      this.msgService.add({
        key: 'tst',
        severity: res.res === 'ok' ? 'success' : 'error',
        summary: `Convalida`,
        detail: res.message,
      });
      this.refreshAggregati();
    })

  }

  onChangeSel(ev): void {
    const id = ev.target.value;
    const isChecked = ev.target.checked;
    this.aggregatiSelect = this.aggregatiSelect.map((d) => {
      if (d.lav_id == id) {
        d.sel = isChecked;
        return d;
      }
      return d;
    })
  }

  onChangeSelEsclusi(ev): void {
    const id = ev.target.value;
    const isChecked = ev.target.checked;
    this.aggregatiSelectEsclusi = this.aggregatiSelectEsclusi.map((d) => {
      if (d.lav_id == id) {
        d.sel = isChecked;
        return d;
      }
      return d;
    })
  }

  ModaleValida(nomeAggregazione: string, serrID: string): void {
    this.openModalValidazione = true;
    this.nomePerValidazione = nomeAggregazione
    this.serramentistaID = serrID
  }


  DocValida(Stato: number): void {
    this.UpdateGestoreStep(this.nomePerValidazione, '6', '1', Stato, this.serramentistaID)
    this.openModalValidazione = false;
    this.openModalSelect = false;
  }

  IconaCheck(stato: number): string {
    if (stato === 1 || stato === 0) {
      return "pi pi-question-circle";
    } else if (stato === 2) {
      return "pi pi-info-circle";
    } else if (stato === 3) {
      return "pi pi-times-circle";
    } else {
      return "pi pi-check-circle";
    }
  }

  ColorButton(stato: number): string {
    if (Number(stato) == 1 || Number(stato) == 0) {
      return "p-button-secondary";
    } else if (Number(stato) == 2) {
      return "p-button-danger";
    } else if (Number(stato) == 3) {
      return "p-button-warning";
    } else if (Number(stato) == 4) {
      return "p-button-success";
    }
  }

  @ViewChild('dt', { static: false }) dt;
  @ViewChild('searchField', { static: false }) searchField;

  RemoveFind(): void {
      this.dt.clear();
      this.searchField.value="";
      sessionStorage.removeItem("stateAttestazione-session")
  }

}
